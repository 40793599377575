import { ArtefactView } from '../types'

export const getCatFromKey = (key: string) => {
  const splits = key.split('/')
  return splits.length === 3 ? splits[1] : ''
}
export const getNameFromKey = (key: string) => {
  const splits = key.split('/')
  return splits[splits.length - 1]
}
export const getKeyWithoutProject = (artefact: ArtefactView) => {
  return artefact.category
    ? `${artefact.category}/${artefact.name.toLowerCase()}`
    : artefact.name.toLowerCase()
}

export const buildKeyFromParts = (
  projectId: string,
  artefact: string,
  category?: string
) => {
  if (category && category.length > 0) {
    return `${projectId}/${category}/${artefact.toLowerCase()}`
  }
  return `${projectId}/${artefact.toLowerCase()}`
}
