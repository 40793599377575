import React, { useState, ChangeEvent } from 'react'

import { Input, Label, FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen } from '@fortawesome/pro-solid-svg-icons'
import { Step, StepDefinition, StepControls } from './step'
import { WizardStepProps, CategoryStepProps, reg } from '../../types'
import { MultiTextInput } from './multi-text-input'

export const CategoryStep: React.FC<WizardStepProps<CategoryStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  data,
  totalSteps,
  goBack,
  onChange
}) => {
  const [radioIndex, setRadioIndex] = useState(
    data.categories.length > 0 ? 1 : 0
  )

  const handleCategoriesChange = (newValues: string[]): void => {
    if (onChange) {
      onChange({ categories: newValues })
    }
  }
  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = parseInt(event.target.value, 10)
    setRadioIndex(newValue)
    if (newValue === 0) {
      if (onChange) {
        onChange({ categories: [] })
      }
    }
  }
  return (
    <Step
      stepNumber={stepNumber}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <StepDefinition
        stepNumber={stepNumber}
        stepTitle="Categories"
        totalSteps={totalSteps}
        currentStep={currentStep}
        goBack={() => {
          if (goBack) {
            goBack()
          }
        }}
        goForward={() => {
          goForward()
        }}
      >
        <Label className="wizard-label font-weight-bold mb-2 font-larger">
          Please add up to 3 categories, if applicable
        </Label>
        <p className="text-secondary mb-4">
          Categories define sub-groups of artefacts for comparison
        </p>
        <p className="text-secondary mb-45">
          For example, when comparing artefacts from Jun 17 and Nov 17 the
          categories could be <b>Jun17</b> and <b>Nov17</b>
        </p>
        <FormGroup tag="fieldset">
          <FormGroup check className="mb-5 pl-0 ">
            <div className="custom-control custom-radio">
              <Input
                data-testid="input-date-undefined"
                type="radio"
                id="none"
                name="none"
                value={0}
                className="custom-control-input"
                checked={radioIndex === 0}
                onChange={event => handleChangeRadio(event)}
              />
              <Label
                for="none"
                className="custom-control-label"
                data-testid="label-nocategories"
                check
              >
                No categories
              </Label>
            </div>
          </FormGroup>
          <FormGroup check className="mb-5 pl-0">
            <div className="custom-control custom-radio">
              <Input
                type="radio"
                name="date"
                id="date"
                className="custom-control-input"
                value={1}
                checked={radioIndex === 1}
                onChange={event => handleChangeRadio(event)}
              />
              <Label
                for="date"
                className="custom-control-label"
                data-testid="label-categories"
                check
              >
                I want to add some categories
              </Label>
            </div>
          </FormGroup>
        </FormGroup>
      </StepDefinition>
      <StepControls>
        {radioIndex === 1 && (
          <MultiTextInput
            placeholder="Enter your category name"
            inputRegex={reg}
            maxValues={3}
            values={data.categories}
            title="Categories"
            onChange={newValues => handleCategoriesChange(newValues)}
            icon={
              <FontAwesomeIcon
                icon={faFolderOpen}
                className="align-middle mr-3"
              />
            }
          />
        )}
      </StepControls>
    </Step>
  )
}
