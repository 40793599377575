import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { keys, Dictionary } from 'lodash'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { Row, Col } from 'reactstrap'
import { ArtefactView, Artefact, DraftArtefactView } from '../../types'
import { CountBox } from '../shared/count-box/count-box'

import { ArtefactViewer } from '../artefact-viewer'
import { NOCAT } from '../use-artefact-manager-new'

interface AfrefactViewProps {
  artefactMap: Map<string, DraftArtefactView>
  categorisedArtefactKeys: Map<string, Set<string>>
}
export const InProgressArtefactView: React.FC<AfrefactViewProps> = ({
  artefactMap,
  categorisedArtefactKeys
}): JSX.Element => {
  const [selectedArtefactKey, setSelectedArtefactKey] = useState<
    ArtefactView | undefined
  >(undefined)
  const [showViewer, setShowViewer] = useState(false)

  const showItem = (key: ArtefactView) => {
    setSelectedArtefactKey(key)
    setShowViewer(true)
  }
  const toggle = () => {
    setShowViewer(!showViewer)
  }
  const mdCols = useMemo(() => {
    const catCount = categorisedArtefactKeys.size
    if (catCount === 1) {
      return 24
    }

    return catCount === 2 ? 12 : 8
  }, [categorisedArtefactKeys])
  return (
    <>
      <Row className="mb-4 mx-n4">
        {categorisedArtefactKeys.size > 0 &&
          Array.from(categorisedArtefactKeys.keys()).map(x => {
            const catKeys = categorisedArtefactKeys.get(x)

            return (
              <Col key={x} xs={24} md={mdCols} className="px-4 mb-45">
                <CountBox
                  title={x === NOCAT ? 'Artefacts' : x}
                  count={catKeys?.size}
                  maxHeight={320}
                >
                  {Array.from(catKeys!).map(key => {
                    const artefact = artefactMap.get(key)
                    return (
                      <Col key={key}>
                        <a
                          className="text-body"
                          href={`/artefacts/${key}`}
                          onClick={event => {
                            event.nativeEvent.preventDefault()

                            showItem(artefact!)
                          }}
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={faFilePdf}
                            className="mr-35"
                          />
                          {artefact!.name}
                        </a>
                      </Col>
                    )
                  })}
                </CountBox>
              </Col>
            )
          })}
      </Row>
      {selectedArtefactKey && (
        <ArtefactViewer
          showAllPages
          artefactName={selectedArtefactKey.name}
          artefactKey={selectedArtefactKey.key}
          isOpen={showViewer}
          toggle={toggle}
        />
      )}
    </>
  )
}
