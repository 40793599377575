import React, { useMemo } from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'

import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { Project } from '../../../types'

import { getPacksOrPairs } from '../../../helpers/project-type-helpers'
import { NameAndMethod } from '../../name-and-method'

export const ProjectCompletedRow: React.FC<Project &
  React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...project
}): JSX.Element => {
  const formattedCompletedDate = useMemo(() => {
    return project.completedDate
      ? format(project.completedDate, 'MMM dd, yyyy')
      : '-'
  }, [project])
  return (
    <Card
      className={`${className} project-row`}
      data-testid="project-completed"
    >
      <CardBody className="px-42 pt-42 pb-4">
        <Row className="mb-42">
          <Col>
            <NameAndMethod {...project} />
          </Col>
          {project.completedDate && (
            <Col xs="auto" className="text-right text-dark">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-primary" />
              <span className="font-weight-bold text-dark">
                {`Completion date is ${formattedCompletedDate}`}
              </span>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <span className="h4 mb-0 text-dark font-weight-bold">
              {project.completedTasks}
              {' / '}
              {project.totalTasks}
            </span>
            <span className="text-dark font-weight-bold">
              {` ${getPacksOrPairs(project.packSize)} completed`}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
