import React from 'react'
import { Col, Row } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers } from '@fortawesome/pro-solid-svg-icons'

import { Project } from '../../types'

import { getMethodText } from '../../helpers/project-type-helpers'

export const ProjectDetail: React.FC<Project> = ({
  name,
  packSize,
  userRole,
  basis
}): JSX.Element => {
  return (
    <>
      <Row>
        <Col lg={24} xl={18}>
          <Row className="align-items-center mb-3">
            <Col xs="auto">
              <div className="text-primary font-weight-bold h4 mb-2 mr-3">
                {name}
              </div>
              <div className="text-body font-weight-bold h5 mr-3 mb-0 ">
                {getMethodText(packSize)}
              </div>
            </Col>
            <Col>
              {userRole === 'OWNER' && (
                <FontAwesomeIcon className="text-primary" icon={faUser} />
              )}
              {userRole === 'ADMIN' && (
                <FontAwesomeIcon
                  className="text-primary font-larger"
                  icon={faUsers}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="font-larger text-body ">{basis}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
