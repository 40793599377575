import React, { ChangeEvent } from 'react'
import { Label, Input, FormGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faCalendar } from '@fortawesome/pro-solid-svg-icons'
import { format } from 'date-fns'
import Calendar from 'react-calendar/dist/entry.nostyle'
import { Step, StepDefinition, StepControls } from './step'
import { WizardStepProps, DateStepProps } from '../../types'
import 'react-datepicker/dist/react-datepicker.css'
import { useDatePickerState } from '../use-date-picker-state'

export interface CalendarProps {
  disabled?: boolean
  dateValid: boolean
  minDate: Date | undefined
  maxDate: Date | undefined
  value: Date | undefined
  handleDateChange: (newDate: Date | Date[]) => void
}
export const CJCalendar: React.FC<CalendarProps> = ({
  disabled,
  dateValid,
  minDate,
  maxDate,
  value,
  handleDateChange
}) => {
  return (
    <div
      data-testid="calendar"
      className={`${disabled ? 'opacity-37' : ''} ${
        !dateValid ? 'bs-danger' : ''
      }  border rounded`}
    >
      <Calendar
        tileDisabled={() => disabled || false}
        className="w-100 border-bottom rounded-top cj-calendar"
        minDate={minDate}
        maxDate={maxDate}
        showNeighboringMonth={false}
        minDetail="month"
        formatShortWeekday={(locale, date) => format(date, 'eeeee')}
        maxDetail="month"
        nextAriaLabel="Next Month"
        nextLabel={
          <FontAwesomeIcon
            color="primary"
            size="xs"
            className="align-middle"
            icon={faPlay}
          />
        }
        prevLabel={
          <FontAwesomeIcon
            color="primary"
            size="xs"
            className="align-middle"
            icon={faPlay}
            rotation={180}
          />
        }
        onChange={changeDate => handleDateChange(changeDate)}
        value={value}
      />

      <div
        style={{ marginTop: '-1px', minHeight: '4rem' }}
        className="bg-lightButNotTooLight text-center p-3 font-larger font-weight-bold rounded-bottom"
      >
        {!dateValid && (
          <div className="font-weight-bold text-danger">
            Please select a deadline date
          </div>
        )}
        {value && (
          <>
            <FontAwesomeIcon className="mr-3" icon={faCalendar} />
            <span>{format(value, 'dd MMMM yyyy')}</span>
          </>
        )}
      </div>
    </div>
  )
}
export interface DateRadioProps {
  chooseDate: boolean
  handleChangeRadio: (event: ChangeEvent<HTMLInputElement>) => void
}
export const DateRadio: React.FC<DateRadioProps> = ({
  chooseDate,
  handleChangeRadio
}) => {
  return (
    <FormGroup tag="fieldset">
      <FormGroup check className="mb-5 pl-0 ">
        <div className="custom-control custom-radio">
          <Input
            data-testid="input-date-undefined"
            type="radio"
            id="none"
            name="none"
            value={0}
            className="custom-control-input"
            checked={!chooseDate}
            onChange={event => handleChangeRadio(event)}
          />
          <Label
            for="none"
            className="custom-control-label"
            data-testid="label-date-undefined"
            check
          >
            No timeline
          </Label>
        </div>
      </FormGroup>
      <FormGroup check className="mb-5 pl-0">
        <div className="custom-control custom-radio">
          <Input
            data-testid="input-date-defined"
            type="radio"
            name="date"
            id="date"
            className="custom-control-input"
            value={1}
            checked={chooseDate}
            onChange={event => handleChangeRadio(event)}
          />
          <Label
            for="date"
            className="custom-control-label"
            data-testid="label-date-defined"
            check
          >
            Choose a deadline date for the project
          </Label>
        </div>
      </FormGroup>
    </FormGroup>
  )
}

export const DateStep: React.FC<WizardStepProps<DateStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  data,
  goBack,
  totalSteps,
  onChange
}) => {
  const {
    dateValid,
    chooseDate,
    setDateValid,
    handleChangeRadio,
    minDate,
    maxDate,
    handleDateChange
  } = useDatePickerState(data.endDate, newDate => {
    if (onChange) {
      onChange({ endDate: newDate })
    }
  })

  return (
    <Step
      stepNumber={stepNumber}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <StepDefinition
        stepNumber={stepNumber}
        stepTitle="Timeline"
        totalSteps={totalSteps}
        currentStep={currentStep}
        goBack={() => {
          if (goBack) {
            goBack()
          }
        }}
        goForward={() => {
          const isDateValid = !chooseDate || data.endDate !== undefined
          setDateValid(isDateValid)
          if (isDateValid) {
            goForward()
          }
        }}
      >
        <Label className="wizard-label font-weight-bold mb-2 font-larger">
          Please choose your timeline for project completion
        </Label>
        <p className="text-secondary mb-45">
          If applicable please select a date for completion of the project, you
          can change the date later if required.
        </p>
        <DateRadio
          chooseDate={chooseDate}
          handleChangeRadio={handleChangeRadio}
        />
      </StepDefinition>
      <StepControls>
        {chooseDate && (
          <CJCalendar
            minDate={minDate}
            maxDate={maxDate}
            value={data.endDate}
            dateValid={dateValid}
            handleDateChange={handleDateChange}
          />
        )}
      </StepControls>
    </Step>
  )
}
