import React from 'react'
import { useSpring, animated } from 'react-spring'

interface ToggleProps {
  toggle?: boolean
}

export const Jiggler: React.FC<ToggleProps> = ({
  children,
  toggle
}): JSX.Element => {
  const { x } = useSpring({
    from: { x: 0 },
    x: toggle ? 1 : 0,
    config: { duration: 1000 }
  })

  return (
    <animated.div
      style={{
        transform: x
          .interpolate({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
          })
          .interpolate(z => `scale(${z})`)
      }}
    >
      {children}
    </animated.div>
  )
}
