import React, { FC, useMemo, useState, useEffect } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'
import useReactRouter from 'use-react-router'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { xxHash32 } from 'js-xxhash'
import { format } from 'date-fns'
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Spinner,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import querystring from 'querystring'
import {
  faClock,
  faCalendar,
  faCheck,
  faChevronLeft,
  faChartBar,
  faGavel,
  faScroll,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { useGetBearerToken } from '../use-get-bearer-token'
import {
  PathAndTabParam,
  Project,
  PlanSummary,
  EditFields,
  ArtefactOutcomes
} from '../../types'
import { useConfig } from '../../use-remote-config'
import { ProjectDetail } from './project-detail'
import { TabButton } from '../shared/tabs/subcomponents/tab-button'
import { ReactComponent as PlanLogo } from '../../assets/icon-plan-empty.svg'
import { useJudgeManager } from '../use-judge-manager'
import { JudgeProgress } from '../shared/judge-progress/judge-progress'
import { ProgressView } from './progress-view'
import { InProgressArtefactView } from './inprogress-artefact-view'
import { JudgeTab } from './judge-tab'
import { PlanView } from './plan-view'
import { ProjectStatusView } from './plan-status'
import { isNotFound } from '../../helpers/axios-error-helpers'
import { ProjectHeader } from './project-header'
import { TabList } from '../shared/tabs/tab-list'
import { Tab } from '../shared/tabs/subcomponents/tab'
import { DraftArtefactsView } from './draft-artefact-view'
import { TargetMetNotificaton } from '../target-met-notification'
import { FaCircleBell } from '../shared/icons/fa-circle-bell'
import { ProjectMethodDetail } from './project-method-detail'
import { SimpleErrorMessage } from '../shared/simple-message/simple-error-message'
import { CompletedSummary, ReportTitle, SSR } from './completed-summary'
import { CompletedJudgeView } from './completed-judge-view'
import { DraftActionsDropDown } from './draft-actions-dropdown'
import { ProjectDeleteModal } from './project-delete-modal'
import { ProjectEditModal } from './project-edit-modal'
import { CompletedArtefactView } from './completed-artefact-view'
import { CompletedActionsDropDown } from './completed-actions-dropdown'
import { useDownloadFile } from '../use-download-file'
import { Loading } from '../loading'
import { InlineErrorMessage } from '../shared/simple-message/inline-error-message'
import { ManageAdminModal } from '../modals/manage-admins-modal'
import { ActionsDropDown } from './actions-dropdown'
import { useNewArtefactManager, NOCAT } from '../use-artefact-manager-new'

export const ProjectView: FC = (): JSX.Element => {
  const bearerToken = useGetBearerToken()
  const { config } = useConfig()
  const { match, history, location } = useReactRouter<PathAndTabParam>()
  const [project, setProject] = useState<Project | undefined>(undefined)
  const getUrl = `${config.apiUrl}/projects/${match.params.id}`

  const rawDataDownload = useDownloadFile({
    fileUrl: `${config.apiUrl}/artefacts/${encodeURIComponent(
      `outcomes/${project ? project.id : 'undefined'}.csv`
    )}`,
    fileName: `${project ? project.id : 'undefined'}-RAW.csv`
  })

  const earlyOutcomesDownload = useDownloadFile({
    fileUrl: `${config.apiUrl}/artefacts/${encodeURIComponent(
      `earlyoutcomes/${match.params.id}${
        project ? `-${project.completedTasks}` : ''
      }.csv`
    )}`,
    fileName: `${match.params.id}${
      project ? `-${project.completedTasks}` : ''
    }-RAW.csv`
  })

  const artefactDataDownload = useDownloadFile({
    fileUrl: `${config.apiUrl}/artefacts/${encodeURIComponent(
      `reports/artefacts/${project ? project.id : 'undefined'}.csv`
    )}`,
    fileName: `${project ? project.id : 'undefined'}-Ranked.csv`
  })

  const judgeDataDownload = useDownloadFile({
    fileUrl: `${config.apiUrl}/artefacts/${encodeURIComponent(
      `reports/judges/${project ? project.id : 'undefined'}.csv`
    )}`,
    fileName: `${project ? project.id : 'undefined'}-Judges.csv`
  })

  const getOutcomesMemo = useMemo(() => {
    return {
      url: `${getUrl}/outcomes`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])

  const getOutcomesTask = useAsyncTaskAxios<AxiosResponse<ArtefactOutcomes>>(
    axios,
    getOutcomesMemo
  )

  const activeTab = useMemo(() => {
    switch (match.params.tab) {
      case 'plan':
        return 3
      case 'judges':
        return 2
      case 'artefacts':
        return 1
      default:
        return 1
    }
  }, [match.params.tab])
  const planUrl = `${config.apiUrl}/projects/${match.params.id}/plan`

  const createPlanMemo = useMemo(() => {
    return {
      method: 'PUT',
      url: planUrl,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [bearerToken, planUrl])
  const createPlanTask = useAsyncTaskAxios<AxiosResponse<PlanSummary>>(
    axios,
    createPlanMemo
  )
  const getPlanMemo = useMemo(() => {
    return {
      url: planUrl,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [planUrl, bearerToken, createPlanTask.result])
  const getPlanTask = useAsyncTaskAxios<AxiosResponse<PlanSummary>>(
    axios,
    getPlanMemo
  )

  const shouldGetOutcomes = useMemo(() => {
    return (
      bearerToken !== undefined &&
      project !== undefined &&
      project.status === 'COMPLETE' &&
      getPlanTask.result !== null &&
      getPlanTask.result.data.connected === 'CONNECTED'
    )
  }, [bearerToken, project, getPlanTask.result])
  useAsyncRun(shouldGetOutcomes && getOutcomesTask)
  const getEarlyOutcomesMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${
        match.params.id
      }/tasks/csv?completedTasks=${project ? project.completedTasks : 0}`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config, bearerToken, match.params.id, project])
  const getEarlyOutcomesTask = useAsyncTaskAxios<AxiosResponse>(
    axios,
    getEarlyOutcomesMemo
  )
  useEffect(() => {
    if (getEarlyOutcomesTask.result) {
      earlyOutcomesDownload.start()
    }
  }, [getEarlyOutcomesTask.result])

  const startProjectMemo = useMemo(() => {
    return {
      url: `${getUrl}/start`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])

  const startProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    startProjectMemo
  )
  const getProjectMemo = useMemo(() => {
    return {
      url: getUrl,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken, startProjectTask.result])
  const getProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    getProjectMemo
  )

  useAsyncRun(bearerToken && getProjectTask)

  useEffect(() => {
    if (getProjectTask.result) {
      setProject(getProjectTask.result.data)
    } else {
      setProject(undefined)
    }
  }, [getProjectTask.result])
  const planNotFound = useMemo(() => {
    if (!getPlanTask.error) {
      return false
    }
    return isNotFound((getPlanTask.error as AxiosError).response)
  }, [getPlanTask.error])
  const getPlanErrored = useMemo(() => {
    return getPlanTask.error !== null && !planNotFound
  }, [getPlanTask.error])
  const plan = useMemo(() => {
    return getPlanTask.result ? getPlanTask.result.data : undefined
  }, [getPlanTask.result])

  useEffect(() => {
    if (
      project &&
      project.status === 'DRAFT' &&
      project.categories.length > 0 &&
      match.params.tab === 'artefacts' &&
      (!match.params.category ||
        (match.params.category &&
          !project.categories.includes(match.params.category)))
    ) {
      // redirect to first category
      history.replace(
        `/projects/${project.id}/artefacts/${project.categories[0]}`
      )
    }
    if (
      project &&
      match.params.tab === 'artefacts' &&
      project.categories.length === 0 &&
      match.params.category
    ) {
      // redirect to no cat
      history.replace(`/projects/${project.id}/artefacts`)
    }
  }, [match.params.category, project, match.params.tab, match.params.category])

  const currentCategory = useMemo(() => {
    if (match.params.category) {
      return match.params.category
    }
    if (project && project.categories.length > 0) {
      return project.categories[0]
    }
    return undefined
  }, [project, match.params.category])
  useAsyncRun(bearerToken && getPlanTask)
  const artefactManager = useNewArtefactManager(
    match.params.id,
    currentCategory,
    project && project.planUploaded && getPlanTask.result
      ? getPlanTask.result.data.artefactCategoryGroups[currentCategory || NOCAT]
      : undefined
  )
  const { artefactState, retrievingArtefacts, getError } = artefactManager
  const judgeUpload = useJudgeManager(match.params.id, [
    startProjectTask.result
  ])

  const planInputValid = useMemo(() => {
    return (
      project !== undefined &&
      ((project.planUploaded && getPlanTask.result) || !project.planUploaded) &&
      artefactState.artefactMap.size > project.packSize &&
      (project.categories.length === 0 ||
        Array.from(artefactState.categoryCompleted.values()).filter(
          x => x.size < Math.floor(project.packSize / project.categories.length)
        ).length === 0)
    )
  }, [
    project,
    judgeUpload.allJudges,
    artefactState.artefactMap,
    artefactState.categoryCompleted
  ])
  const targetArtefactCount = useMemo(() => {
    if (
      !project ||
      !project.planUploaded ||
      !plan ||
      project.status !== 'DRAFT'
    ) {
      return undefined
    }
    return plan.artefactCount
  }, [plan, project])
  const targetJudgeCount = useMemo(() => {
    if (
      project &&
      project.planUploaded &&
      plan &&
      project.status !== 'COMPLETE'
    ) {
      return Math.max(plan.judgeCount, judgeUpload.allJudges.length)
    } else if (
      project &&
      !project.planUploaded &&
      judgeUpload.placeholders.length > 0
    ) {
      return judgeUpload.allJudges.length
    }
    return undefined
  }, [plan, project, judgeUpload.placeholders, judgeUpload.allJudges])
  const planValid = useMemo(() => {
    // calculate the judge and artefact hashes and compare to the plan hashes
    if (
      getPlanTask.result &&
      !(retrievingArtefacts && startProjectTask.pending) &&
      artefactState.categoryCompleted.size > 0
    ) {
      let allKeys: string[] = []
      artefactState.categoryCompleted.forEach((val, key) => {
        allKeys.push(...val.values())
      })

      allKeys.sort()
      const artefactString = allKeys.join(':')
      // why is this a string
      const artefactHash = xxHash32(
        Buffer.from(artefactString, 'utf8')
      ).valueOf()

      return (
        artefactHash === getPlanTask.result.data.artefactHash &&
        project !== undefined &&
        (project.planUploaded ||
          judgeUpload.allJudges.length ===
            getPlanTask.result.data.judgeCount) &&
        project.packSize === getPlanTask.result.data.packSize &&
        ((project.planUploaded !== undefined &&
          project.planUploaded === true) ||
          project.packsPerArtefact ===
            getPlanTask.result.data.targetPacksPerArtefact)
      )
    }
    return true
  }, [
    getPlanTask.result,
    judgeUpload.allJudges,
    artefactState.categoryCompleted,
    retrievingArtefacts,
    startProjectTask.pending,
    project
  ])
  const [showEditModal, setShowEditModal] = useState<EditFields | undefined>(
    undefined
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAdminsModal, setShowAdminsModal] = useState(false)
  const printMode = useMemo<boolean>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )
    if (!qs.print) {
      return false
    }

    return true
  }, [location])
  const completedCount = useMemo(() => {
    return Array.from(artefactState.categoryCompleted.values()).reduce(
      (total, x) => total + x.size,
      0
    )
  }, [artefactState])
  const toUploadCount = useMemo(() => {
    return Array.from(artefactState.cagtegoryUploading.values()).reduce(
      (total, x) => total + x.size,
      0
    )
  }, [artefactState])
  const allDepsForReport = useMemo(() => {
    if (
      getProjectTask.result &&
      getOutcomesTask.result &&
      judgeUpload.allJudges.length > 0
    ) {
      return true
    }
    return false
  }, [getProjectTask.result, getOutcomesTask.result, judgeUpload.allJudges])

  useEffect(() => {
    if (printMode && allDepsForReport) {
      window.print()
      history.goBack()
    }
  }, [printMode, allDepsForReport])

  useEffect(() => {
    if (project && startProjectTask.result) {
      history.push(`/projects/${project.id}/judges?started`)
    }
  }, [startProjectTask.result])
  return (
    <>
      {project && (
        <ManageAdminModal
          project={project}
          onRemoveCurrentUser={() => {
            history.push(`/projects?status=${project.status}`)
          }}
          isOpen={showAdminsModal}
          toggle={() => setShowAdminsModal(!showAdminsModal)}
        />
      )}
      {getProjectTask.error !== null && (
        <Container className="mb-4 py-45">
          <FontAwesomeIcon size="sm" icon={faChevronLeft} className="mr-2 " />
          <Link to="/projects" className="font-weight-bold text-secondary">
            Projects
          </Link>

          <SimpleErrorMessage
            className="my-45"
            title=" Failed to get project"
            message="Please refresh the page, and if the problem persists contact your system administrator."
            allowPageRefresh
          />
        </Container>
      )}
      {getProjectTask.pending && <Loading className="d-block mx-auto my-5" />}
      {project && (
        <>
          <ProjectHeader
            projectStatus={project.status}
            hideBackButton={printMode}
          >
            <Row className="align-items-end">
              <Col
                xs={24}
                md={{ size: 11, order: 1 }}
                className="mb-42 align-self-stretch"
              >
                {project && <ProjectDetail {...project} />}
              </Col>
              <Col
                xs={24}
                md={{ size: 13, order: 3 }}
                className="mb-45 mb-md-0"
              >
                {project && <ProjectMethodDetail {...project} />}
              </Col>
              <Col
                xs={24}
                md={{ size: 13, offset: 0, order: 2 }}
                lg={{ size: 11, offset: 2 }}
                xl={{ size: 9, offset: 4 }}
                className="mb-42 mt-md-0 text-right align-self-stretch"
              >
                {!printMode && (
                  <TabList>
                    <Tab to={`/projects/${match.params.id}/artefacts`}>
                      <TabButton
                        title="Artefacts"
                        isPending={
                          retrievingArtefacts ||
                          (project &&
                            project.status === 'COMPLETE' &&
                            shouldGetOutcomes &&
                            getOutcomesTask.pending)
                        }
                        isActive={activeTab === 1}
                      >
                        {targetArtefactCount &&
                          artefactState.artefactMap.size !==
                            targetArtefactCount && <TargetMetNotificaton />}
                        <div className="h4 font-weight-bold my-0">
                          {getError !== null && (
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          )}
                          {getError === null && (
                            <>
                              {completedCount}
                              {targetArtefactCount &&
                                completedCount !== targetArtefactCount &&
                                ` / ${targetArtefactCount}`}
                              {targetArtefactCount &&
                                completedCount === targetArtefactCount && (
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faCheck}
                                    className="ml-2"
                                  />
                                )}
                              {toUploadCount > 0 && (
                                <>
                                  <Spinner
                                    id="uploadSpinner"
                                    size="sm"
                                    className="ml-2 align-middle"
                                  />
                                  <UncontrolledTooltip
                                    target="uploadSpinner"
                                    placement="bottom"
                                  >
                                    <div className="px-35 py-3 font-weight-bold">
                                      <span>Uploading...</span>
                                      <span className="font-larger ">{` ${toUploadCount} `}</span>
                                      <span> remaining</span>
                                    </div>
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </TabButton>
                    </Tab>
                    <Tab to={`/projects/${match.params.id}/judges`}>
                      <TabButton
                        title="Judges"
                        isActive={activeTab === 2}
                        isPending={judgeUpload.gettingJudges}
                      >
                        {targetJudgeCount &&
                          judgeUpload.nonPlaceholders.length !==
                            targetJudgeCount && <TargetMetNotificaton />}
                        <div className="h4 font-weight-bold my-0">
                          {judgeUpload.resourceManager.getError !== null && (
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          )}
                          {judgeUpload.resourceManager.getError === null && (
                            <>
                              {project.status === 'COMPLETE'
                                ? judgeUpload.nonPlaceholders.filter(
                                    x => x.totalTasks > 0
                                  ).length
                                : judgeUpload.nonPlaceholders.length}
                              {targetJudgeCount &&
                                judgeUpload.nonPlaceholders.length !==
                                  targetJudgeCount &&
                                targetJudgeCount >
                                  judgeUpload.nonPlaceholders.length &&
                                ` / ${targetJudgeCount}`}
                              {targetJudgeCount &&
                                judgeUpload.nonPlaceholders.length ===
                                  targetJudgeCount && (
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faCheck}
                                    className="ml-2"
                                  />
                                )}
                            </>
                          )}
                        </div>
                      </TabButton>
                    </Tab>
                    <Tab to={`/projects/${match.params.id}/plan`}>
                      <TabButton
                        title="Plan"
                        isActive={activeTab === 3}
                        isPending={getPlanTask.pending}
                      >
                        {judgeUpload.allocationsChanged && (
                          <TargetMetNotificaton targetNotMetMessage="The original allocation of tasks to judges has changed" />
                        )}
                        {!planValid &&
                          project.status === 'DRAFT' &&
                          !project.planUploaded &&
                          !(
                            judgeUpload.gettingJudges ||
                            retrievingArtefacts ||
                            getPlanTask.pending
                          ) && (
                            <>
                              <div
                                id="InvalidPlanNotification"
                                className="position-absolute"
                                style={{
                                  right: '-1rem',
                                  top: '-0.75rem'
                                }}
                              >
                                <FaCircleBell className="fa-2x" />
                              </div>
                              <UncontrolledTooltip
                                placement="top"
                                target="InvalidPlanNotification"
                              >
                                {"You've made some recent changes and need to "}
                                <b>
                                  <u>revise the plan</u>
                                </b>
                                {' to start project'}
                              </UncontrolledTooltip>
                            </>
                          )}
                        {getPlanErrored && (
                          <FontAwesomeIcon
                            className="h4 my-0"
                            icon={faExclamationTriangle}
                          />
                        )}
                        {!getPlanErrored && (
                          <PlanLogo
                            width={28}
                            height={28}
                            fill={activeTab === 3 ? '#ffffff' : '#000000'}
                            stroke={activeTab === 3 ? '#ffffff' : '#000000'}
                          />
                        )}
                      </TabButton>
                    </Tab>
                  </TabList>
                )}
                {printMode &&
                  project.status === 'COMPLETE' &&
                  project.completedDate && (
                    <ReportTitle completedDate={project.completedDate} />
                  )}
              </Col>

              <Col
                xs={24}
                md={{ size: 11, offset: 0, order: 4 }}
                lg={{ size: 9, offset: 2 }}
                className="mb-0 mt-md-0 text-right"
              >
                <Row className="text-left align-items-center">
                  {project && project.endDate && project.status === 'DRAFT' && (
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faClock} className="mr-2 " />
                      <b>
                        {`Due date is ${format(
                          project.endDate,
                          'MMM dd, yyyy'
                        )}`}
                      </b>
                    </Col>
                  )}
                  {project !== undefined && project.status === 'INPROGRESS' && (
                    <Col xs="auto">
                      <ActionsDropDown>
                        <DropdownMenu right className="mt-3">
                          {project.completedTasks !== undefined &&
                            project.completedTasks > 0 && (
                              <>
                                <DropdownItem
                                  className="py-3 text-left font-weight-bold "
                                  onClick={e => {
                                    e.preventDefault()
                                    getEarlyOutcomesTask.start()
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faChartBar}
                                    className="mr-3"
                                    fixedWidth
                                  />
                                  Download completed tasks
                                </DropdownItem>
                                <DropdownItem divider />
                              </>
                            )}
                          <DropdownItem
                            className="py-3 text-left font-weight-bold "
                            onClick={e => {
                              e.preventDefault()
                              setShowAdminsModal(true)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faUsers}
                              className="mr-3"
                              fixedWidth
                            />
                            Manage Admins
                          </DropdownItem>
                        </DropdownMenu>
                      </ActionsDropDown>
                    </Col>
                  )}
                  <Col className="text-right">
                    {project.status === 'COMPLETE' &&
                      printMode &&
                      getOutcomesTask.result && (
                        <SSR ssr={getOutcomesTask.result.data.ssr} />
                      )}
                    {project.status === 'DRAFT' && (
                      <>
                        <DraftActionsDropDown
                          onManageAdminsClicked={() => {
                            setShowAdminsModal(true)
                          }}
                          onEditClicked={(editField: EditFields) => {
                            setShowEditModal(editField)
                          }}
                          onDeleteClicked={() => {
                            setShowDeleteModal(true)
                          }}
                          disableDelete={startProjectTask.result !== null}
                          disabledFields={
                            project.planUploaded ? ['METHOD'] : []
                          }
                        />

                        <ProjectDeleteModal
                          projectId={project.id}
                          isOpen={showDeleteModal}
                          toggle={() => setShowDeleteModal(!showDeleteModal)}
                        />
                        <ProjectEditModal
                          toggle={() => setShowEditModal(undefined)}
                          project={project}
                          showEditModal={showEditModal}
                          onProjectUpdate={updated => setProject(updated)}
                        />
                      </>
                    )}
                    {project.status === 'INPROGRESS' && project.startDate && (
                      <div className="text-primary">
                        <FontAwesomeIcon icon={faCalendar} className="mr-2 " />
                        <b>
                          {`Started on ${format(
                            project.startDate,
                            'MMM dd, yyyy'
                          )}`}
                        </b>
                      </div>
                    )}
                    {project.status === 'COMPLETE' && !printMode && (
                      <CompletedActionsDropDown
                        projectId={project.id}
                        rankingAvailable={shouldGetOutcomes}
                        onDownloadJudgeDataClick={() => {
                          judgeDataDownload.start()
                        }}
                        onDownloadArtefactDataClick={() => {
                          artefactDataDownload.start()
                        }}
                        onDownloadRawDataClick={() => {
                          rawDataDownload.start()
                        }}
                        onManageAdminsClick={() => {
                          setShowAdminsModal(true)
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </ProjectHeader>
          {project.status === 'INPROGRESS' && <ProgressView {...project} />}
          <Container className="py-55">
            {project.status === 'COMPLETE' &&
              project.completedDate &&
              !printMode && (
                <CompletedSummary
                  className="mb-42"
                  completedDate={project.completedDate}
                  ssr={
                    getOutcomesTask.result
                      ? getOutcomesTask.result.data.ssr
                      : undefined
                  }
                />
              )}
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>
                {project.status === 'COMPLETE' && !shouldGetOutcomes && (
                  <>
                    <InlineErrorMessage
                      className="mb-42 border-ddd py-42"
                      title="Artefacts cannot be ranked for unconnected plans"
                      message="Please download the raw data if you wish to perform out of system analyis on the judgements collected"
                    />

                    <InProgressArtefactView
                      artefactMap={artefactState.artefactMap}
                      categorisedArtefactKeys={artefactState.categoryCompleted}
                    />
                  </>
                )}
                {(getError !== null || getOutcomesTask.error !== null) && (
                  <SimpleErrorMessage
                    className="mb-5"
                    title={
                      getOutcomesTask.error !== null
                        ? 'Failed to get artefact rank outcomes'
                        : 'Failed to get artefacts'
                    }
                    message="Please refresh the page, and if the problem persists contact your system administrator."
                    allowPageRefresh
                  />
                )}
                {getError === null && getOutcomesTask.error == null && (
                  <>
                    {project.status === 'COMPLETE' && getOutcomesTask.result && (
                      <>
                        {printMode && (
                          <>
                            <Row className="mb-42">
                              <Col className="h5 font-weight-bold">
                                <FontAwesomeIcon
                                  icon={faScroll}
                                  className="mr-3"
                                />
                                Artefact Data
                              </Col>
                              <Col className="h4 font-weight-bold text-right">
                                {
                                  getOutcomesTask.result.data.artefactRanks
                                    .length
                                }
                              </Col>
                            </Row>
                          </>
                        )}
                        <CompletedArtefactView
                          projectId={project.id}
                          artefacts={getOutcomesTask.result.data.artefactRanks}
                          artefactsPerPack={project.packSize}
                        />
                        {printMode && (
                          <>
                            <Row className="mb-42">
                              <Col className="h5 font-weight-bold">
                                <FontAwesomeIcon
                                  icon={faGavel}
                                  className="mr-3"
                                />
                                Judge Data
                              </Col>
                              <Col className="h4 font-weight-bold text-right">
                                {judgeUpload.allJudges.length}
                              </Col>
                            </Row>
                            <CompletedJudgeView
                              artefactsPerPack={project.packSize}
                              judges={judgeUpload.allJudges}
                            />
                          </>
                        )}
                      </>
                    )}

                    {project.status === 'DRAFT' && (
                      <DraftArtefactsView
                        project={project}
                        manager={artefactManager}
                        plan={plan}
                        currentCategory={currentCategory}
                      />
                    )}
                    {project.status === 'INPROGRESS' && (
                      <InProgressArtefactView
                        artefactMap={artefactState.artefactMap}
                        categorisedArtefactKeys={
                          artefactState.categoryCompleted
                        }
                      />
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tabId={2}>
                {judgeUpload.resourceManager.getError !== null && (
                  <SimpleErrorMessage
                    className="mb-5"
                    title=" Failed to get judges"
                    message="Please refresh the page, and if the problem persists contact your system administrator."
                    allowPageRefresh
                  />
                )}
                {judgeUpload.resourceManager.getError === null && (
                  <>
                    {project.status === 'COMPLETE' && (
                      <CompletedJudgeView
                        artefactsPerPack={project.packSize}
                        judges={judgeUpload.allJudges}
                      />
                    )}
                    {project.status === 'INPROGRESS' && (
                      <JudgeProgress
                        onReallocationSuccess={updatedJudges => {
                          judgeUpload.replaceJudges(updatedJudges)
                        }}
                        projectId={project.id}
                        artefactsPerPack={project.packSize}
                        judges={judgeUpload.allJudges}
                        onJudgePauseChanged={(judgeId, isPaused) => {
                          judgeUpload.updateJudgePauseState(judgeId, isPaused)
                        }}
                      />
                    )}
                    {project.status === 'DRAFT' && (
                      <JudgeTab
                        projectId={project.id}
                        judgeState={judgeUpload}
                        expectedJudges={
                          project && project.planUploaded && plan
                            ? plan.judgeCount
                            : undefined
                        }
                      />
                    )}
                  </>
                )}
              </TabPane>
              <TabPane tabId={3}>
                {project.status === 'DRAFT' &&
                  !startProjectTask.result &&
                  !getPlanErrored && (
                    <ProjectStatusView
                      className="mb-45"
                      planConnected={
                        plan ? plan.connected === 'CONNECTED' : false
                      }
                      creationPending={
                        createPlanTask.started && createPlanTask.pending
                      }
                      startPending={
                        startProjectTask.started && startProjectTask.pending
                      }
                      startFailed={startProjectTask.error !== null}
                      planInputValid={planInputValid}
                      planNotFound={planNotFound}
                      planValid={planValid}
                      project={project}
                      onCreate={() => {
                        createPlanTask.start()
                      }}
                      onChangeDate={() => setShowEditModal('DATE')}
                      onStart={() => {
                        if (getPlanTask.result) {
                          startProjectTask.start({
                            data: [
                              ...judgeUpload.nonPlaceholders.map(
                                x => x.user_id
                              ),
                              ...judgeUpload.placeholders.map(x => x.user_id)
                            ]
                          })
                        }
                      }}
                    />
                  )}
                {!(project.status === 'DRAFT' && startProjectTask.result) && (
                  <PlanView
                    planTask={getPlanTask}
                    planUploaded={project.planUploaded}
                    targetPacksPerArtefact={project.packsPerArtefact}
                    projectId={project.id}
                    onRecreate={
                      project.status === 'DRAFT'
                        ? () => {
                            createPlanTask.start()
                          }
                        : undefined
                    }
                  />
                )}
              </TabPane>
            </TabContent>
          </Container>
        </>
      )}
    </>
  )
}
