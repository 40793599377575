import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPen,
  faQuestion,
  faSlidersH,
  faCalendar,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { UncontrolledTooltip, DropdownMenu, DropdownItem } from 'reactstrap'
import { EditFields } from '../../types'
import { ActionsDropDown } from './actions-dropdown'

export interface NewDraftActionProps {
  onDeleteClicked: () => void
  onManageAdminsClicked: () => void
  onEditClicked: (field: EditFields) => void
  disableDelete: boolean
  disabledFields: EditFields[]
}

export const DraftActionsDropDown: FC<NewDraftActionProps> = ({
  onDeleteClicked,
  onEditClicked,
  onManageAdminsClicked,
  disableDelete,
  disabledFields
}) => {
  return (
    <ActionsDropDown>
      <DropdownMenu right className="mt-3 bg-black">
        {!disabledFields.includes('NAME') && (
          <DropdownItem
            onClick={() => {
              onEditClicked('NAME')
            }}
            className="text-left py-3 font-weight-bold mr-45 "
          >
            <FontAwesomeIcon icon={faPen} className="mr-3" fixedWidth />
            Edit project name
          </DropdownItem>
        )}
        {!disabledFields.includes('QUESTION') && (
          <DropdownItem
            className="text-left py-3 font-weight-bold "
            onClick={() => {
              onEditClicked('QUESTION')
            }}
          >
            <FontAwesomeIcon icon={faQuestion} className="mr-3" fixedWidth />
            Edit question
          </DropdownItem>
        )}
        {!disabledFields.includes('METHOD') && (
          <DropdownItem
            className="text-left py-3 font-weight-bold "
            onClick={() => {
              onEditClicked('METHOD')
            }}
          >
            <FontAwesomeIcon icon={faSlidersH} className="mr-3" fixedWidth />
            Edit method
          </DropdownItem>
        )}
        {!disabledFields.includes('DATE') && (
          <DropdownItem
            className="text-left py-3 font-weight-bold "
            onClick={() => {
              onEditClicked('DATE')
            }}
          >
            <FontAwesomeIcon icon={faCalendar} className="mr-3" fixedWidth />
            Edit due date
          </DropdownItem>
        )}
        <DropdownItem divider />
        <DropdownItem
          className="py-3 text-left font-weight-bold "
          onClick={() => onManageAdminsClicked()}
        >
          <FontAwesomeIcon icon={faUsers} className="mr-3" fixedWidth />
          Manage Admins
        </DropdownItem>
        <DropdownItem
          disabled={disableDelete}
          id="btn-delete-project"
          onClick={() => {
            onDeleteClicked()
          }}
          className="text-redonblack py-3 text-left font-weight-bold "
        >
          <FontAwesomeIcon icon={faTrash} className="mr-3" fixedWidth />
          Delete project
          {disableDelete && (
            <UncontrolledTooltip placement="bottom" target="btn-delete-project">
              Project cannot be deleted once started
            </UncontrolledTooltip>
          )}
        </DropdownItem>
      </DropdownMenu>
    </ActionsDropDown>
  )
}
