import produce from 'immer'
import { ProjectAssetAction, RemovableResource } from '../types'

export const REFRESH_ACTION = 'refresh'
export const ADD_OR_UPDATE_ACTION = 'add_or_update'
export const REMOVE_START_ACTION = 'remove_start'
export const REMOVE_COMPLETE_ACTION = 'remove_complete'
export const REMOVE_ERROR = 'remove_error'
export const REPLACE_ITEM = 'replace'

export const projectResourceReducer = <T extends RemovableResource>() => (
  state: Map<string, T>,
  action: ProjectAssetAction<T>
): Map<string, T> => {
  return produce(state, (draft: Map<string, T>) => {
    switch (action.type) {
      case REFRESH_ACTION: {
        return new Map(
          (action.payload || []).map(x => [action.idFunction(x), x])
        )
      }
      case ADD_OR_UPDATE_ACTION: {
        const toAddNew = new Map(
          (action.payload || []).map(x => [action.idFunction(x), x])
        )

        return new Map([...draft, ...toAddNew])
      }
      case REPLACE_ITEM: {
        if (action.payload) {
          draft.set(action.idFunction(action.payload[0]), action.payload[0])
        }
        return draft
      }
      case REMOVE_START_ACTION: {
        if (action.deleteId) {
          const existing = draft.get(action.deleteId)
          if (existing) {
            const updated = {
              ...existing,
              deleting: true,
              deleteFailed: false
            }
            draft.set(action.deleteId, updated)
          }

          return draft
        }
        return draft
      }
      case REMOVE_ERROR: {
        if (action.deleteId) {
          const existing = draft.get(action.deleteId)
          if (existing) {
            const updated = {
              ...existing,
              deleting: false,
              deleteFailed: true
            }

            draft.set(action.deleteId, updated)
          }
        }
        return draft
      }
      case REMOVE_COMPLETE_ACTION: {
        if (action.deleteId) {
          draft.delete(action.deleteId)
        }
        return draft
      }
      default:
        return draft
    }
  })
}
