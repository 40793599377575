import React from 'react'

import { Col } from 'reactstrap'

export interface CountRowProps {
  title: string
  count?: number
}

export const CountRow: React.FunctionComponent<CountRowProps> = ({
  title,
  count
}) => (
  <>
    <Col>{title}</Col>
    <Col xs="auto" className="text-right  font-weight-bold h4 my-0">
      {count}
    </Col>
  </>
)
