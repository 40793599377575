import React from 'react'
import { Col, Row } from 'reactstrap'
import { Project } from '../../types'

export const ProjectMethodDetail: React.FC<Project> = ({
  packSize,
  planUploaded,
  packsPerArtefact
}): JSX.Element => {
  return (
    <>
      <Row>
        <Col xs="auto" className=" text-left pr-4 pr-xl-6">
          <div className=" text-secondary font-weight-bold">Pack size</div>
          <div className="h4 mb-0 font-weight-bold">{packSize}</div>
        </Col>
        {planUploaded && (
          <Col xs="auto" className="pr-4 pr-xl-6  text-left">
            <div className=" text-secondary font-weight-bold">Plan Type</div>
            <div className="h5 mb-0 font-weight-bold">Manual</div>
          </Col>
        )}
        {!planUploaded && packsPerArtefact && (
          <>
            <Col xs="auto" className="pr-4 pr-xl-6  text-left">
              <div className=" text-secondary font-weight-bold">
                Packs per artefact
              </div>
              <div className="h4 mb-0 font-weight-bold">{packsPerArtefact}</div>
            </Col>
            <Col xs="auto" className=" text-left">
              <div className=" text-secondary font-weight-bold">
                Judgements per artefact
              </div>
              <div className="h4 mb-0 font-weight-bold">
                {(packSize - 1) * packsPerArtefact}
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  )
}
