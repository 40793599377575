import React, { useState, useMemo, useEffect, FC } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { shuffle, keys, values } from 'lodash'
import { ScrollSync } from 'react-scroll-sync'
import update from 'immutability-helper'
import { Row, Col, Button } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { useStopwatch } from 'react-timer-hook'
import { TaskArtefact } from './task-artefact'
import { PlanTask, TaskArtefact as TaskArtefactType } from '../../types'
import { DragArtefact } from './task-artefact-dragger'
import { DropArtefact } from './task-artefacrt-dropper'
import instructImg from '../../assets/icon-rankz-instructionz.svg'
import { usePrevious } from '../use-previous'

const artefactPlaceHolders = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
export interface RankingTaskProps {
  task: PlanTask
  onSubmitRank: (rankedTask: PlanTask) => void
}
export const RankingTask: FC<RankingTaskProps> = ({
  task,
  onSubmitRank
}): JSX.Element => {
  const [
    selectedArtefactPlaceHolder,
    setSelectedArtefactPlaceHolder
  ] = useState<string | undefined>(undefined)

  const [isArtefactExpanded, setIsArtefactExpanded] = useState(false)
  const [artefactTimes, setArtefactTimes] = useState<{ [key: string]: number }>(
    {}
  )
  const [rankedArtefacts, setRankedArtefacts] = useState<
    { id: string; text: string | undefined }[]
  >([])
  const [backup, setBackup] = useState<
    { id: string; text: string | undefined }[]
  >([])
  const { seconds, minutes, hours, days, start, pause, reset } = useStopwatch({
    autoStart: false
  })
  const orderArtefacts = useMemo(() => {
    const allArtefacts = artefactPlaceHolders.slice(0, task.artefacts.length)

    const shuffled = shuffle(task.artefacts)
    const mapped = shuffled.reduce<{ [key: string]: TaskArtefactType }>(
      (result, item, index) => {
        const key = allArtefacts[index]
        result[key] = item
        return result
      },
      {}
    )
    return mapped
  }, [artefactPlaceHolders, task])

  const aKey = useMemo(() => {
    return selectedArtefactPlaceHolder
      ? orderArtefacts[selectedArtefactPlaceHolder].artefactKey
      : undefined
  }, [selectedArtefactPlaceHolder, orderArtefacts])
  useEffect(() => {
    reset()
    setArtefactTimes({})
    setRankedArtefacts(
      [...new Array(task.artefacts.length).keys()].map((x, index) => {
        return {
          id: (index + 1).toString(),
          text: undefined
        }
      })
    )
  }, [task])

  const findItem = (id: string) => {
    const card = rankedArtefacts.filter(c => `${c.id}` === id)[0]
    return {
      card,
      index: rankedArtefacts.indexOf(card)
    }
  }
  const originalIndex = (id: string) => {
    const card = backup.filter(c => `${c.id}` === id)[0]
    return backup.indexOf(card)
  }
  const findByText = (text: string) => {
    const card = rankedArtefacts.filter(c => `${c.text}` === text)[0]
    return {
      card,
      index: rankedArtefacts.indexOf(card)
    }
  }

  const allRanked = useMemo(() => {
    return rankedArtefacts.filter(x => x.text === undefined).length === 0
  }, [rankedArtefacts])
  const moveItem = (id: string, text: string, atIndex: number) => {
    const { card, index } = findItem(id)
    if (index >= 0) {
      if (!backup[atIndex].text) {
        const oi = originalIndex(id)
        setRankedArtefacts(
          update(backup, {
            $splice:
              oi !== atIndex
                ? [
                    [atIndex, 1, card.text ? card : { ...card, text }],
                    [oi, 1, backup[atIndex]]
                  ]
                : [[atIndex, 1, card.text ? card : { ...card, text }]]
          })
        )
      } else {
        const updated = update(rankedArtefacts, {
          $splice: [
            [index, 1],
            [atIndex, 0, card.text ? card : { ...card, text }]
          ]
        })
        setRankedArtefacts(updated)
      }
    }
  }
  const onBegin = () => {
    setBackup([...rankedArtefacts])
  }

  const choose = () => {
    pause()

    // add last one on
    const finalCounts = { ...artefactTimes }
    if (aKey) {
      if (finalCounts[aKey]) {
        finalCounts[aKey] +=
          days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds
      } else {
        finalCounts[aKey] =
          days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds
      }
    }

    const ranked = rankedArtefacts
      .filter(x => x.text !== undefined)
      .map((x, i) => {
        return {
          ...orderArtefacts[x.text!],
          initialOrder: x.text!,
          rankOrder: i + 1,
          timeOnArtefact: finalCounts[orderArtefacts[x.text!].artefactKey] || 0
        }
      })

    const cloned: PlanTask = {
      ...task,
      artefacts: ranked,
      taskTime:
        keys(finalCounts).length > 0
          ? values(finalCounts).reduce((a, b) => a + b, 0)
          : 0
    }

    onSubmitRank(cloned)
  }
  const previousSelected = usePrevious(aKey)

  useEffect(() => {
    if (aKey !== previousSelected && previousSelected !== undefined) {
      pause()
      const toUpdate = { ...artefactTimes }
      if (toUpdate[previousSelected]) {
        toUpdate[previousSelected] +=
          days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds
      } else {
        toUpdate[previousSelected] =
          days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds
      }
      setArtefactTimes(toUpdate)
    }
  }, [aKey, previousSelected])
  const onEnd = (didDrop: boolean) => {
    if (!didDrop) {
      setRankedArtefacts([...backup])
    }
  }
  return (
    <div className="bigc d-flex flex-fill overflow-hidden">
      <DndProvider backend={HTML5Backend}>
        <Row className="mx-0 flex-fill align-items-center mw-100 justify-content-center">
          {!isArtefactExpanded && (
            <Col className="py-4 pr-xl-45 mh-100 d-flex flex-column" xs="auto">
              <div
                style={{ minWidth: '175px' }}
                className="font-smaller font-weight-bold bg-light text-center text-uppercase rounded-top p-3"
              >
                Unranked artefacts
              </div>
              <div className="p-0 custom-scroll  bg-white overflow-auto rounded-bottom">
                {keys(orderArtefacts).map((x, i) => (
                  <Row key={x} className="border-bottom mx-0">
                    <Col className="text-center py-3 ">
                      {!findByText(x).card && (
                        <div onClick={() => setSelectedArtefactPlaceHolder(x)}>
                          <DragArtefact
                            id={`${i + 1}`}
                            text={x}
                            isSelected={selectedArtefactPlaceHolder === x}
                            className="mx-auto"
                            onBegin={onBegin}
                            onEnd={onEnd}
                          />
                        </div>
                      )}
                      {findByText(x).card && (
                        <div
                          style={{ width: '44px', height: '44px' }}
                          className="mx-auto d-flex align-items-center justify-content-center border font-weight-bold rounded text-secondary"
                        />
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
              <Button style={{ opacity: 0 }} disabled className="mt-4">
                Hidden
              </Button>
            </Col>
          )}
          <Col
            xs={isArtefactExpanded ? 24 : 8}
            md={isArtefactExpanded ? 24 : 10}
            lg={isArtefactExpanded ? 24 : 14}
            className="h-100 mh-100 d-flex flex-column text-center"
          >
            {aKey && (
              <ScrollSync>
                <TaskArtefact
                  artefactKey={aKey}
                  choosePending={false}
                  retrievePending={false}
                  isExpanded={isArtefactExpanded}
                  onExpandChange={newVal => {
                    setIsArtefactExpanded(newVal)
                  }}
                  onLoaded={() => {
                    reset()
                    start()
                  }}
                />
              </ScrollSync>
            )}
            {!aKey && (
              <div className="flex-fill bg-white d-flex justify-content-center align-items-center p-45">
                <div>
                  <div className="my-45">
                    <img
                      style={{ maxWidth: '237px', width: '90%' }}
                      src={instructImg}
                      alt="Ranking instructions"
                      className="mb-4"
                    />
                    <div>
                      {' '}
                      Please drag the unranked artefacts to their ranking
                      position on the right
                    </div>
                  </div>

                  <div className="my-45 font-weight-bold">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    You can start by clicking any of the unranked artefacts
                    icons on the left to view them
                  </div>
                </div>
              </div>
            )}
          </Col>
          {!isArtefactExpanded && (
            <Col xs="auto" className="py-4 pl-xl-45 mh-100 d-flex flex-column">
              <div
                style={{ minWidth: '175px' }}
                className="font-smaller font-weight-bold bg-primary text-center text-white text-uppercase rounded-top p-3"
              >
                Ranked artefacts
              </div>
              <div className="p-0 custom-scroll overflow-auto bg-white rounded-bottom">
                {rankedArtefacts.map((x, i) => (
                  <DropArtefact
                    key={x.id}
                    moveItem={moveItem}
                    findItem={findItem}
                    isSelected={selectedArtefactPlaceHolder === x.text}
                    id={x.id}
                    onBegin={onBegin}
                    onEnd={onEnd}
                    itemSelect={itemText => {
                      setSelectedArtefactPlaceHolder(itemText)
                    }}
                    text={x.text}
                    index={i}
                  />
                ))}
              </div>
              <Button
                disabled={!allRanked}
                color="primary"
                className="mt-4"
                onClick={() => choose()}
              >
                Submit
              </Button>
            </Col>
          )}
        </Row>
      </DndProvider>
    </div>
  )
}
