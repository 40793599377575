import React, { useState, useMemo } from 'react'

import { differenceWith, isEqual } from 'lodash'
import { FixedSizeList as List } from 'react-window'
import memoize from 'memoize-one'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ArtefactViewer } from './artefact-viewer'
import { ArtefactView } from '../types'
import { MemoRow } from './shared/draft-artefact-row/draft-artefact-row-memo'

export interface ArtefactLProps {
  projectId: string
  data: ArtefactView[]
  onDeleteStart: (key: string) => void
  onDeleteComplete: (key: string) => void
  onDeleteError: (key: string) => void
  expected?: string[]
  showRM: boolean
}

const createItemData = memoize(
  (
    items: ArtefactView[],
    showRM: boolean,
    expectedLessActual: string[],
    projectId: string,
    onDeleteStart: (key: string) => void,
    onDeleteError: (key: string) => void,
    onDeleteComplete: (key: string) => void,
    showItem: (key: string) => void
  ) => ({
    items,
    showRM,
    expectedLessActual,
    projectId,
    onDeleteStart,
    onDeleteError,
    onDeleteComplete,
    showItem
  })
)

export const ArtefactLoader: React.FC<ArtefactLProps> = ({
  data,
  projectId,
  onDeleteStart,
  onDeleteComplete,
  showRM,
  onDeleteError,
  expected
}): JSX.Element => {
  // local state
  const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined)
  const [showViewer, setShowViewer] = useState(false)

  const showItem = (key: string) => {
    setSelectedKey(key)
    setShowViewer(true)
  }

  const toggle = () => {
    setShowViewer(!showViewer)
  }

  const expectedLessActual = useMemo(() => {
    if (!expected) {
      return []
    }
    const res = differenceWith(
      expected,
      data,
      (x, y) => x.toLowerCase() === y.name.toLowerCase()
    )
    return res
  }, [expected, data])
  const itemData = createItemData(
    // testData,
    data,
    showRM,
    expectedLessActual,
    projectId,
    onDeleteStart,
    onDeleteError,
    onDeleteComplete,
    showItem
  )
  return (
    <>
      <div
        style={{
          minHeight: 'max(calc(100vh - 650px), 325px)'
          // minHeight: `${Math.min(325, testData.length *64)}px`
        }}
        className="w-100 "
      >
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                className="custom-scroll"
                // height={Math.min(testData.length * 64)}
                height={height}
                // itemCount={testData.length}
                itemCount={(data ? data.length : 0) + expectedLessActual.length}
                itemData={itemData}
                itemSize={64}
                width={width || 0}
              >
                {MemoRow}
              </List>
            )
          }}
        </AutoSizer>
      </div>

      {selectedKey && (
        <ArtefactViewer
          showAllPages
          artefactName={''}
          artefactKey={selectedKey}
          isOpen={showViewer}
          toggle={toggle}
        />
      )}
    </>
  )
}
