import React, { FC } from 'react'
import { useDrag, DragSourceMonitor } from 'react-dnd'

interface DragProps {
  text: string
  id: string
  isSelected: boolean
  onBegin: () => void
  onEnd: (didDrop: boolean) => void

  className?: string
}
/**
 * Your Component
 */
export const DragArtefact: FC<DragProps> = ({
  text,
  className,
  id,
  onBegin,
  isSelected,
  onEnd
}) => {
  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'ARTEFACT', text, id },
    begin: () => onBegin(),

    end: (
      dropResult: DragSourceMonitor | undefined,
      monitor: DragSourceMonitor
    ) => onEnd(monitor.didDrop()),
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })
  return (
    <div
      data-testid={`drag-item-${text}`}
      style={{ width: '44px', height: '44px' }}
      ref={dragRef}
      className={` draggable ${isDragging ? 'bg-white border' : ''} ${
        !isDragging && isSelected
          ? 'bg-primary text-white'
          : 'bg-ddd text-secondary'
      } ${className} d-flex align-items-center justify-content-center  font-weight-bold rounded `}
    >
      {!isDragging && <>{text}</>}
    </div>
  )
}
