import React, { FunctionComponent } from 'react'

import { Button, Col, Row, Container } from 'reactstrap'
import { Redirect } from 'react-router'
import { useAuth0 } from '../Auth/auth'
import caToolLogo from '../assets/icon-ca-cjtool.svg'

export const HomePage: FunctionComponent = (): JSX.Element => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()
  if (isAuthenticated) {
    return (
      <>
        {user && user.roles.includes('CJ Admin') && (
          <Redirect to="/projects?DRAFT" />
        )}
        {user &&
          !user.roles.includes('CJ Admin') &&
          user.roles.includes('CJ Judge') && <Redirect to="/tasks" />}
      </>
    )
  }

  return (
    <Container className="mt-5">
      <Row className="align-items-center my-5">
        <Col
          xs={24}
          md={{ size: 6, offset: 1, order: 2 }}
          className="mb-5 text-center"
        >
          <img
            src={caToolLogo}
            alt="Cambridge Assessment Comparative Judgement Tool"
          />
        </Col>
        <Col xs={24} md={{ size: 17, order: 1 }}>
          <div className="bg-light rounded p-5">
            <h4 className="mb-3 font-weight-bold">Welcome</h4>
            <p className="mb-4">
              Comparative judgement (CJ) has a long history in psychology and
              market research as a technique for creating an overall ranking out
              of relative comparisons among different objects.
            </p>
            <p className="mb-4">
              Rather than assigning an absolute value, such as a mark out of 15
              to an essay, the markers/raters (usually known as ‘judges’ in CJ)
              simply have to put 2 or more artefacts into a rank order in
              relation to some basis for comparison.
            </p>
            <p className="mb-4">
              The basis for comparison can be something quite simple, such as
              ‘which handwriting is neater?’, or something more complex, such as
              ‘which exam script demonstrates more of the knowledge, skills and
              understanding as described in the A level History syllabus?’.
            </p>
            <p>
              The final rank order is based on the combination of lots of these
              comparisons across all the objects and judges involved.
            </p>
          </div>
          <Button
            className="mt-5 ml-5"
            color="primary"
            onClick={() => {
              loginWithRedirect({
                // eslint-disable-next-line @typescript-eslint/camelcase
                redirect_uri: window.location.origin
              })
            }}
          >
            Go to Log in
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
