import React, { useState, useMemo, useEffect } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'

import { Link } from 'react-router-dom'
import { Button, Container } from 'reactstrap'
import useReactRouter from 'use-react-router'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import {
  faCheck,
  faLock,
  faPortalExit,
  faSignOut
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TaskHeader } from './tasks/task-header'

import { useGetBearerToken } from './use-get-bearer-token'
import { useConfig } from '../use-remote-config'
import { PathParam, PlanTask, Project } from '../types'
import { Loading } from './loading'
import { SimpleMessage } from './shared/simple-message/simple-message'
import { SimpleErrorMessage } from './shared/simple-message/simple-error-message'
import { RankingTask } from './tasks/ranking-task'
import { PairwiseTask } from './tasks/pairwise-task'
import { CJErrorModal } from './modals/simple-error-modal'
import { isTaskPausedError } from '../helpers/axios-error-helpers'
import { CJModal } from './shared/modal/modal'
import { CJModalBody } from './shared/modal/subcomponents/modal-body'
import { SimpleModalMessage } from './shared/modal/subcomponents/modal-message'
import { CJModalHeader } from './shared/modal/subcomponents/modal-header'
import { CJModalFooter } from './shared/modal/subcomponents/modal-footer'
export const TaskView = (): JSX.Element => {
  const bearerToken = useGetBearerToken()
  const { config } = useConfig()
  const { match, history } = useReactRouter<PathParam>()

  const [tasksCompletedThisSession, setTasksCompletedThisSession] = useState<
    string[]
  >([])

  const getTasksMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${match.params.id}/tasks`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, match.params.id, bearerToken])
  const getTasksTask = useAsyncTaskAxios<AxiosResponse<PlanTask[]>>(
    axios,
    getTasksMemo
  )

  const remainingIncompleteTasks = useMemo(() => {
    if (getTasksTask.result) {
      const incompletes = getTasksTask.result.data.filter(
        x =>
          x.status === 'INCOMPLETE' && !tasksCompletedThisSession.includes(x.id)
      )
      return incompletes
    }
    return undefined
  }, [getTasksTask.result, tasksCompletedThisSession])

  const getNextTaskMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/tasks/${
        remainingIncompleteTasks && remainingIncompleteTasks.length > 0
          ? remainingIncompleteTasks[0].id
          : ''
      }`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, match.params.id, bearerToken, remainingIncompleteTasks])
  const getNextTaskTask = useAsyncTaskAxios<AxiosResponse<PlanTask>>(
    axios,
    getNextTaskMemo
  )

  const getUrl = `${config.apiUrl}/projects/${match.params.id}?role=JUDGE`
  const getProjectMemo = useMemo(() => {
    return {
      url: getUrl,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])

  const getProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    getProjectMemo
  )
  useAsyncRun(bearerToken && getProjectTask)
  useAsyncRun(bearerToken && getTasksTask)

  useEffect(() => {
    if (
      getTasksTask.result &&
      remainingIncompleteTasks &&
      remainingIncompleteTasks.length > 0
    ) {
      getNextTaskTask.start()
    }
  }, [getTasksTask.result])

  const updateTaskMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/tasks/${
        getNextTaskTask.result ? getNextTaskTask.result.data.id : 'broke'
      }`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getNextTaskTask.result, bearerToken, config.apiUrl])

  const updateTaskTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    updateTaskMemo
  )
  const isTaskPaused = useMemo(() => {
    if (!updateTaskTask.error) {
      return false
    } else {
      const errorResponse = (updateTaskTask.error as AxiosError<string>)
        .response
      if (errorResponse) {
        return isTaskPausedError(errorResponse)
      }

      return false
    }
  }, [updateTaskTask.error])
  const submitUpdatedTask = (updatedTask: PlanTask) => {
    updateTaskTask.start({ data: updatedTask })
  }
  useEffect(() => {
    if (
      updateTaskTask.result &&
      remainingIncompleteTasks &&
      remainingIncompleteTasks.length > 0
    ) {
      setTasksCompletedThisSession([
        ...tasksCompletedThisSession,
        remainingIncompleteTasks[0].id
      ])
    }
  }, [updateTaskTask.result])

  useEffect(() => {
    if (remainingIncompleteTasks && remainingIncompleteTasks.length > 0) {
      getNextTaskTask.start()
    }
  }, [remainingIncompleteTasks])
  const [updateErrorCleared, setUpdateErrorCleared] = useState(false)
  useEffect(() => {
    if (updateTaskTask.error) {
      setUpdateErrorCleared(false)
    }
  }, [updateTaskTask.error])
  return (
    <>
      {getProjectTask.pending && <Loading className="mt-5 d-block mx-auto" />}
      {!getProjectTask.pending && (
        <div className="bg-lightButNotTooLight h-100 d-flex flex-column ">
          <TaskHeader
            questionAndProgress={
              getProjectTask.result
                ? {
                    basis: getProjectTask.result.data.basis,
                    completedTasks: Math.min(
                      (getProjectTask.result.data.completedTasks || 0) +
                        tasksCompletedThisSession.length,
                      getProjectTask.result.data.totalTasks || 0
                    ),
                    totalTasks: getProjectTask.result.data.totalTasks || 0
                  }
                : undefined
            }
          />

          {remainingIncompleteTasks && remainingIncompleteTasks.length === 0 && (
            <Container className="bg-lightButNotTooLight py-5 ">
              <SimpleMessage
                className="mb-5"
                title="All tasks completed"
                message="Please go back to your task list, to complete any other projects"
                icon={<FontAwesomeIcon icon={faCheck} color="primary" />}
              >
                <Button color="primary" className="mt-4" tag={Link} to="/">
                  Back to Tasks
                </Button>
              </SimpleMessage>
            </Container>
          )}
          {(getNextTaskTask.error !== null || getProjectTask.error) && (
            <Container className="py-6">
              <SimpleErrorMessage
                className="mb-5"
                title="Failed to get task"
                message="Please refresh the page, and if the problem persists contact your system administrator."
                allowPageRefresh
              />
            </Container>
          )}
          {getProjectTask.result && (
            <>
              {getNextTaskTask.started && getNextTaskTask.pending && (
                <Loading className="mt-5 d-block mx-auto" />
              )}
              {!(
                remainingIncompleteTasks &&
                remainingIncompleteTasks.length === 0
              ) &&
                getNextTaskTask.result &&
                getNextTaskTask.result.data.artefacts.length > 2 && (
                  <RankingTask
                    onSubmitRank={submitUpdatedTask}
                    task={getNextTaskTask.result.data}
                  />
                )}
              {!(
                remainingIncompleteTasks &&
                remainingIncompleteTasks.length === 0
              ) &&
                getNextTaskTask.result &&
                getNextTaskTask.result.data.artefacts.length === 2 && (
                  <PairwiseTask
                    updatePending={
                      updateTaskTask.started && updateTaskTask.pending
                    }
                    task={getNextTaskTask.result.data}
                    onChoose={submitUpdatedTask}
                  />
                )}
              {isTaskPaused && (
                <CJModal
                  centered
                  isOpen={updateTaskTask.error !== null && isTaskPaused}
                >
                  <CJModalHeader className="bg-white" />
                  <CJModalBody className="text-center">
                    <div>
                      <FontAwesomeIcon
                        icon={faLock}
                        size="2x"
                        className="mb-3 text-primary"
                      />
                    </div>
                    <div className="font-weight-bold mb-4">
                      {'This task has been paused by your administrator'}
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faSignOut} className="mr-2" />{' '}
                      <Link className="font-weight-bold" to="/tasks">
                        Leave this task
                      </Link>
                    </div>
                  </CJModalBody>
                  <CJModalFooter />
                </CJModal>
              )}
              {updateTaskTask.error && !isTaskPaused && (
                <CJErrorModal
                  isOpen={updateTaskTask.error !== null && !updateErrorCleared}
                  onDismiss={() => setUpdateErrorCleared(true)}
                  title="Failed to complete task"
                  message="Task cannot be completed, please try again and if the problem persists contact us"
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}
