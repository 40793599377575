import { useCallback, useReducer, useState, useMemo, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import { useConfig } from '../use-remote-config'

import { useGetBearerToken } from './use-get-bearer-token'
import { JudgeUploaderState, State, Judge, JudgeAllocation } from '../types'

import { UserSelectView } from './user-lookup'
import {
  uploadingJudgesReducer,
  ADD_ACTION,
  COMPLETE_ACTION,
  ERROR_ACTION
} from '../reducers/uploading-judge-reducer'

import { useProjectResourceManager } from './use-project-resource-manager'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { REFRESH_ACTION } from '../reducers/project-resource-reducer'

export interface JudgeState {
  allJudges: Judge[]
  nonPlaceholders: Judge[]
  placeholders: Judge[]
  state: JudgeUploaderState
  resourceManager: State<Judge>
  gettingJudges: boolean
  addingPlaceholder: boolean
  addPlaceHolderError: Error | null
  allocationsChanged: boolean
  uploadJudges: (users: UserSelectView[]) => Promise<void>
  addPlaceHolder: () => void
  updateJudgePauseState: (judgeId: string, isPaused: boolean) => void
  replaceJudges: (updatedJudges: Judge[]) => void
}

export const useJudgeManager = (
  projectId: string,
  getJudgeDependencies: any[]
): JudgeState => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const [lastUpload, setLastUpload] = useState(new Date())

  const addPlaceholderMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${projectId}/judgePlaceholders`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, bearerToken, projectId])

  const addPlaceholderTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    addPlaceholderMemo
  )
  useEffect(() => {
    setLastUpload(new Date())
  }, [addPlaceholderTask.result])
  const resourceState = useProjectResourceManager<Judge>(
    projectId,
    'judges',
    (item: Judge) => item.user_id,
    [...getJudgeDependencies, lastUpload]
  )
  const { data, retrievingData, getItem, replaceItem, refresh } = resourceState
  const initialState: JudgeUploaderState = {
    loading: false
  }
  const [uploadState, uploadDispatch] = useReducer(
    uploadingJudgesReducer,
    initialState
  )
  const nonPlaceholders = useMemo(() => {
    return data.filter(x => !x.isPlaceholder)
  }, [data])
  const placeholders = useMemo(() => {
    return data.filter(x => x.isPlaceholder)
  }, [data])
  const allocationsChanged = useMemo(() => {
    return data.findIndex(x => x.tasksReallocated) > -1
  }, [data])

  const updateJudgePauseState = (judgeId: string, isPaused: boolean) => {
    const item = getItem(judgeId)
    if (item) {
      replaceItem({ ...item, isPaused })
    }
  }
  const replaceJudges = (updatedJudges: Judge[]): void => {
    setLastUpload(new Date())
  }

  const onUploadJudges = useCallback(
    async (users: UserSelectView[]): Promise<void> => {
      // Do something with the files
      const newAccessToken = bearerToken
      uploadDispatch({
        type: ADD_ACTION
      })

      try {
        const promises = users.map(x => {
          return axios.put<AxiosResponse<void>>(
            `${config.apiUrl}/projects/${projectId}/judges`,
            x.data
              ? { userId: x.data.user_id, email: x.data.email }
              : { email: x.value },
            {
              headers: {
                Authorization: `Bearer ${newAccessToken}`
              }
            }
          )
        })

        const placeholdersToRemove = Math.min(placeholders.length, users.length)

        const removePlaceholderPromises = placeholders
          .reverse()
          .slice(0, placeholdersToRemove)
          .map(x => {
            return axios.delete<AxiosResponse<void>>(
              `${config.apiUrl}/projects/${projectId}/judges/${x.user_id}`,
              {
                headers: {
                  Authorization: `Bearer ${newAccessToken}`
                }
              }
            )
          })

        await Promise.all(promises)
        await Promise.all(removePlaceholderPromises)
        uploadDispatch({ type: COMPLETE_ACTION })
        setLastUpload(new Date())
      } catch (error) {
        uploadDispatch({ type: ERROR_ACTION })
      }
    },
    [bearerToken, projectId, config.apiUrl, placeholders]
  )

  return {
    allJudges: data,
    nonPlaceholders,
    placeholders,
    resourceManager: resourceState,
    state: uploadState,
    addingPlaceholder: addPlaceholderTask.pending && addPlaceholderTask.started,
    addPlaceHolderError: addPlaceholderTask.error,
    addPlaceHolder: () => {
      addPlaceholderTask.start()
    },
    uploadJudges: onUploadJudges,
    gettingJudges: retrievingData,
    updateJudgePauseState,
    replaceJudges,
    allocationsChanged
  }
}
