import produce from 'immer'
import { JudgeUploaderState } from '../types'

export const ADD_ACTION = 'add'
export const PROGRESS_ACTION = 'progress'
export const COMPLETE_ACTION = 'complete'
export const ERROR_ACTION = 'error'

export interface UploadJudgeAction {
  type: string
}

export const uploadingJudgesReducer = (
  state: JudgeUploaderState,
  action: UploadJudgeAction
): JudgeUploaderState => {
  return produce(
    state,
    (draft): JudgeUploaderState => {
      switch (action.type) {
        case ADD_ACTION: {
          draft.loading = true
          draft.error = undefined
          return draft
        }
        case COMPLETE_ACTION: {
          draft.completedAt = new Date()
          draft.loading = false
          draft.error = undefined
          return draft
        }
        case ERROR_ACTION: {
          draft.error = 'Error'
          draft.loading = false
          return draft
        }
        default:
          return draft
      }
    }
  )
}
