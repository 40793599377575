import React, { useReducer, useMemo, useState, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import produce from 'immer'
import { Redirect } from 'react-router'
import useReactRouter from 'use-react-router'
import { Container } from 'reactstrap'
import { useAsyncTaskAxios } from 'react-hooks-async'
import {
  NameStepProps,
  BasisStepProps,
  MethodStepProps,
  DateStepProps,
  CategoryStepProps,
  ProjectData,
  PathParam,
  PlanTypeStepProps,
  UploadPlanStepProps,
  Project
} from '../types'
import { createProjectReducer } from '../reducers/project-reducer'
import { NameStep } from './wizard-steps/name-step'
import { BasisStep } from './wizard-steps/basis-step'
import { MethodStep } from './wizard-steps/method-step'
import { DateStep } from './wizard-steps/date-step'
import { CategoryStep } from './wizard-steps/category-step'
import { Loading } from './loading'
import { useConfig } from '../use-remote-config'
import { PlanTypeStep } from './wizard-steps/plan-type-step'
import { UploadPlanStep } from './wizard-steps/upload-plan-step'
import { CJErrorModal } from './modals/simple-error-modal'
import { useGetBearerToken } from './use-get-bearer-token'

const intitialState: ProjectData = {
  name: '',
  basis: '',
  packSize: undefined,
  planType: undefined,
  packsPerArtefact: 20,
  categories: []
}

export const NewProject = ({ inital = intitialState }): JSX.Element => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const { match, history } = useReactRouter<PathParam>()
  const [state, dispatch] = useReducer(createProjectReducer, inital)
  const [createErrorCleared, setCreateErrorCleared] = useState(false)
  const currentStep = useMemo(() => {
    const parsed = parseInt(match.params.id, 10)

    return Number.isNaN(parsed) ? 1 : parsed
  }, [match.params.id])

  const changeStep = (newStep: number) => {
    history.push(`/createProject/${newStep}`)
  }

  useEffect(() => {}, [])

  const handleChangeName = (event: NameStepProps): void => {
    const updated = produce(state, draft => {
      draft.name = event.name
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }
  const handleChangePlanType = (event: PlanTypeStepProps): void => {
    const updated = produce(state, draft => {
      draft.planType = event.planType
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }
  const handleChangeMethodStep = (event: MethodStepProps): void => {
    const updated = produce(state, draft => {
      draft.packSize = event.packSize
      draft.packsPerArtefact = event.artefactViewCount
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }

  const handleChangeUploadPlanStep = (event: UploadPlanStepProps): void => {
    const updated = produce(state, draft => {
      draft.categories = event.categories
      draft.packSize = event.packSize
      draft.packsPerArtefact = event.packsPerArtefact
      draft.tempPlanKey = event.tempPlanKey
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }

  const handleChangeDateStep = (event: DateStepProps): void => {
    const updated = produce(state, draft => {
      draft.endDate = event.endDate
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }

  const handleChangeCategoryStep = (event: CategoryStepProps): void => {
    const updated = produce(state, draft => {
      draft.categories = event.categories
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }

  const handleChangeBasis = (event: BasisStepProps): void => {
    const updated = produce(state, draft => {
      draft.basis = event.basis
      return draft
    })
    dispatch({ type: 'UPDATE_PROJECT', projectData: updated })
  }
  const createProjectMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [bearerToken, config.apiUrl])

  const createProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    createProjectMemo
  )
  useEffect(() => {
    if (createProjectTask.result) {
      history.push(`/projects/${createProjectTask.result.data.id}`)
    }
  }, [createProjectTask, history])

  if (createProjectTask.started && createProjectTask.pending) {
    return <Loading className="mt-5 d-block mx-auto" />
  }
  const totalSteps = state.planType === 'AUTO' ? 6 : 5
  return (
    <>
      <Container>
        {currentStep > 1 && state.name.length < 3 && (
          <Redirect to="/createProject/1" />
        )}
        {state.planType === 'AUTO' &&
          currentStep > 3 &&
          state.packSize === undefined && <Redirect to="/createProject/3" />}
        {state.planType === 'AUTO' &&
          currentStep > 4 &&
          state.basis.length < 3 && <Redirect to="/createProject/4" />}
        {state.planType === 'MANUAL' &&
          currentStep > 3 &&
          state.basis.length < 3 && <Redirect to="/createProject/3" />}
        <NameStep
          stepNumber={1}
          totalSteps={totalSteps}
          currentStep={currentStep}
          data={{ name: state.name }}
          goForward={() => changeStep(currentStep + 1)}
          onChange={event => handleChangeName(event)}
        />

        <PlanTypeStep
          stepNumber={2}
          totalSteps={totalSteps}
          currentStep={currentStep}
          data={{ planType: state.planType }}
          goBack={() => changeStep(currentStep - 1)}
          goForward={() => changeStep(currentStep + 1)}
          onChange={event => handleChangePlanType(event)}
        />
        {state.planType === 'AUTO' && (
          <>
            <MethodStep
              stepNumber={3}
              totalSteps={totalSteps}
              currentStep={currentStep}
              data={{
                packSize: state.packSize,
                artefactViewCount: state.packsPerArtefact
              }}
              goForward={() => changeStep(currentStep + 1)}
              goBack={() => changeStep(currentStep - 1)}
              onChange={event => {
                handleChangeMethodStep(event)
              }}
            />
            <CategoryStep
              stepNumber={5}
              totalSteps={totalSteps}
              currentStep={currentStep}
              data={{ categories: state.categories }}
              goForward={() => changeStep(currentStep + 1)}
              goBack={() => changeStep(currentStep - 1)}
              onChange={event => {
                handleChangeCategoryStep(event)
              }}
            />
          </>
        )}
        {state.planType === 'MANUAL' && (
          <UploadPlanStep
            stepNumber={5}
            totalSteps={totalSteps}
            currentStep={currentStep}
            data={{
              planType: undefined,
              tempPlanKey: undefined,
              categories: []
            }}
            goForward={() => {
              setCreateErrorCleared(false)
              createProjectTask.start({ data: state })
            }}
            goBack={() => changeStep(currentStep - 1)}
            onChange={event => {
              handleChangeUploadPlanStep(event)
            }}
          />
        )}
        <BasisStep
          stepNumber={state.planType === 'AUTO' ? 4 : 3}
          totalSteps={totalSteps}
          currentStep={currentStep}
          data={{ basis: state.basis }}
          goForward={() => changeStep(currentStep + 1)}
          goBack={() => changeStep(currentStep - 1)}
          onChange={event => {
            handleChangeBasis(event)
          }}
        />

        <DateStep
          stepNumber={state.planType === 'AUTO' ? 6 : 4}
          totalSteps={totalSteps}
          currentStep={currentStep}
          data={{ endDate: state.endDate }}
          goForward={() => {
            if (state.planType === 'AUTO') {
              setCreateErrorCleared(false)
              createProjectTask.start({ data: state })
            } else {
              changeStep(currentStep + 1)
            }
          }}
          goBack={() => changeStep(currentStep - 1)}
          onChange={event => {
            handleChangeDateStep(event)
          }}
        />
      </Container>
      <CJErrorModal
        isOpen={createProjectTask.error !== null && !createErrorCleared}
        onDismiss={() => setCreateErrorCleared(true)}
        title="Failed to create project"
        message="Please try again and if the problem persists contact your system administrator"
      />
    </>
  )
}
