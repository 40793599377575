import React, { useState, ChangeEvent } from 'react'

import { Button, Row, Col, Input, FormFeedback } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

export interface MultiTextInput {
  title: string
  icon?: JSX.Element
  values: string[]
  placeholder?: string
  inputRegex?: RegExp
  maxValues: number
  onChange?: (newValues: string[]) => void
}
// TODO : What should this control do if max elements is set and inital state contains more than the max
export const MultiTextInput: React.FC<MultiTextInput> = ({
  onChange,
  title,
  icon,
  placeholder,
  inputRegex,
  values,
  maxValues
}) => {
  const [inputValid, setInputValid] = useState(true)
  const [currentInputValue, setCurrentInputValue] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!inputValid) {
      const newValue = inputRegex ? inputRegex.test(event.target.value) : true
      if (newValue) {
        setValidationMessage('')
      }
      setInputValid(newValue)
    }
    setCurrentInputValue(event.target.value)
    // is this now faster
  }
  const handleRemoveClick = (value: string): void => {
    const index = values.findIndex(x => x === value)
    if (index >= 0 && onChange) {
      onChange([...values.slice(0, index), ...values.slice(index + 1)])
      setInputValid(true)
    }
  }

  const handleAddClick = (): void => {
    let newVM = ''
    if (inputRegex && !inputRegex.test(currentInputValue)) {
      newVM = 'Value not valid'
    }

    if (values.includes(currentInputValue)) {
      newVM = 'Values must be unique'
    }
    setValidationMessage(newVM)
    const isValid =
      currentInputValue.trim().length >= 3 &&
      (!inputRegex || inputRegex.test(currentInputValue)) &&
      !values.includes(currentInputValue) &&
      values.length < maxValues
    setInputValid(isValid)
    if (isValid) {
      const updated = [...values, currentInputValue]

      if (onChange) {
        onChange(updated)
      }
      setCurrentInputValue('')
    }
  }
  return (
    <>
      <Row
        className={`${
          values.length > 0 ? 'border-bottom' : ''
        } rounded-top border-top border-left border-right bg-light px-3 py-35 mx-0  text-primary font-weight-bold`}
      >
        <Col>
          {icon !== undefined && icon}
          {title}
        </Col>
      </Row>
      {values.map((x, i) => (
        <Row
          key={x}
          className={` ${
            i === maxValues - 1 ? 'border-bottom rounded-bottom' : ''
          } ${
            i !== 0 ? 'border-top' : ''
          } border-left border-right px-3 py-4 mx-0 align-items-center font-weight-bold`}
        >
          <Col>{x}</Col>
          <Col xs="auto" style={{ minWidth: '46px' }}>
            <Button
              className="pr-2"
              data-testid={`btn-close-${x.replace(/ /g, '')}`}
              close
              onClick={() => handleRemoveClick(x)}
            />
          </Col>
        </Row>
      ))}
      {values.length < maxValues && (
        <Row
          className={`${
            !inputValid ? 'bs-danger-inset' : ''
          } pl-0 px-3 py-1 mx-0 align-items-center row-input border-bottom rounded-bottom border-left border-right border-top`}
        >
          <Col>
            <Input
              autoFocus
              type="text"
              maxLength={50}
              name="multiValueInput"
              value={currentInputValue}
              onChange={event => handleInputChange(event)}
              pattern="[a-zA-Z0-9_-]{3,50}"
              onKeyPress={event => {
                if (event.key === 'Enter' || event.charCode === 13) {
                  event.preventDefault()
                  handleAddClick()
                }
              }}
              required
              placeholder={placeholder}
              className="border-0 py-35 px-0 font-weight-bold"
            />
          </Col>
          <Col xs="auto">
            <Button
              data-testid="btn-multi-input-add"
              style={{ width: '26px', height: '26px' }}
              color="primary"
              className="rounded-small p-0"
              id="link-create"
              onClick={() => handleAddClick()}
              size="sm"
            >
              <FontAwesomeIcon size="xs" icon={faPlus} />
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <FormFeedback
            className={`px-35 mt-2 ${
              !inputValid ? 'd-block' : ''
            } font-weight-bold text-danger`}
          >
            {validationMessage}
          </FormFeedback>
        </Col>
      </Row>
    </>
  )
}
