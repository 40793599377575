import React, { FC, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import {
  faUsers,
  faSearch,
  faExclamationTriangle,
  faRedoAlt
} from '@fortawesome/pro-solid-svg-icons'
import {
  Button,
  Spinner,
  ModalProps,
  Row,
  Col,
  UncontrolledTooltip,
  FormFeedback
} from 'reactstrap'
import { ValueType } from 'react-select/src/types'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'
import { useConfig } from '../../use-remote-config'
import { useGetBearerToken } from '../use-get-bearer-token'
import { User, AdminUser, Project } from '../../types'
import { getMethodText } from '../../helpers/project-type-helpers'
import {
  sanitizeId,
  nameRenderer,
  emailRenderer
} from '../../helpers/dom-helpers'
import { UserLookup, UserSelectView } from '../user-lookup'
import {
  useProjectResourceDeleter,
  useProjectResourceManager
} from '../use-project-resource-manager'
import { useAuth0 } from '../../Auth/auth'
import { SimpleModalMessage } from '../shared/modal/subcomponents/modal-message'
import { SimpleMessage } from '../shared/simple-message/simple-message'
import { SimpleErrorMessage } from '../shared/simple-message/simple-error-message'

interface ManageAdminModalProps {
  project: Project
  onRemoveCurrentUser: () => void
}

interface AdminRowProps {
  projectId: string
  index: number
  user: User
  onDeleteStart: () => void
  onDeleteComplete: () => void
  onDeleteError: () => void
}

export const AdminUserRow: FC<AdminRowProps> = ({
  projectId,
  index,
  user,
  onDeleteStart,
  onDeleteComplete,
  onDeleteError
}) => {
  const auth = useAuth0()
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const { deleteResource } = useProjectResourceDeleter(
    projectId,
    'admins',
    user.user_id,
    onDeleteStart,
    onDeleteComplete,
    onDeleteError
  )

  return (
    <Row
      className={`align-items-center  mx-0 p-3 ${
        index !== 0 ? 'border-top ' : ''
      }`}
      key={user.user_id}
    >
      <Col xs="auto" className="px-1">
        <img
          className="nav-user-profile"
          width="32"
          src={user.picture}
          alt="user avatar"
        />
      </Col>
      <Col className="text-left">
        {user.name && nameRenderer(user.name, user.user_id)}

        {user.name !== user.email &&
          user.email &&
          emailRenderer(user.email, user.user_id)}
      </Col>
      <Col xs="auto">
        {user.deleting && (
          <div className="font-weight-bold text-secondary">Deleting...</div>
        )}
        {user.deleteFailed && (
          <div className="text-danger font-weight-bold">
            <FontAwesomeIcon className="mr-25" icon={faExclamationTriangle} />
            <span>Delete Failed</span>
          </div>
        )}
        {!user.deleting && !user.deleteFailed && (
          <div className="bg-lightButNotTooLight font-weight-bold px-3 py-1 rounded font-smaller ">
            Admin
          </div>
        )}
      </Col>
      <Col xs="auto">
        {user.deleting && <Spinner color="secondary" size="sm" />}
        {!user.deleting && (
          <>
            <Button
              data-testid="btn-delete"
              close
              onClick={() => {
                if (auth.user && auth.user.user_id === user.user_id) {
                  setConfirmDeleteOpen(true)
                } else {
                  deleteResource()
                }
              }}
            />
            <CJModal
              centered
              isOpen={confirmDeleteOpen}
              toggle={() => setConfirmDeleteOpen(!confirmDeleteOpen)}
              size="sm"
            >
              <CJModalHeader className="bg-ddd">
                <FontAwesomeIcon
                  color="black"
                  icon={faExclamationTriangle}
                  size="lg"
                  className="my-2"
                />
              </CJModalHeader>
              <CJModalBody>
                <SimpleModalMessage
                  title="Removing yourself from the project"
                  message="If you remove yourself from this project you will not be able to view or make further changes to this project"
                />
              </CJModalBody>
              <CJModalFooter>
                <Button
                  color="ddd"
                  outline
                  onClick={() => {
                    setConfirmDeleteOpen(false)
                  }}
                >
                  Go back
                </Button>
                <Button
                  color="ddd"
                  onClick={() => {
                    deleteResource()
                  }}
                >
                  Do it anyway
                </Button>
              </CJModalFooter>
            </CJModal>
          </>
        )}
      </Col>
    </Row>
  )
}

export const ManageAdminModal: FC<ModalProps & ManageAdminModalProps> = ({
  isOpen,
  toggle,
  onRemoveCurrentUser,
  project
}) => {
  const { user } = useAuth0()
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const getUrl = `${config.apiUrl}/projects/${project.id}/admins`
  const addProjectAdminsMemo = useMemo(() => {
    return {
      url: getUrl,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])
  const addProjectAdminsTask = useAsyncTaskAxios<AxiosResponse<User>>(
    axios,
    addProjectAdminsMemo
  )
  const [tryAgainToggle, setTryAgainToggle] = useState(false)
  const resourceManager = useProjectResourceManager<AdminUser>(
    project.id,
    'admins',
    (item: AdminUser) => encodeURIComponent(item.user_id),
    [addProjectAdminsTask.result, tryAgainToggle],
    isOpen
  )

  const owner = useMemo(() => {
    if (resourceManager.data) {
      const owners = resourceManager.data.filter(x => x.isOwner)
      return owners.length > 0 ? owners[0] : undefined
    }
    return undefined
  }, [resourceManager.data])

  const admins = useMemo(() => {
    if (resourceManager.data) {
      return resourceManager.data.filter(x => !x.isOwner)
    }
    return undefined
  }, [resourceManager.data])
  return (
    <CJModal centered isOpen={isOpen}>
      <CJModalHeader className="bg-primary">
        <FontAwesomeIcon
          color="white"
          icon={faUsers}
          size="lg"
          className="my-2"
        />
      </CJModalHeader>
      <CJModalBody>
        <div className="font-weight-bold text-primary h5 mb-0">
          {project.name}
        </div>
        <div className="font-weight-bold font-larger mb-45">
          {getMethodText(project.packSize)}
        </div>

        {!resourceManager.retrievingData && resourceManager.getError !== null && (
          <SimpleErrorMessage title="Failed to get admin list ...">
            <button
              type="button"
              onClick={e => {
                e.preventDefault()
                setTryAgainToggle(!tryAgainToggle)
              }}
              className="link-button mb-5 text-secondary font-weight-bold"
            >
              <FontAwesomeIcon icon={faRedoAlt} className="mr-25" />
              <span>Try again</span>
            </button>
          </SimpleErrorMessage>
        )}
        {resourceManager.getError === null && (
          <>
            <div className="rounded bg-lightButNotTooLight mb-45">
              <Row className="align-items-center  mx-0 p-3">
                <Col xs="auto" className="px-1">
                  {owner && !resourceManager.retrievingData && (
                    <img
                      className="nav-user-profile"
                      width="32"
                      src={owner.picture}
                      alt="user avatar"
                    />
                  )}
                  {resourceManager.retrievingData && (
                    <Spinner size="sm" className="text-primary" />
                  )}
                </Col>
                <Col className="text-left">
                  {resourceManager.retrievingData && (
                    <span className="font-weight-bold">
                      Retrieving admins ...
                    </span>
                  )}
                  {owner &&
                    !resourceManager.retrievingData &&
                    nameRenderer(owner.name, owner.user_id)}

                  {owner &&
                    !resourceManager.retrievingData &&
                    owner.name !== owner.email &&
                    emailRenderer(owner.email, owner.user_id)}
                </Col>
                <Col xs="auto">
                  <div className="bg-primary text-white font-weight-bold px-3 py-1 rounded font-smaller ">
                    Owner
                  </div>
                </Col>
              </Row>
            </div>

            {admins && admins.length < 10 && (
              <div className="mb-35">
                <UserLookup
                  adminOnly
                  placeHolder="Search for admin users..."
                  value={[]}
                  existingToFilerOut={resourceManager.data}
                  onChange={(value: ValueType<UserSelectView>) => {
                    const newVal = value as UserSelectView

                    if (newVal) {
                      addProjectAdminsTask.start({
                        data: { userId: newVal.data.user_id }
                      })
                    }
                  }}
                />
                <FormFeedback
                  className={`ml-4 mt-3  px-45 ${
                    addProjectAdminsTask.error ? 'd-block' : ''
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-3"
                  />
                  Adding an admin failed, please try again
                </FormFeedback>
              </div>
            )}
            {admins && admins.length >= 10 && (
              <div className="font-weight-bold text-secondary mb-3">
                You have reached the maximum number of admins (10)
              </div>
            )}

            {!resourceManager.retrievingData &&
              !(addProjectAdminsTask.started && addProjectAdminsTask.pending) &&
              admins &&
              admins.length > 0 && (
                <div
                  style={{ maxHeight: '250px' }}
                  className={`custom-scroll  rounded border   ${
                    admins.length > 2 ? 'overflow-auto' : ''
                  } `}
                >
                  {admins.map((thisUser, index) => (
                    <AdminUserRow
                      key={thisUser.user_id}
                      projectId={project.id}
                      onDeleteStart={() =>
                        resourceManager.deleteStart(thisUser)
                      }
                      onDeleteComplete={() => {
                        resourceManager.deleteComplete(thisUser)
                        if (user && thisUser.user_id === user.user_id) {
                          if (toggle) {
                            toggle()
                          }
                          onRemoveCurrentUser()
                        }
                      }}
                      onDeleteError={() =>
                        resourceManager.deleteFailed(thisUser)
                      }
                      index={index}
                      user={thisUser}
                    />
                  ))}
                </div>
              )}
          </>
        )}
      </CJModalBody>
      <CJModalFooter>
        {toggle !== undefined && (
          <Button color="primary" onClick={toggle}>
            Finish
          </Button>
        )}
      </CJModalFooter>
    </CJModal>
  )
}
