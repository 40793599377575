import React from 'react'

import {
  Row,
  Col,
  FormGroup,
  Button,
  Progress,
  CardHeader,
  CardBody,
  Card,
  UncontrolledTooltip
} from 'reactstrap'
import { Link } from 'react-router-dom'
import CardFooter from 'reactstrap/lib/CardFooter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faTimes } from '@fortawesome/pro-solid-svg-icons'

import { StepProps, StepDefintionProps } from '../../types'

export const StepDefinition: React.FC<StepDefintionProps> = ({
  currentStep,
  totalSteps,
  stepNumber,
  stepTitle,
  children,
  goBack,
  goForward
}) => {
  return (
    <div className="bg-light step-main" style={{ flex: '1, 1, 30rem' }}>
      <Card className="wizard-step">
        <CardHeader>
          <Row>
            <Col className="font-weight-bold text-secondary">
              Setup a new project
            </Col>
            <Col xs="auto" className="text-right">
              <Link to="/" className="text-secondary">
                <span id="cancelButton">
                  <FontAwesomeIcon icon={faTimes} />
                  <span className="sr-only">Cancel</span>
                </span>
                <UncontrolledTooltip placement="top" target="cancelButton">
                  Cancel
                </UncontrolledTooltip>
              </Link>
            </Col>
          </Row>
        </CardHeader>
        <Progress
          value={currentStep}
          max={totalSteps + 1}
          className="mb-3 rounded-0 progress-slim-2"
        />
        <CardBody>
          <div className="h4 font-weight-bold">{`Step ${stepNumber}`}</div>
          <FormGroup>
            <div className="h5 mb-6">{stepTitle}</div>

            {children}
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Row>
            {stepNumber > 1 && goBack && (
              <Col>
                <Button
                  data-testid="back-button"
                  className="font-weight-bold font-smaller text-left pl-0"
                  color="link"
                  onClick={() => goBack()}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                  Back
                </Button>
              </Col>
            )}
            <Col className="text-right">
              <Button
                data-testid="btn-forward"
                color="primary"
                onClick={() => goForward()}
              >
                {stepNumber < totalSteps && 'Next'}
                {stepNumber === totalSteps && 'Finish'}
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  )
}

export const StepControls: React.FC = ({ children }) => {
  return <div className="step-tip">{children}</div>
}

// TODO : Consider refactoring out dependency on React Router, follow reactstrap model of passing in wrapping tag if you need it
export const Step: React.FC<StepProps> = ({
  stepNumber,
  currentStep,
  children
}) => {
  return (
    <>
      {currentStep === stepNumber && (
        <Row className=" justify-content-between">{children}</Row>
      )}
    </>
  )
}
