import React from 'react'
import { Spinner } from 'reactstrap'

interface TabButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  /** is this button active (in pressed state) */
  isActive?: boolean
  /** is this button awaiting some asynchronous action to complete */
  isPending?: boolean
  /** title to display on the button */
  title: string
}

export const TabButton: React.FC<TabButtonProps> = ({
  title,
  children,
  className,
  isActive = false,
  isPending = false,
  onClick
}): JSX.Element => {
  return (
    <button
      data-testid={`btn-${title}`}
      type="button"
      tabIndex={0}
      className={`${className} ${
        isActive ? 'bg-primary text-white' : 'bg-lightButNotTooLight'
      }  font-normal position-relative align-self-stretch btn w-100  h-100 rounded btn-nofocus text-center font-weight-normal py-35 px-4 font-weight-bold text-uppercase  `}
      onClick={event => {
        if (onClick) {
          onClick(event)
        }
      }}
    >
      <div className="mb-2 font-normal">{title}</div>
      {isPending && (
        <Spinner
          style={{ width: '1.5rem', height: '1.5rem' }}
          className="my-2 d-block mx-auto"
          role="progressbar"
          color={isActive ? 'white' : 'primary'}
        />
      )}
      {!isPending && <>{children}</>}
    </button>
  )
}
