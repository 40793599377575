import React, { forwardRef, useRef } from 'react'

import { Label } from 'reactstrap'
import { Step, StepDefinition } from './step'
import { WizardStepProps, NameStepProps, reg } from '../../types'
import { ValAndSumbit, CheckValid, ValidatedInput } from './validated-input'

export interface NameValAndSumbit extends ValAndSumbit {
  onChange?: (event: NameStepProps) => void
}

export const NameInput: React.FC<
  NameValAndSumbit & React.RefAttributes<CheckValid>
> = forwardRef(({ value, onSubmit, onChange, className }, ref) => {
  return (
    <ValidatedInput
      autoFocus
      ref={ref}
      data-testid="name-input"
      type="text"
      maxLength={30}
      name="projectName"
      id="projectName"
      onChange={event => {
        if (onChange) {
          onChange({ name: event.target.value })
        }
      }}
      rows={4}
      className={`${className} font-weight-bold`}
      onSubmit={onSubmit}
      validationMessage="Project name must be at least 3 alphanumeric characters long"
      checkIsValid={newVal => {
        return (
          typeof newVal === 'string' &&
          newVal.trim().length >= 3 &&
          reg.test(newVal)
        )
      }}
      placeholder="Jun17 IGCSE Biology RO Study"
      value={value}
    />
  )
})

export const NameStep: React.FC<WizardStepProps<NameStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  data,
  totalSteps,
  onChange
}) => {
  const childRef = useRef<CheckValid>(null)
  return (
    <Step
      stepNumber={stepNumber}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <StepDefinition
        stepNumber={stepNumber}
        stepTitle="Project's Name"
        totalSteps={totalSteps}
        currentStep={currentStep}
        goForward={() => {
          if (childRef && childRef.current) {
            const newValid = childRef.current.checkAndSetValid()
            if (newValid) {
              goForward()
            }
          }
        }}
      >
        <Label className="wizard-label font-weight-bold mb-2 font-larger">
          Please give your project a name
        </Label>
        <p className="text-secondary mb-45">
          Project name should be unique and clear to the Judges on the project.
          This will be the first thing they will encounter when they are invited
          to take part.
        </p>
        <NameInput
          ref={childRef}
          value={data.name}
          onSubmit={goForward}
          onChange={onChange}
        />
      </StepDefinition>
    </Step>
  )
}
