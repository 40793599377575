import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faEnvelopeOpenText,
  faSearch
} from '@fortawesome/pro-solid-svg-icons'
import AsyncCreatableSelect from 'react-select/async-creatable'
import axios from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise'

import { Col, Row } from 'reactstrap'

import { OptionProps } from 'react-select/src/components/Option'
import { MultiValueGenericProps } from 'react-select/src/components/MultiValue'

import { ValueType, ActionMeta } from 'react-select/src/types'
import { User } from '../types'
import { useAuth0 } from '../Auth/auth'
import { useConfig } from '../use-remote-config'
import { FaCircleEnvelope } from './shared/icons/fa-circle-envelope'

export interface UserLookupProps {
  disabled?: boolean
  onChange:
    | ((value: ValueType<UserSelectView>, action: ActionMeta) => void)
    | undefined
  value: UserSelectView[]
  existingToFilerOut: User[]
  placeHolder: string
  isMulti?: boolean
  judgesOnly?: boolean
  adminOnly?: boolean
  allowNewUserCreation?: boolean
}

export interface UserSelectView {
  value: string
  label: string
  data: User
}
// TODO use asynchook to create state and then use that possibly
export const UserLookup: React.FC<UserLookupProps> = ({
  disabled,
  onChange,
  placeHolder,
  isMulti,
  judgesOnly,
  adminOnly,
  value,
  allowNewUserCreation,
  existingToFilerOut
}): JSX.Element => {
  const { config } = useConfig()
  const { getTokenSilently } = useAuth0()

  const queryUsers = async (input: string): Promise<UserSelectView> => {
    const newAccessToken = await getTokenSilently()

    let url = `${config.apiUrl}/users?query=${input}`
    if (judgesOnly) {
      url += '&judgesOnly=true'
    }
    if (adminOnly) {
      url += '&adminOnly=true'
    }
    const result = await axios(url, {
      headers: {
        Authorization: `Bearer ${newAccessToken}`
      }
    })
    // TODO : is there a better way of doing this
    return result.data.map((x: User) => {
      return { value: x.user_id, label: x.email, data: x }
    })
  }
  const queryUsersDebounced = AwesomeDebouncePromise(queryUsers, 500)
  const Option: React.FC<OptionProps<UserSelectView>> = ({
    data,
    innerProps,
    innerRef,
    isFocused
  }) => {
    return (
      <div
        className={`d-flex  align-items-center p-2 border-bottom ${
          isFocused ? ' bg-light ' : ''
        }`}
        ref={innerRef}
        {...innerProps}
      >
        {data.data && (
          <>
            <Col xs="auto" className="p-2">
              {data.data && data.data.email_verified ? (
                <img
                  style={{ height: '32px' }}
                  src={data.data.picture}
                  alt="Profile"
                  className="nav-user-profile d-inline-block mr-2"
                />
              ) : (
                <FaCircleEnvelope size="2x" />
              )}
            </Col>
            <Col className="p-2 font-smaller text-left">
              <div className="font-weight-bold">
                {data.data.email_verified
                  ? data.data.name
                  : 'Pending account activation'}
              </div>
              <div>{data.data.email}</div>
            </Col>
          </>
        )}
        {!data.data && (
          <>
            <Col xs="auto" className="p-1">
              <FontAwesomeIcon
                style={{ opacity: 0.37 }}
                className="mx-2"
                icon={faEnvelope}
              />
            </Col>
            <Col className="p-1 font-smaller  text-left">
              <div>Invite</div>
              <div className="font-weight-bold">{data.value}</div>
            </Col>
          </>
        )}
      </div>
    )
  }

  const MultiValueLabel: React.FC<MultiValueGenericProps<UserSelectView>> = ({
    data,
    innerProps
  }) => {
    return (
      <div {...innerProps}>
        {data.data && data.data.email_verified ? (
          <img
            style={{ height: '18.5px' }}
            src={data.data.picture}
            alt="Profile"
            className="nav-user-profile d-inline-block mr-2"
          />
        ) : (
          <FaCircleEnvelope size="lg" />
        )}

        <div className="d-inline-block align-middle py-0 pl-1 pr-2 font-smaller font-weight-bold ">
          {data.data && <>{data.data.name}</>}
          {!data.data && <>{data.value}</>}
        </div>
      </div>
    )
  }

  const validateEmail = (email: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d\-A-Za-z]+\.)+[A-Za-z]{2,}))$/
    return re.test(email)
  }
  return (
    <div className="border rounded">
      <Row className="align-items-stretch  rounded mx-0">
        <Col
          xs="auto"
          style={{ minWidth: '64px' }}
          className="rounded-left border-right align-items-center justify-content-center d-flex text-center text-secondary px-3 bg-light"
        >
          <FontAwesomeIcon icon={faSearch} />
        </Col>
        <Col className="px-0">
          <div onClick={(e: any) => e.preventDefault()}>
            <AsyncCreatableSelect
              isMulti={isMulti}
              value={value}
              onChange={(x, y) => {
                if (onChange) {
                  onChange(x, y)
                }
              }}
              classNamePrefix="react-select"
              noOptionsMessage={() => null}
              cacheOptions
              allowCreateWhileLoading
              isClearable
              isSearchable
              isDisabled={disabled}
              autoFocus
              filterOption={(candidate, input) => {
                if (
                  existingToFilerOut
                    .map(x => x.user_id)
                    .includes(candidate.value)
                ) {
                  return false
                }
                return true
              }}
              placeholder={placeHolder}
              defaultOptions={[]}
              formatCreateLabel={inputValue => `invite ... ${inputValue}`}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                return (
                  allowNewUserCreation !== undefined &&
                  allowNewUserCreation === true &&
                  validateEmail(inputValue) &&
                  selectOptions.filter(
                    x => x.label.toLowerCase() === inputValue.toLowerCase()
                  ).length === 0 &&
                  existingToFilerOut.filter(
                    x => x.email.toLowerCase() === inputValue.toLowerCase()
                  ).length === 0
                )
              }}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#037fac',
                  neutral30: '#dee2e6'
                }
              })}
              styles={{
                control: base => ({
                  ...base,
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  borderTop: 'none',
                  borderBottom: 'none',
                  borderRadius: 0,
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  paddingTop: '0.25rem',
                  paddingBottom: '0.25rem'
                }),
                menu: base => ({
                  ...base,
                  maxWidth: '400px',
                  marginLeft: '24px'
                }),
                input: base => ({
                  ...base,
                  margin: '0.375rem 2px'
                }),
                multiValueLabel: () => ({
                  display: 'flex',
                  alignItems: 'center'
                }),
                multiValue: (base, { data }) => ({
                  ...base,
                  borderRadius: '8px',
                  backgroundColor: data.data ? '#eee' : 'white',
                  border: data.data ? '1px solid #eee' : '1px solid #dddddd',
                  display: 'flex',
                  padding: '6px 12px',
                  alignItems: 'center',
                  marginRight: '0.75rem',
                  marginTop: '0.375rem',
                  marginBottom: '0.375rem'
                })
              }}
              components={{
                // TODO : if you override the Component the focus stops working but would like the overide as means we can have the logo

                Option,
                MultiValueLabel,
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null
              }}
              loadOptions={queryUsersDebounced}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}
