import React, { FC } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScroll, faHandRock } from '@fortawesome/pro-solid-svg-icons'
import {
  faHandRock as faHandRockReg,
  faFile
} from '@fortawesome/pro-regular-svg-icons'

export const FaDragArtefact: FC<{ className?: string }> = ({
  className
}): JSX.Element => (
  <span className={`${className} fa-layers fa-2x fa-fw`}>
    <FontAwesomeIcon transform=" shrink-6 " icon={faScroll} />
    <FontAwesomeIcon
      inverse
      icon={faHandRock}
      transform=" shrink-8 left-4 down-5"
    />
    <FontAwesomeIcon icon={faHandRockReg} transform="shrink-8 left-4 down-5" />
  </span>
)
