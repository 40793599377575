import React, { FC, useMemo, useState } from 'react'
import { orderBy } from 'lodash'
import { Row, Col, Card, CardHeader } from 'reactstrap'
import CardBody from 'reactstrap/lib/CardBody'
import { Judge, JudgeAllocation } from '../../../types'
import { JudgeProgressRow } from './subcomponents/judge-progress-row'
import { ReallocateModal } from '../../modals/reallocate-modal/reallocate-modal'

interface JudgeProgressProps {
  judges: Judge[]
  projectId: string
  artefactsPerPack: number
  className?: string
  onJudgePauseChanged: (judgeId: string, isPaused: boolean) => void
  onReallocationSuccess: (updatedJudges: Judge[]) => void
}

export const JudgeProgress: FC<JudgeProgressProps> = ({
  judges,
  projectId,
  artefactsPerPack,
  onJudgePauseChanged,
  onReallocationSuccess,
  className
}): JSX.Element => {
  const orderedJudges = useMemo(() => {
    return orderBy(judges, x => x.assignedJudgePlace)
  }, [judges])

  const [reallocateJudge, setReallocateJudge] = useState<Judge | null>(null)

  return (
    <>
      <Card className={`${className}`}>
        <CardHeader className="p-0">
          <Row className="d-none d-md-flex align-items-center  mx-0 py-25 px-25">
            <Col xs="auto" style={{ minWidth: '47px' }} />
            <Col xs="auto" style={{ minWidth: '40px' }} />
            <Col
              md={8}
              className="font-smaller font-weight-bold text-dark text-uppercase  "
            >
              Judge
            </Col>
            <Col
              style={{ flex: '0 0 100px' }}
              className="font-smaller font-weight-bold text-dark text-uppercase text-center "
            >
              {artefactsPerPack === 2 && 'Pairs'}
              {artefactsPerPack !== 2 && 'Packs'}
            </Col>
            <Col
              style={{ flex: '0 0 125px' }}
              className="font-smaller font-weight-bold text-dark text-uppercase text-center "
            >
              {`Avg Time /  ${artefactsPerPack === 2 ? 'Pair' : 'Pack'}`}
            </Col>
            <Col className="font-smaller font-weight-bold text-dark text-uppercase text-center ">
              Progress
            </Col>
            <Col className="d-none d-xl-flex" style={{ flex: '0 0 80px' }} />
            <Col style={{ flex: '0 0 110px' }} />
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          {orderedJudges.map((judge, index) => (
            <JudgeProgressRow
              key={judge.user_id}
              index={index}
              judge={judge}
              projectId={projectId}
              onPauseChanged={isPaused => {
                onJudgePauseChanged(judge.user_id, isPaused)
              }}
              onSelectReallocate={() => setReallocateJudge(judge)}
            />
          ))}
        </CardBody>
      </Card>
      {reallocateJudge && (
        <ReallocateModal
          onReallocationSuccess={onReallocationSuccess}
          isOpen={reallocateJudge !== undefined}
          toggle={() => setReallocateJudge(null)}
          projectId={projectId}
          judge={reallocateJudge}
          existingJudges={orderedJudges}
        />
      )}
    </>
  )
}
