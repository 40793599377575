import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faFileChartLine } from '@fortawesome/pro-solid-svg-icons'
import { Row, Col } from 'reactstrap'

import { format } from 'date-fns'

interface CompletedSummaryProps {
  completedDate: Date
  ssr?: number
}

export const SSR: FC<{ ssr: number }> = ({ ssr }): JSX.Element => {
  return (
    <div className="text-primary h4 mb-0 font-weight-bold ">
      <FontAwesomeIcon
        icon={faStar}
        className="mr-2 font-normal align-baseline"
      />
      {`SSR: ${ssr.toFixed(2)}`}
    </div>
  )
}

export const ReportTitle: FC<{ completedDate: Date }> = ({
  completedDate
}): JSX.Element => {
  return (
    <>
      <div className="h5 font-weight-bold">
        <FontAwesomeIcon icon={faFileChartLine} className="mr-2" />
        Project Report
      </div>
      <div className="font-larger text-secondary font-weight-bold">
        {'Completed on '}
        {format(completedDate, 'MMM dd, yyyy')}
      </div>
    </>
  )
}

export const CompletedSummary: FC<
  CompletedSummaryProps & React.HTMLAttributes<HTMLDivElement>
> = ({ ssr, completedDate, className }): JSX.Element => {
  return (
    <div className={`${className}`}>
      <Row className="align-items-end">
        <Col>
          <ReportTitle completedDate={completedDate} />
        </Col>
        {ssr && (
          <Col xs="auto" className="tex-right">
            <SSR ssr={ssr} />
          </Col>
        )}
      </Row>
    </div>
  )
}
