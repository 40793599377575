import React, { useMemo } from 'react'
import { Col, Row, Button } from 'reactstrap'
import { pickBy, keys, values, sum } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faFileDownload } from '@fortawesome/pro-solid-svg-icons'
import { PlanSummary } from '../../types'
import { PlanConnectivity } from './plan-connectivity'

import { PlanWidgetTip } from './plan-widget-tip'
import { PlanWidget } from './plan-widget'
import { useConfig } from '../../use-remote-config'
import { useDownloadFile } from '../use-download-file'
import { DownloadButton } from '../download-button'

interface ProjectAndPlan {
  planUploaded: boolean
  targetPacksPerArtefact?: number
  plan: PlanSummary
  projectId: string
  onRecreate?: () => void
}

export const ProjectPlan: React.FC<ProjectAndPlan> = ({
  plan,
  targetPacksPerArtefact,
  planUploaded,
  projectId,
  onRecreate
}): JSX.Element => {
  const { config } = useConfig()
  const planCSVUrl = `${config.apiUrl}/artefacts/${encodeURIComponent(
    `plans/${projectId}.csv`
  )}`
  const dl = useDownloadFile({
    fileUrl: planCSVUrl,
    fileName: `${projectId}.csv`
  })
  const binCounts = (
    inputObject: {
      [key: string]: number
    },
    maxItems: number
  ) => {
    const allKeys = keys(inputObject)

    if (allKeys.length < maxItems) {
      return inputObject
    }

    const firstXKeys = allKeys.slice(0, maxItems - 1)
    const restKeys = allKeys.slice(maxItems - 1, allKeys.length)
    const firstX = pickBy(inputObject, (value, key) => firstXKeys.includes(key))
    const rest = pickBy(inputObject, (value, key) => restKeys.includes(key))
    const total = sum(values(rest))
    firstX[`${restKeys[0]}+`] = total
    return firstX
  }

  const binPairCounts = useMemo(() => {
    return binCounts(plan.possiblePairFrequency, 5)
  }, [plan])
  const binSeenCounts = useMemo(() => {
    return binCounts(plan.artefactSeenFrequency, 5)
  }, [plan])

  const idealSeenCount = useMemo(() => {
    return plan.idealSeenCount
  }, [plan])
  const idealJudgePackCounts = useMemo(() => {
    const packsPerJudge = plan.totalTasks / plan.judgeCount

    return [`${Math.floor(packsPerJudge)}`, `${Math.ceil(packsPerJudge)}`]
  }, [plan])

  return (
    <div className="bg-light mb-5 rounded border">
      <Row className="p-42">
        <Col className="mb-2">
          <PlanConnectivity connected={plan.connected} />
        </Col>

        <Col xs="auto" className="text-right">
          {!planUploaded && onRecreate && (
            <Button
              className="mb-2 px-0"
              color="link"
              onClick={() => onRecreate()}
            >
              <span className="d-none d-md-inline-block">Re-create plan</span>
              <FontAwesomeIcon
                title="Click to re-create plan"
                icon={faRedoAlt}
                className="ml-2"
              />
            </Button>
          )}
          <DownloadButton
            downloadUrl={`${config.apiUrl}/artefacts/${encodeURIComponent(
              `plans/${projectId}.csv`
            )}`}
            downloadFileName={`${projectId}.csv`}
            spinnerColor="secondary"
            className="mb-2 ml-45 px-0"
            color="link"
            disabled={dl.started && dl.pending}
          >
            <span className="d-none d-md-inline-block">Export this plan</span>
            <FontAwesomeIcon
              title="Click to export this plan to csv"
              icon={faFileDownload}
              size="lg"
              className="ml-2"
            />
          </DownloadButton>
        </Col>
      </Row>

      {plan.packsPerJudge && (
        <Row>
          <Col xs={24} className="mb-2">
            <PlanWidget
              title="Number of Packs"
              subTitle="Number of Judges"
              accentKeys={idealJudgePackCounts}
              itemCounts={plan.packsPerJudge}
            />
          </Col>
          <Col xs={24}>
            <PlanWidgetTip
              idealTip="This shows the ideal number of packs each judge will receive."
              otherTip="Some judges will receive more or less."
            />
          </Col>
        </Row>
      )}
      {plan.judgementsPerArtefact && (
        <Row>
          <Col xs={24} className="mb-2">
            <PlanWidget
              title="Judgements per artefact"
              subTitle="Number of artefacts"
              accentKeys={
                targetPacksPerArtefact
                  ? [`${targetPacksPerArtefact * (plan.packSize - 1)}`]
                  : []
              }
              itemCounts={plan.judgementsPerArtefact}
            />
          </Col>
          <Col xs={24}>
            <PlanWidgetTip
              idealTip="This shows the chosen number of judgements per artefact."
              otherTip="Some artefacts will be judged more."
            />
          </Col>
        </Row>
      )}
      {plan.artefactSeenFrequency && (
        <Row>
          <Col xs={24} className="mb-2">
            <PlanWidget
              title="Number of times same artefact seen"
              subTitle="Frequency"
              accentKeys={[idealSeenCount.toString()]}
              itemCounts={binSeenCounts}
            />
          </Col>
          <Col xs={24}>
            <PlanWidgetTip
              idealTip="Ideally a judge will see each artefact as few a times as possible."
              otherTip="The minimum number will depend on the study."
            />
          </Col>
        </Row>
      )}
      {plan.possiblePairFrequency && (
        <Row>
          <Col xs={24} className="mb-2">
            <PlanWidget
              title="Packs containing each possible pair"
              subTitle="Frequency"
              accentKeys={['0', '1']}
              itemCounts={binPairCounts}
            />
          </Col>
          <Col xs={24}>
            <PlanWidgetTip
              idealTip="Ideally the same pair will appear in a pack once or less."
              otherTip="Sometimes more."
            />
          </Col>
        </Row>
      )}
    </div>
  )
}
