import React, { useState } from 'react'

import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'

export interface CountPickerProps {
  value?: number
  defaultValue?: number
  min: number
  max: number
  onChange?: (newValue: number) => void
}

export const CountPicker: React.FC<CountPickerProps> = ({
  onChange,
  value,
  defaultValue,
  min,
  max
}) => {
  const [uncontrolledVal, setUncontrolledVal] = useState<number>(
    defaultValue || min || 0
  )

  if (
    (value && (value > max || value < min)) ||
    (defaultValue && (defaultValue > max || defaultValue < min))
  ) {
    throw new Error('Value out of range')
  }

  const handlePlusClick = () => {
    if (value && onChange) {
      onChange(value + 1)
    } else {
      const newValue = uncontrolledVal + 1
      setUncontrolledVal(newValue)
      if (onChange) {
        onChange(newValue)
      }
    }
  }
  const handleMinusClick = () => {
    if (value && onChange) {
      onChange(value - 1)
    } else {
      const newValue = uncontrolledVal - 1
      setUncontrolledVal(newValue)
      if (onChange) {
        onChange(newValue)
      }
    }
  }
  return (
    <>
      <Button
        data-testid="decrement"
        outline
        color="ddd"
        disabled={value ? value === min : uncontrolledVal === min}
        onClick={() => handleMinusClick()}
        className="btn-circle font-smaller"
      >
        <FontAwesomeIcon size="xs" icon={faMinus} />
      </Button>
      <span
        style={{ minWidth: '25px' }}
        className="mx-3 font-weight-bold align-middle"
      >
        {value || uncontrolledVal}
      </span>
      <Button
        data-testid="increment"
        outline
        color="ddd"
        size="sm"
        disabled={value ? value === max : uncontrolledVal === max}
        onClick={() => handlePlusClick()}
        className="btn-circle font-smaller "
      >
        <FontAwesomeIcon size="xs" icon={faPlus} />
      </Button>
    </>
  )
}
