import React, { FC, useMemo } from 'react'
import { orderBy } from 'lodash'
import { Table, Progress, UncontrolledTooltip, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCaretDown,
  faCaretUp
} from '@fortawesome/pro-solid-svg-icons'
import { CellProps, useTable, useSortBy } from 'react-table'
import { Judge } from '../../types'
import { sanitizeId } from '../../helpers/dom-helpers'

interface JudgeProgressProps {
  judges: Judge[]
  artefactsPerPack: number
  className?: string
}

export const secondsToString = (totalSeconds: number) => {
  const numhours = Math.floor(totalSeconds / 3600)
  const numminutes = Math.floor(((totalSeconds % 86400) % 3600) / 60)
  let numseconds = ((totalSeconds % 86400) % 3600) % 60
  if (numminutes > 0 || numhours > 0 || numseconds > 10) {
    numseconds = Math.round(numseconds)
  }
  if (totalSeconds < 60) {
    return `${numseconds}s`
  }
  if (totalSeconds < 3600) {
    return `${numminutes}m ${numseconds}s`
  }

  return `${numhours}h ${numseconds > 30 ? numminutes + 1 : numminutes}m`
}

const getTimeCol = (header: string, accessor: string, cellClass?: string) => {
  return {
    Header: header,
    accessor,
    Cell: ({ cell }: CellProps<Judge>) => secondsToString(cell.value),
    headerProps: {
      className: `text-center text-uppercase`
    },
    cellProps: {
      className: `${cellClass} text-center font-larger`
    }
  }
}

export const CompletedJudgeView: FC<JudgeProgressProps> = ({
  judges,
  artefactsPerPack
}): JSX.Element => {
  const orderedJudges = useMemo(() => {
    return orderBy(
      judges.filter(x => x.totalTasks > 0),
      x => x.assignedJudgePlace
    )
  }, [judges])

  const columns = useMemo(() => {
    const firstCols = [
      {
        Header: '#',
        accessor: 'assignedJudgePlace',
        headerProps: {
          className: 'text-center text-uppercase'
        },
        cellProps: {
          className: 'text-center font-larger font-weight-bold'
        }
      },
      {
        Header: 'Judge',
        accessor: 'email',
        headerProps: {
          className: 'text-uppercase'
        },
        cellProps: {
          className: 'font-weight-bold'
        },
        Cell: ({ cell }: CellProps<Judge>) => {
          return (
            <span className="d-inline-block">
              <img
                className="nav-user-profile mr-25"
                width="32"
                src={cell.row.original.picture}
                alt="user avatar"
              />
              {cell.row.original.email}
            </span>
          )
        }
      },
      {
        Header: 'Done / Assigned',
        accessor: 'completedTasks',
        headerProps: {
          className: ' text-center text-uppercase'
        },
        cellProps: {
          className: 'h5 mb-0 text-center font-weight-bold'
        },
        Cell: ({ cell }: CellProps<Judge>) => {
          return (
            <>
              {cell.row.original.completedTasks ===
              cell.row.original.totalTasks ? (
                <span className="text-primary d-inline-block">
                  {cell.row.original.completedTasks}
                  <FontAwesomeIcon icon={faCheck} className="ml-2" />
                </span>
              ) : (
                `${cell.row.original.completedTasks} / ${cell.row.original.totalTasks}`
              )}
            </>
          )
        }
      }
    ]
    const middleCols =
      artefactsPerPack === 2
        ? [
            {
              Header: 'Left / Right',
              accessor: 'leftWins',
              headerProps: {
                className: ' text-center text-uppercase'
              },
              cellProps: {
                className: 'text-center font-weight-bold'
              },
              Cell: ({ cell }: CellProps<Judge>) => {
                const cellId = sanitizeId(cell.row.original.user_id)

                return (
                  <>
                    {cell.row.original.leftWins !== undefined &&
                      cell.row.original.rightWins !== undefined && (
                        <>
                          <Progress
                            id={`pb-${cellId}`}
                            className="mb-0 progress-slim-3"
                            max={
                              cell.row.original.leftWins +
                              cell.row.original.rightWins
                            }
                            value={cell.row.original.leftWins}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`pb-${cellId}`}
                          >
                            <div className="px-3 py-2">
                              <Row>
                                <Col className="text-center">
                                  <div>Left</div>
                                  <div className="h5 mb-0 font-weight-bold">
                                    {cell.row.original.leftWins}
                                  </div>
                                </Col>
                                <Col className="text-center">
                                  <div>Right</div>
                                  <div className="h5 mb-0 font-weight-bold">
                                    {cell.row.original.rightWins}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </UncontrolledTooltip>
                        </>
                      )}
                  </>
                )
              }
            }
          ]
        : []
    const endCols = [
      getTimeCol('Min', 'minTimeInSeconds'),
      getTimeCol('Median', 'medianTimeInSeconds'),
      getTimeCol('Max', 'maxTimeInSeconds'),
      getTimeCol('Total Time', 'totalTimeInSeconds', 'font-weight-bold')
    ]
    return [...firstCols, ...middleCols, ...endCols]
  }, [orderedJudges, artefactsPerPack])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    rows,
    prepareRow
  } = useTable<Judge>(
    {
      columns,
      data: orderedJudges
    },
    useSortBy
  )

  return (
    <Table striped className="rounded mb-6" {...getTableProps()}>
      <thead className="text-primary text-uppercase bg-light font-weight-bold">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th
                {...column.getHeaderProps(
                  (column as any).getSortByToggleProps(
                    (column as any).headerProps
                  )
                )}
              >
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {(column as any).isSorted ? (
                    (column as any).isSortedDesc ? (
                      <FontAwesomeIcon
                        className="ml-2 position-absolute"
                        icon={faCaretDown}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="ml-2 position-absolute"
                        icon={faCaretUp}
                      />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps((cell.column as any).cellProps)}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
