import produce from 'immer'
import { ProjectData } from '../types'

export type ProjectAction = { type: 'UPDATE_PROJECT'; projectData: ProjectData }

export const createProjectReducer = (
  state: ProjectData,
  action: ProjectAction
): ProjectData => {
  return produce(state, draft => {
    switch (action.type) {
      case 'UPDATE_PROJECT':
        if (action.projectData) {
          draft.name = action.projectData.name
          draft.basis = action.projectData.basis
          draft.packSize = action.projectData.packSize
          draft.endDate = action.projectData.endDate
          draft.planType = action.projectData.planType
          draft.categories = action.projectData.categories
          draft.tempPlanKey = action.projectData.tempPlanKey
          draft.packsPerArtefact = action.projectData.packsPerArtefact
        }
        return draft
      default:
        return draft
    }
  })
}
