import React from 'react'
import { Col, Row } from 'reactstrap'

interface ItemCountInnerProps {
  title?: string
  count: number
}

interface PlanCountProps {
  title: string
  subTitle: string
  accentKeys: string[]
  itemCounts: { [key: string]: number }
}

export const PlanWidget: React.FC<PlanCountProps> = ({
  title,
  subTitle,
  itemCounts,
  accentKeys
}): JSX.Element => (
  <>
    <Row className="bg-white border-top border-bottom  no-gutters">
      <Col>
        <Row className=" align-items-stretch font-weight-bold border-bottom no-gutters">
          <Col className="px-4 py-35">{title}</Col>
          {Object.keys(itemCounts).map((x, i) => (
            <Col
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={{ minHeight: '64px', width: '64px' }}
              className={`align-self-stretch d-flex flex-column text-center justify-content-center h5 mb-0 font-weight-bold  border-left  ${
                accentKeys.includes(x)
                  ? 'bg-primary text-white'
                  : 'bg-lightButNotTooLight text-body'
              }
             
              `}
              xs="auto"
            >
              {x.toLocaleString()}
            </Col>
          ))}
        </Row>
        <Row className="bg-white align-items-stretch no-gutters">
          <Col className="px-4 py-35">{subTitle}</Col>
          {Object.values(itemCounts).map((x, i) => (
            <Col
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={{ minHeight: '64px', width: '64px' }}
              className={`align-self-stretch align-items-center d-flex flex-column justify-content-center  text-center text-secondary h5  mb-0 border-left  ${
                accentKeys.includes(Object.keys(itemCounts)[i])
                  ? ' text-dark'
                  : ''
              }`}
              xs="auto"
            >
              {x.toLocaleString()}
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  </>
)
