import React from 'react'
import { Button, Col, Row, Progress } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'

interface TaskHeaderProjectProps {
  basis: string
  completedTasks: number
  totalTasks: number
}

interface TaskHeaderProps {
  questionAndProgress?: TaskHeaderProjectProps
}

export const TaskHeader: React.FC<TaskHeaderProps> = ({
  questionAndProgress
}) => {
  return (
    <div className="bg-white">
      <div className="bigc">
        <Row className="p-3 align-items-center mx-0">
          <Col className="text-left" xs={6}>
            <Button
              color="link"
              className="font-smaller text-secondary"
              tag={Link}
              to="/"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
              Exit and continue later
            </Button>
          </Col>
          {questionAndProgress && (
            <Col className="text-center">
              <div className="font-weight-bold h5">
                {questionAndProgress.basis}
              </div>
              <div className="h5 font-weight-bold text-secondary mb-0">
                {questionAndProgress.completedTasks ===
                questionAndProgress.totalTasks
                  ? questionAndProgress.completedTasks
                  : questionAndProgress.completedTasks + 1}
                {' / '}
                {questionAndProgress.totalTasks}
              </div>
            </Col>
          )}
          <Col xs={6} className="text-right" />
        </Row>
      </div>
      {questionAndProgress && (
        <Progress
          className="rounded-0 progress-slim-2"
          max={questionAndProgress.totalTasks}
          value={questionAndProgress.completedTasks}
        />
      )}
    </div>
  )
}
