import { useMemo, useReducer, useEffect, useCallback } from 'react'
import { useAsyncTaskAxios, useAsyncRun } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { useConfig } from '../use-remote-config'

import { useGetBearerToken } from './use-get-bearer-token'
import { State } from '../types'
import {
  projectResourceReducer,
  ADD_OR_UPDATE_ACTION,
  REFRESH_ACTION,
  REMOVE_START_ACTION,
  REMOVE_COMPLETE_ACTION,
  REMOVE_ERROR,
  REPLACE_ITEM
} from '../reducers/project-resource-reducer'
import { faGameConsoleHandheld } from '@fortawesome/pro-solid-svg-icons'

export const useProjectResourceManager = <T>(
  projectId: string,
  resourceName: string,
  idFunction: (item: T) => string,
  getDependencies: any[],
  getCondition?: boolean
): State<T> => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const resourceUrl = `${config.apiUrl}/projects/${projectId}/${resourceName}`
  const assetReducer = projectResourceReducer<T>()
  const [data, dispatch] = useReducer(assetReducer, new Map<string, T>())
  const getAssetMemo = useMemo(() => {
    return {
      url: resourceUrl,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [resourceUrl, bearerToken, ...getDependencies])

  const getAssetsTask = useAsyncTaskAxios<AxiosResponse<T[]>>(
    axios,
    getAssetMemo
  )
  useAsyncRun(
    bearerToken && (getCondition === undefined || getCondition) && getAssetsTask
  )

  useEffect(() => {
    if (getAssetsTask.result) {
      dispatch({
        type: REFRESH_ACTION,
        payload: getAssetsTask.result ? getAssetsTask.result.data : [],
        idFunction
      })
    }
  }, [getAssetsTask.result])

  return {
    data: [...data.values()],
    getItem: id => {
      return data.get(id)
    },
    projectId,
    idFunction,
    resourceName,
    getError: getAssetsTask.error,
    retrievingData: getAssetsTask.pending,
    resourceUrl,
    addAssets: newAssets => {
      dispatch({
        type: ADD_OR_UPDATE_ACTION,
        payload: newAssets,
        idFunction
      })
    },
    replaceItem: newItem => {
      dispatch({
        type: REPLACE_ITEM,
        payload: [newItem],
        idFunction
      })
    },
    deleteStart: useCallback((toDelete: T) => {
      dispatch({
        type: REMOVE_START_ACTION,
        deleteId: idFunction(toDelete),
        idFunction
      })
    }, []),
    refresh: (newAssets: T[]) => {
      dispatch({
        type: REFRESH_ACTION,
        payload: newAssets,
        idFunction
      })
    },
    deleteComplete: (toDelete: T) => {
      dispatch({
        type: REMOVE_COMPLETE_ACTION,
        deleteId: idFunction(toDelete),
        idFunction
      })
    },
    deleteFailed: (toDelete: T) => {
      dispatch({
        type: REMOVE_ERROR,
        deleteId: idFunction(toDelete),
        idFunction
      })
    }
  }
}

export interface DeleteProps {
  deleteResource: () => void
}

export const useProjectResourceDeleter = (
  projectId: string,
  resourceName: string,
  itemId: string,
  onDeleteStart: () => void,
  onDeleteComplete: () => void,
  onDeleteError: () => void
): DeleteProps => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const resourceUrl = `${
    config.apiUrl
  }/projects/${projectId}/${resourceName}/${encodeURIComponent(itemId)}`

  const deleteAssetMemo = useMemo(() => {
    return {
      url: resourceUrl,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [resourceUrl, bearerToken])
  const deleteAssetTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    deleteAssetMemo
  )
  useEffect(() => {
    if (deleteAssetTask.result !== null) {
      // success so remove it from the state
      onDeleteComplete()
    }
  }, [deleteAssetTask.result])

  useEffect(() => {
    if (deleteAssetTask.error !== null) {
      // success so remove it from the state
      onDeleteError()
    }
  }, [deleteAssetTask.error])
  return {
    deleteResource: () => {
      onDeleteStart()
      deleteAssetTask.start()
    }
  }
}
