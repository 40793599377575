import React, { memo } from 'react'

import { faFilePdf } from '@fortawesome/pro-regular-svg-icons'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { areEqual } from 'react-window'
import { isEqual } from 'lodash'
import { ArtefactView } from '../../../types'
import { DraftArtefactRow, ArtefactRowProps } from './draft-artefact-row'
import { getKeyWithoutProject } from '../../../helpers/artefact-key-helpers'

function rowPropsAreEqual(prev: ArtefactRowProps, next: ArtefactRowProps) {
  return isEqual(prev.artefact, next.artefact) && prev.showRM === next.showRM
}

const Memo2Row = React.memo(DraftArtefactRow, rowPropsAreEqual)

export const MemoRow = memo<{
  index: number
  style: any
  data: {
    items: ArtefactView[]
    showRM: boolean
    expectedLessActual: string[]
    projectId: string
    onDeleteStart: (key: string) => void
    onDeleteError: (key: string) => void
    onDeleteComplete: (key: string) => void
    showItem: (key: string) => void
  }
}>(
  ({ data, index, style }) => {
    const {
      items,
      showRM,
      expectedLessActual,
      projectId,
      showItem,
      onDeleteStart,
      onDeleteError,
      onDeleteComplete
    } = data
    if (items && index < items.length) {
      return (
        <Memo2Row
          style={style}
          className={`${index !== 0 ? 'border-top' : ''}`}
          projectId={projectId}
          artefact={items[index]}
          showRM={showRM}
          showItem={showItem}
          resourceId={encodeURIComponent(getKeyWithoutProject(items[index]))}
          key={items[index].key}
          onDeleteStart={() => onDeleteStart(items[index].key)}
          onDeleteError={() => onDeleteError(items[index].key)}
          onDeleteComplete={() => onDeleteComplete(items[index].key)}
        />
      )
    }
    const expectedIndex = items ? index - items.length : index
    return (
      <Row
        style={style}
        key={expectedLessActual[expectedIndex]}
        className={`${
          index !== 0 ? 'border-top' : ''
        } align-items-center mx-0 bg-light`}
      >
        <Col className="text-black37 p-35 font-weight-bold">
          <>
            <FontAwesomeIcon size="lg" icon={faFilePdf} className="mr-35" />
            {expectedLessActual[expectedIndex]}
          </>
        </Col>
      </Row>
    )
  },

  // Step 2: Pass it as the second argument to React.memo()
  areEqual
)
