import React from 'react'

import { ModalHeader, ModalHeaderProps } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'

export const CJModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <ModalHeader
      tag="div"
      className={`${className}  py-25 modal-header-cj justify-content-center font-weight-bold border-0 `}
      {...rest}
    >
      {children}
    </ModalHeader>
  )
}

export const CJModalHeaderEdit: React.FC = () => {
  return (
    <CJModalHeader className="bg-primary">
      <FontAwesomeIcon
        color="white"
        size="lg"
        className="my-2"
        icon={faPencil}
      />
    </CJModalHeader>
  )
}
