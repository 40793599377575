import React, { useEffect, FunctionComponent } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../Auth/auth'
import { rules } from '../Auth/rbac-rules'
import { check } from '../Auth/rbac-rule-checker'

export const RestrictedRoute: FunctionComponent<any> = ({
  component: Component,
  path,
  permission,
  ...rest
}): JSX.Element => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()

  useEffect(() => {
    const fn = async (): Promise<void> => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          // eslint-disable-next-line @typescript-eslint/camelcase
          redirect_uri: window.location.origin,
          appState: { targetUrl: window.location.pathname }
        })
      }
    }
    fn()
  }, [isAuthenticated, loginWithRedirect, path])

  if (
    isAuthenticated &&
    (permission && (user && !check(rules, user.roles, permission)))
  )
    return <></>

  const render = (properties: any): JSX.Element => <Component {...properties} />

  return <Route path={path} render={render} {...rest} />
}
