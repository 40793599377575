import { AxiosResponse } from 'axios'

export const isNotFound = (
  axiosResponse: AxiosResponse | undefined
): boolean => {
  if (axiosResponse && axiosResponse.status === 404) {
    return true
  }
  return false
}

export const isTaskPausedError = (
  axiosResponse: AxiosResponse<string> | undefined
): boolean => {
  if (
    axiosResponse &&
    axiosResponse.status === 409 &&
    axiosResponse.data === 'ERR_PROJECT_PAUSED'
  ) {
    return true
  }
  return false
}
