import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'

import { ConnectStatus } from '../../types'

interface PlanConnectivityProps {
  connected: ConnectStatus
}

export const PlanConnectivity: React.FC<PlanConnectivityProps> = ({
  connected
}): JSX.Element => {
  let subText = <>Not available</>
  const img = (
    <FontAwesomeIcon
      className="h5 mb-0 mr-2"
      icon={connected === 'CONNECTED' ? faCheckCircle : faExclamationTriangle}
    />
  )
  if (connected === 'CONNECTED') {
    subText = (
      <>
        Each artefact is compared either directly or indirectly with all other
        artefacts
      </>
    )
  } else if (connected === 'UNCONNECTED') {
    subText = (
      <>
        {'Each artefact '}
        <u>is not</u>
        {' compared either directly or indirectly with all other artefacts'}
      </>
    )
  }
  return (
    <div className="d-flex flex-row align-items-center">
      <div
        className={
          connected === 'CONNECTED' ? 'text-primary pr-3' : 'text-danger pr-2'
        }
      >
        {img}
      </div>
      <div>
        <div style={{ fontSize: '18px' }}>
          <b>
            {connected === 'CONNECTED' ? 'Connected Plan' : 'Unconnected Plan'}
          </b>
        </div>
        <div>{subText}</div>
      </div>
    </div>
  )
}
