import React, { useRef, useMemo, useState, useEffect } from 'react'
import { Label, Button, Spinner, FormFeedback } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudUploadAlt,
  faTable,
  faFileCsv,
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import uuidv4 from 'uuid/v4'
import axios, { AxiosResponse } from 'axios'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Step, StepDefinition, StepControls } from './step'
import { WizardStepProps, UploadPlanStepProps, PlanTopLine } from '../../types'
import { useUploadS3 } from '../use-upload-s3'
import { useConfig } from '../../use-remote-config'
import { useGetS3UploadToken } from '../use-get-temp-s3-upload-token'
import { useGetBearerToken } from '../use-get-bearer-token'
import { PlanTopLineView } from './plan-top-line-view'
import { SimpleMessage } from '../shared/simple-message/simple-message'
import { SimpleErrorMessage } from '../shared/simple-message/simple-error-message'
import { Jiggler } from '../jiggler'

export const UploadPlanStep: React.FC<WizardStepProps<UploadPlanStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  totalSteps,
  goBack,
  onChange
}) => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()

  const s3tokenTask = useGetS3UploadToken({ autoStart: true })
  const inputFile = useRef<HTMLInputElement>(null)
  const [uploadValid, setUploadValid] = useState(true)
  const [toggler, setToggler] = useState(true)
  const { uploadState, fileUploadCallback } = useUploadS3(
    config.bucketName || '',
    s3tokenTask.result ? s3tokenTask.result.data : undefined
  )
  const [fileInContext, setFileInContext] = useState(false)

  const uploadStateArray = useMemo(() => {
    return [...uploadState.values()]
  }, [uploadState])
  const getPlanTopLineAxiosRequest = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/validatePlan?planKey=${
        uploadStateArray.length > 0 ? uploadStateArray[0].key : ''
      }`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, bearerToken, uploadState])

  const getPlanTopLineTask = useAsyncTaskAxios<AxiosResponse<PlanTopLine>>(
    axios,
    getPlanTopLineAxiosRequest
  )
  const sessionGuid = useMemo(() => {
    return uuidv4()
  }, [])

  const latestTempPlanKey = useMemo(() => {
    return uploadStateArray.length > 0 ? uploadStateArray[0].key : ''
  }, [uploadState])

  useEffect(() => {
    if (
      !uploadValid &&
      getPlanTopLineTask.result &&
      (!getPlanTopLineTask.result.data.catCounts ||
        Object.keys(getPlanTopLineTask.result.data.catCounts).length < 4) &&
      Object.keys(getPlanTopLineTask.result.data.packSizes).length === 1
    ) {
      setUploadValid(true)
    }
    if (getPlanTopLineTask.result && onChange) {
      onChange({
        categories: getPlanTopLineTask.result.data.catCounts
          ? Object.keys(getPlanTopLineTask.result.data.catCounts)
          : [],
        tempPlanKey: uploadStateArray.length > 0 ? uploadStateArray[0].key : '',
        packSize: getPlanTopLineTask.result.data.packSizes[0],
        packsPerArtefact: undefined
      })
    }
  }, [getPlanTopLineTask.result, latestTempPlanKey, uploadValid])

  useAsyncRun(
    bearerToken &&
      uploadStateArray.length > 0 &&
      uploadStateArray[0].uploadingState.isComplete &&
      getPlanTopLineTask
  )
  return (
    <Step
      stepNumber={stepNumber}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <StepDefinition
        stepNumber={stepNumber}
        stepTitle="Upload and Review Plan"
        totalSteps={totalSteps}
        currentStep={currentStep}
        goBack={() => {
          if (goBack) {
            goBack()
          }
        }}
        goForward={() => {
          const isUploadValid =
            getPlanTopLineTask.result !== null &&
            (!getPlanTopLineTask.result.data.catCounts ||
              Object.keys(getPlanTopLineTask.result.data.catCounts).length <
                4) &&
            Object.keys(getPlanTopLineTask.result.data.packSizes).length === 1
          if (!isUploadValid) {
            setToggler(!toggler)
            setUploadValid(isUploadValid)
          }
          if (isUploadValid) {
            goForward()
          }
        }}
      >
        <Label className="wizard-label font-weight-bold mb-2 font-larger">
          Please upload your plan
        </Label>
        <div className="text-secondary mb-4">
          After uploading the plan you can review and if necessary delete the
          existing and upload it again, you can find a template here
        </div>
        <div>
          <Button
            tag="a"
            href="/planTemplate.csv"
            color="ddd"
            className="mb-4 text-secondary"
          >
            <FontAwesomeIcon icon={faTable} className="align-middle mr-2" />
            Download CSV Template
          </Button>
        </div>
        <div>
          <input
            type="file"
            id="file"
            data-testid="input-upload-plan"
            ref={inputFile}
            style={{ display: 'none' }}
            accept=".csv"
            onChange={event => {
              if (event.target.files && event.target.files.length > 0) {
                setFileInContext(true)
                fileUploadCallback(
                  [...event.target.files],
                  `uploadplans/${sessionGuid}/`
                )
                // eslint-disable-next-line no-param-reassign
                event.target.value = ''
              }
            }}
          />
          <Button
            color={`${
              getPlanTopLineTask.error && fileInContext ? 'danger' : 'primary'
            }`}
            disabled={
              uploadStateArray.length > 0 &&
              uploadStateArray[0].uploadingState.isLoading
            }
            className="mb-2"
            onClick={() => {
              if (fileInContext) {
                setFileInContext(false)
              } else if (inputFile !== null && inputFile.current !== null) {
                inputFile.current.click()
              }
            }}
          >
            {uploadStateArray.length > 0 &&
              fileInContext &&
              uploadStateArray[0].uploadingState.isComplete && (
                <>
                  <FontAwesomeIcon
                    icon={faFileCsv}
                    className="align-middle mr-2"
                  />
                  {uploadStateArray[0].name}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="align-middle ml-45"
                  />
                </>
              )}
            {uploadStateArray.length > 0 &&
              fileInContext &&
              uploadStateArray[0].uploadingState.isLoading && (
                <Spinner color="white" size="sm" />
              )}
            {!(
              uploadStateArray.length > 0 &&
              fileInContext &&
              (uploadStateArray[0].uploadingState.isLoading ||
                uploadStateArray[0].uploadingState.isComplete)
            ) && (
              <>
                <FontAwesomeIcon
                  icon={faCloudUploadAlt}
                  className="align-middle mr-2"
                />
                Upload Plan
              </>
            )}
          </Button>
          <FormFeedback
            className={`mr-2 px-3 ${!uploadValid ? 'd-block' : ''}`}
          >
            Please upload a valid plan to proceed
          </FormFeedback>
        </div>
      </StepDefinition>
      <StepControls>
        {fileInContext && getPlanTopLineTask.error && (
          <Jiggler toggle={toggler}>
            <SimpleErrorMessage
              title="Uploaded plan is invalid..."
              className="mb-5"
            >
              <p>
                Please check your plan for issues, ensure all requied fields{' '}
                <b>(judge, pack, artefact)</b> are completed for every row
              </p>
            </SimpleErrorMessage>
          </Jiggler>
        )}
        {fileInContext &&
          uploadStateArray.length > 0 &&
          uploadStateArray[0].uploadingState.isError && (
            <Jiggler toggle={toggler}>
              <SimpleErrorMessage
                title="Failed to upload plan..."
                className="mb-5"
                message="There was a problem uploading your plan, please retry"
              />
            </Jiggler>
          )}

        {getPlanTopLineTask.started && getPlanTopLineTask.pending && (
          <SimpleMessage
            className="py-5"
            icon={<Spinner className="d-block mx-auto text-primary" />}
            title="Analysing"
            message="The system is analysing your plan"
          />
        )}
        {getPlanTopLineTask.result && fileInContext && (
          <PlanTopLineView
            toggleCheckError={toggler}
            {...getPlanTopLineTask.result.data}
          />
        )}
      </StepControls>
    </Step>
  )
}
