import React, { FC, useReducer, useMemo, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPeopleCarry,
  faSearch,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { Button, ModalProps, Spinner } from 'reactstrap'
import { CJModal } from '../../shared/modal/modal'
import { CJModalHeader } from '../../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../../shared/modal/subcomponents/modal-body'
import { CJModalFooter } from '../../shared/modal/subcomponents/modal-footer'
import { Judge, JudgeAllocation } from '../../../types'
import { ReallocateFromRow } from '../../shared/reallocate-rows/reallocate-from-row'
import { UserLookup, UserSelectView } from '../../user-lookup'
import { ValueType } from 'react-select'
import { reallocationReducer } from '../../../reducers/reallocation-reducer'
import { ReallocateToRow } from '../../shared/reallocate-rows/reallocate-to-row'
import { useConfig } from '../../../use-remote-config'
import { useGetBearerToken } from '../../use-get-bearer-token'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { SimpleModalMessage } from '../../shared/modal/subcomponents/modal-message'

interface ReallocateModalProps {
  projectId: string
  judge: Judge
  existingJudges: Judge[]
  onReallocationSuccess: (updatedJudges: Judge[]) => void
}

const initialState = {
  allocations: new Map<string, JudgeAllocation>()
}

export const ReallocateModal: FC<ModalProps & ReallocateModalProps> = ({
  isOpen,
  toggle,
  judge,
  projectId,
  onReallocationSuccess,
  existingJudges
}) => {
  const [reallocateFrom, setReallocateFrom] = useState(judge)
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const [allocationState, dispatch] = useReducer(
    reallocationReducer,
    initialState
  )
  const [confirmReallocateModal, setConfirmReallocateModal] = useState(false)

  const [allocationValid, setAllocationValid] = useState(true)

  const allocated = useMemo(() => {
    if (allocationState.allocations.size === 0) {
      return 0
    }
    return Array.from(allocationState.allocations.values()).reduce(
      (sum, current) => sum + current.toReallocate,
      0
    )
  }, [allocationState])
  useEffect(() => {
    if (judge.isPlaceholder && allocated === judge.totalTasks) {
      setAllocationValid(true)
    }
  }, [allocated])
  useEffect(() => {
    setReallocateFrom(judge)
  }, [judge])

  const reallocateMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${projectId}/judges/${encodeURIComponent(
        reallocateFrom.user_id
      )}/reallocateTasks`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [bearerToken, config.apiUrl])

  const reallocateTask = useAsyncTaskAxios<AxiosResponse<Judge[]>>(
    axios,
    reallocateMemo
  )

  const getJudgesMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects/${projectId}/judges`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [bearerToken, config.apiUrl])

  const getJudgesTask = useAsyncTaskAxios<AxiosResponse<Judge[]>>(
    axios,
    getJudgesMemo
  )

  useEffect(() => {
    if (reallocateTask.result && onReallocationSuccess) {
      onReallocationSuccess(reallocateTask.result.data)
      if (toggle) {
        toggle()
      }
    }
  }, [reallocateTask.result])

  useEffect(() => {
    if (getJudgesTask.result && onReallocationSuccess) {
      const judgeMap = new Map<string, Judge>(
        getJudgesTask.result.data.map(x => [x.user_id, x])
      )

      const updatedOwner = judgeMap.get(reallocateFrom.user_id)
      if (updatedOwner) {
        setReallocateFrom(updatedOwner)
      }
      allocationState.allocations.forEach(x => {
        const toReplace = judgeMap.get(x.user_id)
        if (toReplace) {
          dispatch({ type: 'addJudge', judge: toReplace })
        }
      })

      onReallocationSuccess(getJudgesTask.result.data)
    }
  }, [getJudgesTask.result])

  useEffect(() => {
    if (reallocateTask.error && onReallocationSuccess) {
      getJudgesTask.start()
    }
  }, [reallocateTask.error])
  const performReallocate = () => {
    const initialOne: { [key: string]: number } = {}
    const allocations = Array.from(allocationState.allocations.values()).reduce(
      (acc, value) => {
        acc[value.user_id] = value.toReallocate
        return acc
      },
      initialOne
    )
    reallocateTask.start({ data: { allocations } })
  }
  return (
    <CJModal centered isOpen={isOpen} size="627">
      <CJModalHeader className="bg-primary">
        <FontAwesomeIcon
          color="white"
          icon={faPeopleCarry}
          size="lg"
          className="my-2"
        />
      </CJModalHeader>
      <CJModalBody>
        <div className="font-weight-bold text-primary h5 mb-5">
          {judge.isPlaceholder ? 'Allocate work' : 'Reallocate work'}
        </div>

        <ReallocateFromRow {...reallocateFrom} allocated={allocated} />
        <div className="mb-35">
          <UserLookup
            placeHolder="Search for an existing judge or add a new one... "
            value={[]}
            existingToFilerOut={[
              reallocateFrom,
              ...allocationState.allocations.values()
            ]}
            onChange={(value: ValueType<UserSelectView>) => {
              const newVal = value as UserSelectView

              if (newVal) {
                const existing = existingJudges.find(
                  x => x.user_id === newVal.data.user_id
                )

                dispatch({
                  type: 'addJudge',
                  judge: {
                    ...newVal.data,
                    ...{ completedTasks: 0, totalTasks: 0 },
                    ...existing
                  }
                })
              }
            }}
          />
        </div>
        {allocationState.allocations.size > 0 && (
          <div className="rounded border overflow-hidden bg-light">
            {Array.from(allocationState.allocations.values()).map(x => (
              <ReallocateToRow
                key={x.user_id}
                {...x}
                maxAvailable={
                  reallocateFrom.totalTasks -
                  reallocateFrom.completedTasks -
                  allocated
                }
                onRemoveRowClick={() => {
                  dispatch({ type: 'removeJudge', judgeId: x.user_id })
                }}
                disabled={
                  reallocateFrom.totalTasks -
                    reallocateFrom.completedTasks -
                    allocated +
                    x.toReallocate ===
                  0
                }
                onAllocationChange={newAllocation => {
                  dispatch({
                    type: 'updateAllocation',
                    judgeId: x.user_id,
                    newAllocation: Math.min(
                      newAllocation,
                      reallocateFrom.totalTasks -
                        reallocateFrom.completedTasks -
                        allocated +
                        x.toReallocate
                    )
                  })
                }}
              />
            ))}
          </div>
        )}
      </CJModalBody>
      <CJModalFooter>
        {!allocationValid && !reallocateTask.error && (
          <div className="w-100 text-center font-weight-bold mb-2 text-danger">
            <FontAwesomeIcon
              color="danger"
              icon={faExclamationTriangle}
              className="mr-2"
            />
            You must allocate all of the unallocated work
          </div>
        )}
        {reallocateTask.error && getJudgesTask.result && (
          <div className="w-100 text-center font-weight-bold mb-2 text-danger">
            <FontAwesomeIcon
              color="danger"
              icon={faExclamationTriangle}
              className="mr-2"
            />
            Reallocating tasks has failed, please try again
          </div>
        )}
        {toggle !== undefined && (
          <Button color="ddd" outline onClick={toggle}>
            Cancel
          </Button>
        )}
        <Button
          color="primary"
          disabled={
            (reallocateTask.started && reallocateTask.pending) ||
            allocated === 0
          }
          onClick={() => {
            if (judge.isPlaceholder && allocated !== judge.totalTasks) {
              setAllocationValid(false)
            } else {
              if (
                reallocateFrom.isPlaceholder &&
                allocationState.allocations.size === 1 &&
                allocated ===
                  reallocateFrom.totalTasks - reallocateFrom.completedTasks &&
                existingJudges.filter(x =>
                  allocationState.allocations.has(x.user_id)
                ).length === 0
              ) {
                performReallocate()
              } else {
                setConfirmReallocateModal(true)
              }
            }
          }}
        >
          {reallocateTask.started && reallocateTask.pending && (
            <Spinner size="sm" />
          )}
          {!(reallocateTask.started && reallocateTask.pending) && 'Assign'}
        </Button>
        <CJModal
          centered
          isOpen={confirmReallocateModal}
          toggle={() => setConfirmReallocateModal(!confirmReallocateModal)}
          size="sm"
        >
          <CJModalHeader className="bg-ddd">
            <FontAwesomeIcon
              color="black"
              icon={faExclamationTriangle}
              size="lg"
              className="my-2"
            />
          </CJModalHeader>
          <CJModalBody>
            <SimpleModalMessage
              title="This action will change the allocation of tasks to judges"
              message="Press continue to proceed, this cannot be undone"
            />
          </CJModalBody>
          <CJModalFooter>
            <Button
              color="ddd"
              outline
              onClick={() => {
                setConfirmReallocateModal(false)
              }}
            >
              Go back
            </Button>
            <Button
              color="ddd"
              onClick={() => {
                performReallocate()
              }}
            >
              Continue
            </Button>
          </CJModalFooter>
        </CJModal>
      </CJModalFooter>
    </CJModal>
  )
}
