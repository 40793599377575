import React, { useMemo, useState, useEffect } from 'react'
import { Col, Row, Button, Spinner, FormFeedback } from 'reactstrap'
import { range } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGavel,
  faExclamationTriangle,
  faUserPlus
} from '@fortawesome/pro-solid-svg-icons'
import { ValueType } from 'react-select/src/types'

import { UserSelectView, UserLookup } from '../user-lookup'
import { SimpleMessage } from '../shared/simple-message/simple-message'

import { JudgeState } from '../use-judge-manager'
import { DraftJudge } from './draft-judge'

interface JudgeTabProps {
  projectId: string
  judgeState: JudgeState
  expectedJudges?: number
}

export const JudgeTab: React.FC<JudgeTabProps> = ({
  projectId,
  judgeState,
  expectedJudges
}): JSX.Element => {
  const [inputValid, setInputValid] = useState(true)

  const [usersToAdd, setUsersToAdd] = useState<UserSelectView[]>([])
  const judgePlaceholders = useMemo(() => {
    const end = expectedJudges || 0
    const start = end > 0 ? Math.min(end, judgeState.allJudges.length) : 0
    return range(start, end)
  }, [judgeState, expectedJudges])

  useEffect(() => {
    if (!inputValid) {
      const isInputValid =
        expectedJudges === undefined ||
        usersToAdd.length <= expectedJudges - judgeState.nonPlaceholders.length
      setInputValid(isInputValid)
    }
  }, [judgeState.allJudges])

  useEffect(() => {
    if (judgeState.state.completedAt) {
      setUsersToAdd([])
    }
  }, [judgeState.state.completedAt])

  const handleUserInputChange = (value: ValueType<UserSelectView>) => {
    const newVal = value as UserSelectView[]
    if (!inputValid && newVal !== null) {
      const isInputValid =
        expectedJudges === undefined ||
        newVal.length <= expectedJudges - judgeState.nonPlaceholders.length
      setInputValid(isInputValid)
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setUsersToAdd(newVal || [])
  }

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <UserLookup
            allowNewUserCreation
            isMulti
            placeHolder="Search an existing judge or add a new email address"
            existingToFilerOut={judgeState.allJudges}
            value={usersToAdd}
            onChange={handleUserInputChange}
          />
        </Col>
        <Col xs="auto" className=" ">
          <Button
            color="primary"
            className="px-4"
            disabled={
              judgeState.addingPlaceholder ||
              judgeState.state.loading ||
              (judgePlaceholders.length > 0 && usersToAdd.length === 0)
            }
            onClick={() => {
              if (usersToAdd.length === 0) {
                judgeState.addPlaceHolder()
              } else {
                const isInputValid =
                  expectedJudges === undefined ||
                  usersToAdd.length <=
                    expectedJudges - judgeState.nonPlaceholders.length
                setInputValid(isInputValid)
                if (isInputValid) {
                  judgeState.uploadJudges(usersToAdd)
                }
              }
            }}
          >
            {(judgeState.state.loading || judgeState.addingPlaceholder) && (
              <Spinner size="sm" />
            )}
            {!(judgeState.state.loading || judgeState.addingPlaceholder) && (
              <>
                Add <FontAwesomeIcon className="ml-2" icon={faUserPlus} />
              </>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mb-45">
        <Col>
          <FormFeedback
            className={`mt-3 ml-6 px-45 ${!inputValid ? 'd-block' : ''}`}
          >
            {`This project can have a maximum of ${expectedJudges || 0} judges`}
          </FormFeedback>
          <FormFeedback
            className={`ml-6 mt-3  px-45 ${
              inputValid && judgeState.state.error ? 'd-block' : ''
            }`}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
            Adding judges failed, please try again
          </FormFeedback>
        </Col>
      </Row>

      {judgeState.allJudges.length === 0 && judgePlaceholders.length === 0 && (
        <SimpleMessage
          className="mb-5"
          title="You have no judges in your project "
          message="Use the search bar above to add or invite new judges to your project"
          icon={<FontAwesomeIcon icon={faGavel} size="2x" />}
        />
      )}

      {[...judgeState.nonPlaceholders, ...judgeState.placeholders].map(
        (item, index) => {
          let className = index === 0 ? 'rounded-top border-top' : ''
          if (
            index ===
            judgeState.allJudges.length + judgePlaceholders.length - 1
          ) {
            className += ' rounded-bottom'
          }
          return (
            <DraftJudge
              key={item.user_id}
              className={className}
              index={index}
              projectId={projectId}
              onDeleteStart={() => judgeState.resourceManager.deleteStart(item)}
              onDeleteError={() =>
                judgeState.resourceManager.deleteFailed(item)
              }
              onDeleteComplete={() =>
                judgeState.resourceManager.deleteComplete(item)
              }
              user={item}
            />
          )
        }
      )}
      {judgePlaceholders.map((value, index) => (
        <Row
          key={value}
          className={`${
            index === judgePlaceholders.length - 1 ? 'rounded-bottom' : ''
          }  ${
            judgeState.allJudges.length === 0 && index === 0
              ? 'border-top rounded-top'
              : ''
          } bg-light align-items-stretch mb-0 mx-0 border-left border-right border-bottom`}
        >
          <Col
            style={{ minWidth: '64px' }}
            xs="auto"
            className="font-larger bg-light rounded-left font-weight-bold my-0 border-right align-items-center justify-content-center d-flex px-4 py-35"
          >
            {value + 1}
          </Col>
          <Col />
        </Row>
      ))}
    </>
  )
}
