import produce from 'immer'
import { Judge, JudgeAllocation } from '../types'

export type ReallocateAction =
  | { type: 'addJudge'; judge: Judge }
  | { type: 'removeJudge'; judgeId: string }
  | { type: 'updateAllocation'; judgeId: string; newAllocation: number }

export interface ReallocateState {
  allocations: Map<string, JudgeAllocation>
}
export const reallocationReducer = (
  state: ReallocateState,
  action: ReallocateAction
): ReallocateState => {
  return produce(state, draft => {
    switch (action.type) {
      case 'addJudge': {
        draft.allocations.set(action.judge.user_id, {
          ...action.judge,
          toReallocate: 0
        })
        return draft
      }
      case 'removeJudge': {
        draft.allocations.delete(action.judgeId)
        return draft
      }
      case 'updateAllocation': {
        draft.allocations.get(action.judgeId)!.toReallocate =
          action.newAllocation
        return draft
      }
      default:
        return draft
    }
  })
}
