import React, { useMemo, useState } from 'react'
import { Button, Row, Col, Container, TabContent, TabPane } from 'reactstrap'
import axios, { AxiosResponse } from 'axios'
import { Link } from 'react-router-dom'
import querystring from 'querystring'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faDraftingCompass,
  faStopwatch,
  faCheck
} from '@fortawesome/pro-solid-svg-icons'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import emptyListDraft from '../assets/icon-draft.svg'
import emptyListCompleted from '../assets/icon-completed.svg'
import emptyListInProgress from '../assets/icon-inprogress.svg'

import { useAuth0 } from '../Auth/auth'
import { Project } from '../types'
import { Can } from './can'

import { SimpleMessage } from './shared/simple-message/simple-message'
import { SimpleErrorMessage } from './shared/simple-message/simple-error-message'
import { useConfig } from '../use-remote-config'
import { useGetBearerToken } from './use-get-bearer-token'
import { TabList } from './shared/tabs/tab-list'
import { Tab } from './shared/tabs/subcomponents/tab'
import { TabContentLabelWithCount } from './tab-content-label-with-count'
import { ProjectDraftRow } from './shared/project-rows/project-list-draft-row'
import { ProjectInProgressRow } from './shared/project-rows/project-list-inprogress-row'
import { ProjectCompletedRow } from './shared/project-rows/project-list-completed-row'

const NO_PROJECT_MSG =
  'You can create a new project with the "New Project" button at the top.'

export const MyProjects = (): JSX.Element => {
  const bearerToken = useGetBearerToken()
  const { config } = useConfig()
  const { user } = useAuth0()
  const { location } = useReactRouter()
  const statusFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )
    if (!qs.status) {
      return 'DRAFT'
    }
    if (Array.isArray(qs.status)) {
      return qs.status[0]
    }
    return qs.status
  }, [location])
  const [invalidateGetProjectToggle, setInvalidateGetProjectToggle] = useState(
    false
  )
  const listProjectMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects?role=ADMIN&role=OWNER`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, bearerToken, invalidateGetProjectToggle])

  const listProjectsTask = useAsyncTaskAxios<AxiosResponse<Project[]>>(
    axios,
    listProjectMemo
  )
  useAsyncRun(config && bearerToken && listProjectsTask)

  const draftProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }
    return listProjectsTask.result.data.filter(x => x.status === 'DRAFT')
  }, [listProjectsTask.result])

  const inProgresProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }
    return listProjectsTask.result.data.filter(x => x.status === 'INPROGRESS')
  }, [listProjectsTask.result])

  const completeProjects = useMemo(() => {
    if (!listProjectsTask.result) {
      return []
    }
    return listProjectsTask.result.data.filter(x => x.status === 'COMPLETE')
  }, [listProjectsTask.result])

  return (
    <>
      <div className="bg-light py-45 px-3 ">
        <Container>
          <Row className=" mb-45  ">
            <Col>
              <h1 className="h4 font-weight-bold mb-0">Projects</h1>
            </Col>
            <Col className="text-right">
              {' '}
              <Can
                userRoles={user ? user.roles : []}
                perform="project:create"
                yes={(): JSX.Element => (
                  <Link to="/createProject/1">
                    <Button
                      color="primary"
                      id="link-create"
                      data-testid="btn-new-project"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span className="ml-2">New project</span>
                    </Button>
                  </Link>
                )}
                no={(): JSX.Element => <></>}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="text-left font-weight-bold">
              <TabList>
                <Tab autoSize to="/projects?status=DRAFT">
                  <TabContentLabelWithCount
                    label="Draft"
                    isActive={statusFilter === 'DRAFT'}
                    icon={faDraftingCompass}
                    count={draftProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
                <Tab autoSize to="/projects?status=INPROGRESS">
                  <TabContentLabelWithCount
                    label="In Progress"
                    isActive={statusFilter === 'INPROGRESS'}
                    icon={faStopwatch}
                    count={inProgresProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
                <Tab autoSize to="/projects?status=COMPLETE">
                  <TabContentLabelWithCount
                    label="Completed"
                    isActive={statusFilter === 'COMPLETE'}
                    icon={faCheck}
                    count={completeProjects.length}
                    countPending={listProjectsTask.pending}
                  />
                </Tab>
              </TabList>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-55">
        <Can
          userRoles={user ? user.roles : []}
          perform="project:list"
          yes={(): JSX.Element => (
            <>
              {listProjectsTask.error && (
                <SimpleErrorMessage
                  title=" Failed to get project list"
                  message="Please refresh the page, and if the problem persists contact your system administrator."
                  allowPageRefresh
                />
              )}

              <TabContent activeTab={statusFilter}>
                <TabPane tabId="DRAFT">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    draftProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no draft projects"
                        message={NO_PROJECT_MSG}
                        icon={<img src={emptyListDraft} alt="No projects" />}
                      />
                    )}
                  {draftProjects.map(project => (
                    <Link
                      key={project.id}
                      className="no-text-hover"
                      to={`/projects/${project.id}/artefacts`}
                    >
                      <ProjectDraftRow
                        {...project}
                        triggerRemoveRow={() => {
                          setInvalidateGetProjectToggle(
                            !invalidateGetProjectToggle
                          )
                        }}
                        className="mb-5"
                      />
                    </Link>
                  ))}
                </TabPane>
                <TabPane tabId="INPROGRESS">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    inProgresProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no in progress projects"
                        message={NO_PROJECT_MSG}
                        icon={
                          <img src={emptyListInProgress} alt="No projects" />
                        }
                      />
                    )}
                  {inProgresProjects.map(project => (
                    <Link
                      key={project.id}
                      className="no-text-hover"
                      to={`/projects/${project.id}/judges`}
                    >
                      <ProjectInProgressRow {...project} className="mb-5" />
                    </Link>
                  ))}
                </TabPane>
                <TabPane tabId="COMPLETE">
                  {listProjectsTask.error == null &&
                    !listProjectsTask.pending &&
                    completeProjects.length === 0 && (
                      <SimpleMessage
                        className="mb-5"
                        title="You have no completed projects"
                        message={NO_PROJECT_MSG}
                        icon={
                          <img src={emptyListCompleted} alt="No projects" />
                        }
                      />
                    )}
                  {completeProjects.map(project => (
                    <Link
                      key={project.id}
                      className="no-text-hover"
                      to={`/projects/${project.id}/artefacts`}
                    >
                      <ProjectCompletedRow {...project} className="mb-5" />
                    </Link>
                  ))}
                </TabPane>
              </TabContent>
            </>
          )}
          no={(): JSX.Element => <></>}
        />
      </Container>
    </>
  )
}
