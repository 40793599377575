import React from 'react'

import { Row, Col } from 'reactstrap'

export interface Counts {
  title: string
  className?: string
  subTitle?: string
  count?: number
  icon?: JSX.Element
  maxHeight?: number
}

export const CountBox: React.FunctionComponent<Counts> = ({
  title,
  subTitle,
  className,
  maxHeight,
  children,
  count,
  icon
}) => (
  <div className={` ${className}  border rounded overflow-hidden`}>
    <Row
      className={`${subTitle ? 'text-black' : 'text-primary'} ${
        React.Children.count(children) === 0
          ? 'rounded-bottom'
          : 'border-bottom'
      } mx-0  rounded-top bg-light px-3 py-35  font-weight-bold align-items-center`}
    >
      {icon && <Col xs="auto">{icon}</Col>}
      <Col>
        <div>{title}</div>
        {subTitle && <div className="font-weight-normal">{subTitle}</div>}
      </Col>
      {count && (
        <Col className="text-right h4 my-0 font-weight-bold" xs="auto">
          {count}
        </Col>
      )}
    </Row>
    {React.Children.count(children) > 0 && (
      <Row
        style={maxHeight ? { maxHeight } : {}}
        className="overflow-auto custom-scroll mx-0"
      >
        <Col className="px-0">
          {React.Children.map(children, (child, index) => (
            <Row
              key={index}
              className={`${
                index === React.Children.count(children) - 1
                  ? ''
                  : ' border-bottom'
              } mx-0  px-3 py-4  align-items-center font-weight-bold`}
            >
              {child}
            </Row>
          ))}
        </Col>
      </Row>
    )}
  </div>
)
