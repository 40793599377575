import React from 'react'

import { ModalFooter, ModalFooterProps } from 'reactstrap'

export const CJModalFooter: React.FC<ModalFooterProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <ModalFooter
      {...rest}
      className={`${className} bg-white pb-4 bg-white  border-0 justify-content-center rounded-bottom`}
    >
      {children}
    </ModalFooter>
  )
}
