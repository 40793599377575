import { useCallback, useMemo, useReducer, useEffect } from 'react'
import aws from 'aws-sdk'
import { CredentialsOptions } from 'aws-sdk/lib/credentials'
import { getBinary } from '../helpers/get-binary'

import {
  uploadingReducer,
  ADD_ACTION,
  PROGRESS_ACTION,
  ERROR_ACTION,
  COMPLETE_ACTION,
  START_DOWNLOADING,
  REMOVE,
  UploadingState
} from '../reducers/uploading-reducer'
import { UploadingFile } from '../types'

export interface UploadState {
  uploadState: Map<string, UploadingFile>
  queuCount: number
  inProgress: Set<string>
  inProgressCount: number
  fileUploadCallback: (files: File[], keyPrefix?: string) => void
  removeCompleted: (keys: string[]) => void
}
const initialState: UploadingState = {
  files: new Map<string, UploadingFile>(),
  inProgress: new Set<string>(),
  queue: new Set<string>()
}
export const useUploadS3 = (
  bucketName: string,
  s3Credentials?: CredentialsOptions
): UploadState => {
  const [uploadState, uploadDispatch] = useReducer(
    uploadingReducer,
    initialState
  )

  const s3 = useMemo(() => {
    if (!s3Credentials) {
      return undefined
    }
    const tempS3 = new aws.S3({
      region: 'eu-west-1',
      credentials: s3Credentials
    })
    return tempS3
  }, [s3Credentials])

  useEffect(() => {
    const isCancelled = false
    const alreadyRunning = uploadState.inProgress.size
    if (alreadyRunning === 0) {
      const countToStart = Math.max(0, 5 - alreadyRunning)
      const batchKeys = Array.from(
        { length: countToStart },
        (i => () => i.next().value)(uploadState.queue.keys())
      )

      const batch: UploadingFile[] = []
      batchKeys
        .filter(x => x !== undefined)
        .forEach(x => {
          const match = uploadState.files.get(x)
          if (match) {
            batch.push(match)
          }
        })
      if (batch.length > 0) {
        uploadDispatch({
          type: START_DOWNLOADING,
          payload: { keys: batch.map(x => x.key) }
        })

        const getShizzle = async (toUpload: UploadingFile[]) => {
          const proms = toUpload.map(
            async (file: UploadingFile): Promise<void> => {
              if (file.file) {
                const binaryString = await getBinary(file.file)

                if (binaryString) {
                  const params: aws.S3.PutObjectRequest = {
                    Body: binaryString,
                    Bucket: bucketName || '',
                    Key: file.key,
                    ContentType: 'application/pdf'
                  }
                  try {
                    if (s3) {
                      const putter = s3.upload(params)
                      putter.on('httpUploadProgress', (progress): void => {
                        if (!isCancelled) {
                          uploadDispatch({
                            type: PROGRESS_ACTION,
                            payload: { key: file.key, progress }
                          })
                        }
                      })
                      await putter.promise()
                    }
                  } catch (error) {
                    if (!isCancelled) {
                      uploadDispatch({
                        type: ERROR_ACTION,
                        payload: { key: file.key }
                      })
                    }
                  }
                  if (!isCancelled) {
                    uploadDispatch({
                      type: COMPLETE_ACTION,
                      payload: { key: file.key }
                    })
                  }
                }
              }
            }
          )
          await Promise.all(proms)
        }
        getShizzle(batch)
      }
    }
  }, [uploadState, s3, bucketName])

  const handleFileChange = useCallback(
    async (files: File[], keyPrefix?: string) => {
      const withKeys = files.map(x => {
        return {
          name: x.name,
          key: `${keyPrefix || ''}${x.name.toLowerCase()}`,
          file: x
        }
      })
      uploadDispatch({
        type: ADD_ACTION,
        payload: { newFiles: withKeys }
      })
    },
    []
  )

  return {
    fileUploadCallback: handleFileChange,
    uploadState: uploadState.files,
    queuCount: uploadState.queue.size,
    inProgress: uploadState.inProgress,
    inProgressCount: uploadState.inProgress.size,
    removeCompleted: (keys: string[]) => {
      uploadDispatch({
        type: REMOVE,
        payload: { keys }
      })
    }
  }
}
