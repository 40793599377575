import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { ScrollSync } from 'react-scroll-sync'
import { TaskArtefact } from './tasks/task-artefact'

export interface ArtefactViewerProps {
  artefactName: string
  artefactKey: string
  isOpen: boolean
  showAllPages?: boolean
  toggle: () => void
  onChange?: (newValue: number) => void
}

export const ArtefactViewer: React.FC<ArtefactViewerProps> = ({
  isOpen,
  toggle,
  artefactKey
}) => {
  return (
    <>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={toggle}
        className="modal-dialog-auto my-0 "
        modalClassName="modal-auto"
        contentClassName="border-0 rounded-0 h-100"
      >
        <ModalBody className="d-flex flex-column flex-fill overflow-hidden p-0">
          <ScrollSync>
            <TaskArtefact
              isExpanded
              onClose={toggle}
              artefactKey={artefactKey}
              retrievePending={false}
              choosePending={false}
              onLoaded={() => {}}
            />
          </ScrollSync>
        </ModalBody>
      </Modal>
    </>
  )
}
