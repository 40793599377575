import React, {
  useState,
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react'

import { Input, FormFeedback, InputProps } from 'reactstrap'

interface ValidatedInputProps extends InputProps {
  checkIsValid: (value: string | number | string[] | undefined) => boolean
  validationMessage: string

  onSubmit?: () => void
}
export interface ValAndSumbit {
  value: string | number | string[] | undefined
  onSubmit?: () => void
  className?: string
}
export interface CheckValid {
  focus: () => void
  checkAndSetValid: () => boolean
}

export const ValidatedInput: React.FC<ValidatedInputProps> = forwardRef(
  (
    { checkIsValid, value, validationMessage, onChange, onSubmit, ...rest },
    ref
  ) => {
    const [isValid, setIsValid] = useState(true)
    const inputRef = useRef<HTMLInputElement>(null)
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus()
        }
      },
      checkAndSetValid() {
        const newValid = checkIsValid(value)
        setIsValid(newValid)
        return newValid
      }
    }))
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      if (!isValid) {
        setIsValid(checkIsValid(event.target.value))
      }
      if (onChange) {
        onChange(event)
      }
    }
    return (
      <>
        <Input
          {...rest}
          innerRef={inputRef}
          onChange={handleInputChange}
          invalid={!isValid}
          value={value}
          onKeyPress={event => {
            if (event.key === 'Enter' || event.charCode === 13) {
              event.preventDefault()
              const isValidNow = checkIsValid(value)
              setIsValid(isValidNow)
              if (isValidNow && onSubmit) {
                onSubmit()
              }
            }
          }}
        />
        <FormFeedback className="mt-2 px-3 text-left">
          {validationMessage}
        </FormFeedback>
      </>
    )
  }
)
