import React, { FC } from 'react'
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd'
import { Row, Col } from 'reactstrap'

interface DropProps {
  index: number
  moveItem: (id: string, text: string, to: number) => void
  findItem: (
    id: string
  ) => { index: number; card: { id: string; text: string | undefined } }
  onBegin: () => void
  onEnd: (didDrop: boolean) => void
  itemSelect: (itemText: string) => void
  isSelected: boolean
  id: string
  text: string | undefined
}
interface Item {
  type: string
  id: string
  text: string
  originalIndex: string
}

export const DropArtefact: FC<DropProps> = ({
  id,
  text,
  index,
  moveItem,
  findItem,
  onBegin,
  onEnd,
  itemSelect,
  isSelected
}) => {
  const originalIndex = findItem(id).index
  const [{ isDragging, canDrag }, drag] = useDrag({
    item: { type: 'ARTEFACT', id, originalIndex },
    begin: () => onBegin(),
    isDragging: (monitor: DragSourceMonitor): boolean => {
      return id === monitor.getItem().id
    },
    end: (
      dropResult: DragSourceMonitor | undefined,
      monitor: DragSourceMonitor
    ) => onEnd(monitor.didDrop()),
    canDrag: () => text !== undefined,

    collect: monitor => ({
      isDragging: monitor.isDragging(),

      canDrag: monitor.canDrag()
    })
  })
  const [{ isOver }, drop] = useDrop({
    accept: 'ARTEFACT',
    drop() {},
    hover({ id: draggedId, text: draggedText }: Item) {
      const { card: draggedCard } = findItem(draggedId)
      const { index: overIndex } = findItem(id)

      if (draggedId !== id || draggedCard.text === undefined) {
        moveItem(draggedId, draggedText, overIndex)
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  })

  return (
    <div ref={drop} data-testid={`drop-target-${index + 1}`}>
      <Row className="border-bottom mx-0 align-items-center">
        <Col
          xs={8}
          className="text-center py-3 font-weight-bold font-larger noselect"
        >
          {index + 1}
        </Col>
        <Col className="text-center py-3 font-weight-bold font-larger">
          <div
            ref={drag}
            role="button"
            data-testid={`drag-item-${text}`}
            tabIndex={0}
            onClick={() => {
              if (text) {
                itemSelect(text)
              }
            }}
            style={{ width: '44px', height: '44px' }}
            className={` ${
              !text || (isDragging && !isOver) ? ' input-focus' : ''
            } ${canDrag ? 'draggable' : ''}  ${
              isDragging && isOver ? 'bg-primary opacity-37' : ''
            } 
            ${
              !isDragging && text && !isSelected ? 'bg-ddd text-secondary' : ''
            } 
            ${!isDragging && text && isSelected ? 'bg-primary text-white' : ''} 
            d-flex align-items-center justify-content-center  font-weight-bold rounded  `}
          >
            {!isDragging && <>{text}</>}
          </div>
        </Col>
      </Row>
    </div>
  )
}
