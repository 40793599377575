import React, { FC, useState, useMemo, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { faCheck, faTrash } from '@fortawesome/pro-solid-svg-icons'
import useReactRouter from 'use-react-router'
import { Button, Spinner, ModalProps } from 'reactstrap'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import { CJErrorModal } from '../modals/simple-error-modal'
import { SimpleModalMessage } from '../shared/modal/subcomponents/modal-message'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'
import { useConfig } from '../../use-remote-config'
import { useGetBearerToken } from '../use-get-bearer-token'
import { Project, PathAndTabParam } from '../../types'

interface DeleteModalProps {
  projectId: string
}

export const ProjectDeleteModal: FC<ModalProps & DeleteModalProps> = ({
  isOpen,
  toggle,
  projectId
}) => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const { history } = useReactRouter<PathAndTabParam>()

  const getUrl = `${config.apiUrl}/projects/${projectId}`
  const deleteProjectMemo = useMemo(() => {
    return {
      url: getUrl,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])

  const deleteProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    deleteProjectMemo
  )

  const [deleteErrorCleared, setDeleteErrorCleared] = useState(false)
  useEffect(() => {
    if (deleteProjectTask.error) {
      setDeleteErrorCleared(false)
    }
  }, [deleteProjectTask.error])

  return (
    <CJModal size="sm" centered isOpen={isOpen} toggle={toggle}>
      <CJModalHeader
        className={deleteProjectTask.result ? 'bg-primary' : 'bg-danger'}
      >
        <FontAwesomeIcon
          color={deleteProjectTask.result ? 'white' : 'white'}
          icon={deleteProjectTask.result ? faCheck : faTrash}
          size="lg"
          className="my-2"
        />
      </CJModalHeader>
      <CJModalBody>
        <CJErrorModal
          isOpen={deleteProjectTask.error !== null && !deleteErrorCleared}
          onDismiss={() => {
            setDeleteErrorCleared(true)
          }}
          title="Failed to delete project"
        />
        {deleteProjectTask.result && (
          <SimpleModalMessage
            title="Project is successfully deleted"
            message="You can always create a new project from your admin dashboard"
          />
        )}
        {!deleteProjectTask.result && (
          <SimpleModalMessage
            title="Do you want to delete this draft project?"
            message="If you delete this project, all artefacts and details will deleted from the system"
          />
        )}
      </CJModalBody>
      <CJModalFooter>
        {toggle !== undefined && !deleteProjectTask.result && (
          <Button color="ddd" outline onClick={toggle}>
            Cancel
          </Button>
        )}

        <Button
          disabled={deleteProjectTask.started && deleteProjectTask.pending}
          color={deleteProjectTask.result !== null ? 'primary' : 'danger'}
          outline={deleteProjectTask.result !== null}
          onClick={() => {
            if (!deleteProjectTask.result) {
              deleteProjectTask.start()
            } else {
              history.push('/')
            }
          }}
        >
          {deleteProjectTask.started && deleteProjectTask.pending && (
            <Spinner color="white" size="sm" />
          )}
          {(!deleteProjectTask.started ||
            (deleteProjectTask.started && !deleteProjectTask.pending)) &&
            !deleteProjectTask.result &&
            'Delete'}
          {(!deleteProjectTask.started ||
            (deleteProjectTask.started && !deleteProjectTask.pending)) &&
            deleteProjectTask.result !== null &&
            'Go to Project List'}
        </Button>
      </CJModalFooter>
    </CJModal>
  )
}
