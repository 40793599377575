import { useMemo, useState, useEffect, useCallback } from 'react'
import {
  useAsyncRun,
  useAsyncTaskAxios,
  useAsyncTaskTimeout
} from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'

import { differenceInMilliseconds } from 'date-fns'
import { useGetBearerToken } from './use-get-bearer-token'
import { useConfig } from '../use-remote-config'
import { Credentials } from '../types'

export const useGetS3UploadToken = ({
  autoStart = true
}: {
  autoStart?: boolean
}) => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()
  const [delay, setDelay] = useState(0)
  const [expiredTokenCount, setExpiredTokenCount] = useState(0)

  const getS3TokenMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/artefacts/getToken`,
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [config.apiUrl, bearerToken, expiredTokenCount])

  const getS3TokenTask = useAsyncTaskAxios<AxiosResponse<Credentials>>(
    axios,
    getS3TokenMemo
  )
  const timeout = useAsyncTaskTimeout(
    useCallback(() => {
      setExpiredTokenCount(expiredTokenCount + 1)
    }, [getS3TokenTask.result, delay]),
    delay
  )
  useEffect(() => {
    if (getS3TokenTask.result) {
      const diff = differenceInMilliseconds(
        getS3TokenTask.result.data.expiration,
        new Date()
      )
      setDelay(diff)
    }
  }, [getS3TokenTask.result])
  useAsyncRun(autoStart && bearerToken && getS3TokenTask)
  useAsyncRun(delay > 0 && timeout)

  return getS3TokenTask
}
