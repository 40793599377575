import React from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'

interface TabContentLabelWithCountProps {
  isActive?: boolean
  countPending: boolean
  label: string
  icon: IconDefinition
  count: number
}

export const TabContentLabelWithCount: React.FC<
  TabContentLabelWithCountProps
> = ({ isActive = false, label, icon, countPending, count }): JSX.Element => {
  return (
    <div
      className={`${
        isActive ? 'bg-primary text-white' : 'bg-lightButNotTooLight text-body'
      } rounded  font-weight-bold py-25 px-35 position-relative`}
    >
      <Row className="align-items-center">
        <Col className="pr-5">
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {label}
        </Col>
        <Col xs="auto" className="text-right h5 mb-0 font-weight-bold d-flex">
          {countPending && (
            <Spinner
              className="font-normal"
              color={isActive ? 'white' : 'secondary'}
              size="sm"
            />
          )}
          {!countPending && <span>{count}</span>}
        </Col>
      </Row>
    </div>
  )
}
