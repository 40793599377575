import React, { useMemo } from 'react'
import { AxiosResponse, AxiosError } from 'axios'
import { AsyncTask } from 'react-hooks-async'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilRuler } from '@fortawesome/pro-solid-svg-icons'

import { PlanSummary } from '../../types'
import { Loading } from '../loading'

import { SimpleMessage } from '../shared/simple-message/simple-message'
import { SimpleErrorMessage } from '../shared/simple-message/simple-error-message'
import { ProjectPlan } from './project-plan'

import { isNotFound } from '../../helpers/axios-error-helpers'

interface PlanViewProps {
  planTask: AsyncTask<AxiosResponse<PlanSummary>>
  planUploaded: boolean
  projectId: string
  targetPacksPerArtefact?: number
  onRecreate?: () => void
}

export const PlanView = ({
  planTask,
  planUploaded,
  targetPacksPerArtefact,
  projectId,
  onRecreate
}: PlanViewProps): JSX.Element => {
  const planNotFound = useMemo(() => {
    if (!planTask.error) {
      return false
    }
    return isNotFound((planTask.error as AxiosError).response)
  }, [planTask.error])

  return (
    <>
      {planTask.pending && (
        <SimpleMessage
          className="mb-5"
          icon={<Loading className="d-block mx-auto mt-7" />}
          title="Retrieving plan..."
        />
      )}

      {!planTask.pending && (
        <>
          {planTask.error && !planNotFound && (
            <SimpleErrorMessage
              className="mb-5"
              title=" Failed to get project plan"
              message="Please refresh the page, and if the problem persists contact your system administrator."
              allowPageRefresh
            />
          )}

          {planNotFound && (
            <SimpleMessage
              className="mb-5"
              title="You haven't created a plan yet."
              message='To generate a plan hit the "Create Plan" button above'
              icon={<FontAwesomeIcon icon={faPencilRuler} size="2x" />}
            />
          )}
          {planTask.result && (
            <ProjectPlan
              planUploaded={planUploaded}
              projectId={projectId}
              targetPacksPerArtefact={targetPacksPerArtefact}
              plan={planTask.result.data}
              onRecreate={
                onRecreate
                  ? () => {
                      onRecreate()
                    }
                  : undefined
              }
            />
          )}
        </>
      )}
    </>
  )
}
