import produce from 'immer'
import { UploadingFile, UploadAction, S3File } from '../types'

export const ADD_ACTION = 'add'
export const PROGRESS_ACTION = 'progress'
export const COMPLETE_ACTION = 'complete'
export const ERROR_ACTION = 'error'
export const START_DOWNLOADING = 'start'
export const REMOVE = 'remove'

export interface UploadingState {
  files: Map<string, UploadingFile>
  queue: Set<string>
  inProgress: Set<string>
}
export const uploadingReducer = (
  state: UploadingState,
  action: UploadAction
): UploadingState => {
  return produce(
    state,
    (draft): UploadingState => {
      switch (action.type) {
        case ADD_ACTION: {
          if (action.payload.newFiles) {
            const toAddNew = new Map(
              (action.payload.newFiles || []).map((file: S3File): [
                string,
                UploadingFile
              ] => {
                return [
                  file.key,
                  {
                    name: file.name,
                    key: file.key,
                    file: file.file,
                    uploadingState: {
                      isLoading: false,
                      isComplete: false,
                      isError: false,
                      loaded: 0,
                      total: file.file.size
                    }
                  }
                ]
              })
            )
            draft.queue = new Set([
              ...action.payload.newFiles.map(x => x.key),
              ...draft.queue
            ])
            // this is a bit hacky we are adding toAddNew twice, once at start to ensure order is preserved and then again at the end to ensure that they replace any existing
            draft.files = new Map([...toAddNew, ...draft.files, ...toAddNew])
          }
          return draft
        }
        case START_DOWNLOADING: {
          if (action.payload.keys !== undefined) {
            if (action.payload.keys !== undefined) {
              action.payload.keys.forEach(x => {
                draft.queue.delete(x)
                draft.inProgress.add(x)

                const existing = draft.files.get(x)

                if (existing) {
                  const toReplaceWith = {
                    ...existing,
                    uploadingState: {
                      ...existing.uploadingState,
                      isLoading: true
                    }
                  }
                  draft.files.set(x, toReplaceWith)
                }
              })
            }
          }
          return draft
        }
        case PROGRESS_ACTION: {
          if (
            action.payload.key !== undefined &&
            action.payload.progress !== undefined
          ) {
            if (draft.files.has(action.payload.key)) {
              const existing = draft.files.get(action.payload.key)
              if (existing) {
                draft.files.set(action.payload.key, {
                  ...existing,
                  uploadingState: {
                    ...existing.uploadingState,
                    loaded: action.payload.progress.loaded,
                    total: action.payload.progress.total
                  }
                })
              }
            }
          }
          return draft
        }
        case COMPLETE_ACTION: {
          if (action.payload.key !== undefined) {
            if (draft.files.has(action.payload.key)) {
              draft.inProgress.delete(action.payload.key)
              const existing = draft.files.get(action.payload.key)

              if (existing) {
                draft.files.set(action.payload.key, {
                  ...existing,
                  uploadingState: {
                    ...existing.uploadingState,
                    isLoading: false,
                    isComplete: true,
                    loaded: existing.uploadingState.total
                  }
                })
              }
            }
          }
          return draft
        }
        case REMOVE: {
          if (action.payload.keys) {
            action.payload.keys.forEach(x => {
              draft.files.delete(x)
            })
          }
          return draft
        }
        case ERROR_ACTION: {
          if (action.payload.key !== undefined) {
            if (draft.files.has(action.payload.key)) {
              draft.inProgress.delete(action.payload.key)

              const existing = draft.files.get(action.payload.key)

              if (existing) {
                draft.files.set(action.payload.key, {
                  ...existing,
                  uploadingState: {
                    ...existing.uploadingState,
                    isLoading: false,
                    isError: true
                  }
                })
              }
            }
          }
          return draft
        }
        default:
          return draft
      }
    }
  )
}
