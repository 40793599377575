import React, { FC, useState, useEffect } from 'react'
import { ScrollSync } from 'react-scroll-sync'
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { useStopwatch } from 'react-timer-hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-solid-svg-icons'
import { cloneDeep, findIndex } from 'lodash'
import { TaskArtefact } from './task-artefact'

import { PlanTask } from '../../types'

export interface PairwiseTaskProps {
  task: PlanTask
  updatePending: boolean
  onChoose: (updatedTask: PlanTask) => void
}
export const PairwiseTask: FC<PairwiseTaskProps> = ({
  task,
  updatePending,
  onChoose
}): JSX.Element => {
  const [scrollTogether, setScrollTogether] = useState(false)
  const [expandedArtefact, setExpandedArtefact] = useState<string | undefined>(
    undefined
  )
  const [artefactLoadedCount, setArtefactLoadedCount] = useState(0)
  const { seconds, minutes, hours, days, start, pause, reset } = useStopwatch({
    autoStart: false
  })
  useEffect(() => {
    reset()
    setArtefactLoadedCount(0)
  }, [task])
  useEffect(() => {
    if (artefactLoadedCount === 2) {
      start()
    }
  }, [artefactLoadedCount])

  const choose = (chosenKey: string) => {
    pause()
    const timeOnTask = days * 24 * 3600 + hours * 3600 + minutes * 60 + seconds
    const cloned = cloneDeep(task)
    const winnerIndex = findIndex(cloned.artefacts, {
      artefactKey: chosenKey
    })
    cloned.artefacts[winnerIndex].rankOrder = 1
    const loserIndex = !winnerIndex ? 1 : 0
    cloned.artefacts[winnerIndex].timeOnArtefact = timeOnTask / 2
    cloned.artefacts[loserIndex].rankOrder = 2
    cloned.artefacts[loserIndex].timeOnArtefact = timeOnTask / 2

    cloned.taskTime = timeOnTask
    // now put the result
    cloned.artefacts[0].initialOrder = 'A'
    cloned.artefacts[1].initialOrder = 'B'
    onChoose(cloned)
  }

  return (
    <ScrollSync enabled={scrollTogether}>
      <div className="bigc d-flex flex-fill overflow-hidden">
        <Row className="mx-0 flex-fill  mw-100">
          <Col
            className={`${
              expandedArtefact &&
              expandedArtefact !== task.artefacts[0].artefactKey
                ? 'invisible col-0'
                : ''
            }  ${
              !expandedArtefact ? 'col-pairwise' : 'pr-0'
            } pl-0 h-100 d-flex flex-column`}
          >
            <TaskArtefact
              scrollSynced={scrollTogether}
              retrievePending={false}
              choosePending={updatePending}
              isExpanded={expandedArtefact === task.artefacts[0].artefactKey}
              onExpandChange={newVal => {
                if (newVal) {
                  setExpandedArtefact(task.artefacts[0].artefactKey)
                } else {
                  setExpandedArtefact(undefined)
                }
              }}
              artefactKey={task.artefacts[0].artefactKey}
              onChoose={() => choose(task.artefacts[0].artefactKey)}
              onLoaded={() => {
                setArtefactLoadedCount(artefactLoadedCount + 1)
              }}
            />
          </Col>

          <Col
            xs="auto"
            className={`${
              expandedArtefact !== undefined ? 'invisible col-0 p-0' : ''
            } text-center pt-3`}
          >
            <Button
              id="ScrollTogether"
              className="px-35"
              color={scrollTogether ? 'primary' : 'white'}
              onClick={() => setScrollTogether(!scrollTogether)}
            >
              <FontAwesomeIcon className="font-normal" icon={faLink} />
            </Button>
            <UncontrolledTooltip placement="bottom" target="ScrollTogether">
              Scroll together
            </UncontrolledTooltip>
          </Col>

          <Col
            className={`${
              expandedArtefact &&
              expandedArtefact !== task.artefacts[1].artefactKey
                ? 'invisible col-0'
                : ''
            }  ${
              !expandedArtefact ? 'col-pairwise' : 'pl-0'
            } pr-0 h-100 d-flex flex-column`}
          >
            <TaskArtefact
              scrollSynced={scrollTogether}
              artefactKey={task.artefacts[1].artefactKey}
              retrievePending={false}
              choosePending={updatePending}
              isExpanded={expandedArtefact === task.artefacts[1].artefactKey}
              onExpandChange={newVal => {
                if (newVal) {
                  setExpandedArtefact(task.artefacts[1].artefactKey)
                } else {
                  setExpandedArtefact(undefined)
                }
              }}
              onChoose={() => choose(task.artefacts[1].artefactKey)}
              onLoaded={() => {
                setArtefactLoadedCount(artefactLoadedCount + 1)
              }}
            />
          </Col>
        </Row>
      </div>
    </ScrollSync>
  )
}
