import { useCallback } from 'react'
import { useAsyncTask, useAsyncRun } from 'react-hooks-async'
import { useAuth0 } from '../Auth/auth'

export const useGetBearerToken = (): string | undefined => {
  const { getTokenSilently } = useAuth0()
  const getToken = useCallback(() => getTokenSilently(), [getTokenSilently])

  const getTokenTask = useAsyncTask(getToken)
  useAsyncRun(getTokenTask)
  return getTokenTask.result ? getTokenTask.result : undefined
}
