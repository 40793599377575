import React, { FC, useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import { SimpleModalMessage } from '../shared/modal/subcomponents/modal-message'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'
import { MemoizedCJErrorModal } from '../modals/simple-error-modal'

interface DeleteCategoryProps {
  isDeleting: boolean
  onDelete: () => void
  deleteError: Error | null
}

export const DeleteCategoryButton: FC<DeleteCategoryProps> = ({
  isDeleting,
  onDelete,
  deleteError
}): JSX.Element => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteErrorCleared, setDeleteErrorCleared] = useState(false)

  const toggle = () => {
    setDeleteModal(!deleteModal)
  }
  useEffect(() => {
    if (deleteError !== null) {
      setDeleteErrorCleared(false)
    }
  }, [deleteError])
  return (
    <>
      <Button
        color="link"
        data-testid="btn-deleteall"
        className="ml-35 px-0 text-dark"
        onClick={() => setDeleteModal(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <CJModal centered isOpen={deleteModal} toggle={toggle}>
        <CJModalHeader className="bg-ddd">
          <FontAwesomeIcon icon={faTrash} size="lg" className="my-2" />
        </CJModalHeader>
        <CJModalBody>
          <MemoizedCJErrorModal
            isOpen={deleteError !== null && !deleteErrorCleared}
            onDismiss={() => setDeleteErrorCleared(true)}
            title="Failed to delete artefacts"
          />
          <SimpleModalMessage
            title="Do you want to delete all artefacts in the category?"
            message="If you delete these artefacts, and want to use them later then you need to upload them again."
          />
        </CJModalBody>
        <CJModalFooter>
          {toggle !== undefined && !isDeleting && (
            <Button color="ddd" className="mr-2" outline onClick={toggle}>
              Cancel
            </Button>
          )}

          <Button
            color="ddd"
            className="ml-2"
            disabled={isDeleting}
            onClick={() => {
              onDelete()
            }}
          >
            {isDeleting && (
              <Spinner size="sm" role="progressbar" color="secondary" />
            )}
            {!isDeleting && 'Delete'}
          </Button>
        </CJModalFooter>
      </CJModal>
    </>
  )
}
