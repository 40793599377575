import React from 'react'

import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import { faCircle, faEnvelope } from '@fortawesome/pro-solid-svg-icons'

export type SizeProp =
  | 'xs'
  | 'lg'
  | 'sm'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'

export const FaCircleEnvelope: React.FC<{ size?: SizeProp }> = ({
  size
}): JSX.Element => (
  <span style={{ width: '32px' }} className="fa-layers">
    <FontAwesomeIcon size={size} className="text-ddd" icon={faCircle} />
    <FontAwesomeIcon
      size={size}
      className="text-secondary"
      icon={faEnvelope}
      transform="shrink-9"
    />
  </span>
)
