import React, { FC } from 'react'
import { Col, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'

export interface TabProps {
  to: string
  autoSize?: boolean
}

export const Tab: FC<TabProps & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  to,
  autoSize,
  className
}): JSX.Element => {
  return (
    <Col xs={autoSize ? 'auto' : undefined} className={`${className} mt-3`}>
      <NavLink tag={Link} className="p-0" to={to}>
        {children}
      </NavLink>
    </Col>
  )
}
