import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { isEqual } from 'lodash'
import axios, { AxiosResponse } from 'axios'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { Button, Spinner, Row, Col, UncontrolledTooltip } from 'reactstrap'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeaderEdit } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import { MemoizedCJErrorModal } from '../modals/simple-error-modal'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'
import { NameInput } from '../wizard-steps/name-step'
import { BasisInput } from '../wizard-steps/basis-step'
import { DateRadio, CJCalendar } from '../wizard-steps/date-step'
import { MethodChooser } from '../wizard-steps/method-chooser'
import { CJModalAside } from '../shared/modal/subcomponents/modal-aside'
import {
  MethodParamaters,
  sanitizeArtefactViewCount
} from '../wizard-steps/method-paramaters'
import { useConfig } from '../../use-remote-config'
import { useGetBearerToken } from '../use-get-bearer-token'
import { Project, ProjectData } from '../../types'
import { CheckValid } from '../wizard-steps/validated-input'
import { useDatePickerState } from '../use-date-picker-state'

type EditFields = 'NAME' | 'QUESTION' | 'METHOD' | 'DATE'

export interface EditModalProps {
  project: Project
  onProjectUpdate: (updated: Project) => void
  showEditModal?: EditFields | undefined
  toggle?: () => void
}

export const ProjectEditModal: FC<EditModalProps> = ({
  project,
  toggle,
  showEditModal,
  onProjectUpdate
}) => {
  const { config } = useConfig()
  const bearerToken = useGetBearerToken()

  const [draftProject, setDraftProject] = useState<ProjectData>(project)
  const getUrl = `${config.apiUrl}/projects/${project.id}`

  const updateProjectMemo = useMemo(() => {
    return {
      url: getUrl,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    }
  }, [getUrl, bearerToken])

  const updateProjectTask = useAsyncTaskAxios<AxiosResponse<Project>>(
    axios,
    updateProjectMemo
  )

  const changePackSize = (newVal: number): void => {
    setDraftProject({
      ...draftProject,
      packSize: newVal
    })
  }

  useEffect(() => {
    if (updateProjectTask.result && onProjectUpdate) {
      onProjectUpdate(updateProjectTask.result.data)
      if (toggle) {
        toggle()
      }
    }
  }, [updateProjectTask.result])

  const [editErrorCleared, setEditErrorCleared] = useState(false)
  const getEditModalCols = () => {
    if (showEditModal === 'DATE') {
      return 12
    }
    if (showEditModal === 'METHOD') {
      return 13
    }

    return 24
  }
  const nameFieldRef = useRef<CheckValid>(null)
  const questionFieldRef = useRef<CheckValid>(null)
  const {
    chooseDate,
    setDateValid,
    dateValid,
    handleChangeRadio,
    minDate,
    maxDate,
    handleDateChange
  } = useDatePickerState(draftProject.endDate, newDate => {
    setDraftProject({ ...draftProject, endDate: newDate })
  })
  const editChanges = useMemo(() => {
    const same = isEqual(draftProject, project)
    return !same
  }, [draftProject, project])
  useEffect(() => {
    setDraftProject(project)
  }, [project])

  const undoOnToggle = useCallback(() => {
    setDraftProject(project)
    if (toggle) {
      toggle()
    }
  }, [toggle])
  return (
    <>
      <CJModal
        centered
        size={
          showEditModal === 'NAME' ||
          showEditModal === 'QUESTION' ||
          showEditModal === undefined
            ? 'sm'
            : 'lg'
        }
        toggle={undoOnToggle}
        isOpen={showEditModal !== undefined}
      >
        <Row>
          <Col
            xs={24}
            lg={getEditModalCols()}
            className="rounded pr-lg-4 d-flex flex-column mb-4"
          >
            <CJModalHeaderEdit />
            <CJModalBody>
              <MemoizedCJErrorModal
                isOpen={updateProjectTask.error !== null && !editErrorCleared}
                onDismiss={() => setEditErrorCleared(true)}
                title="Failed to update project"
              />

              {showEditModal === 'NAME' && (
                <>
                  <div className="mb-4">
                    <b>Edit: </b>
                    Project's Name
                  </div>
                  <NameInput
                    ref={nameFieldRef}
                    value={draftProject.name}
                    onChange={newVal => {
                      setDraftProject({
                        ...draftProject,
                        name: newVal.name
                      })
                    }}
                  />
                </>
              )}

              {showEditModal === 'QUESTION' && (
                <>
                  <div className="mb-4">
                    <b>Edit: </b>
                    Judgement Question
                  </div>
                  <BasisInput
                    className="mb-4"
                    ref={questionFieldRef}
                    value={draftProject.basis}
                    onChange={newVal => {
                      setDraftProject({
                        ...draftProject,
                        basis: newVal.basis
                      })
                    }}
                  />
                </>
              )}
              {showEditModal === 'DATE' && (
                <>
                  <div className="mb-4 text-center">
                    <b>Edit: </b>
                    Due Date
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center  text-left">
                    <DateRadio
                      chooseDate={chooseDate}
                      handleChangeRadio={handleChangeRadio}
                    />
                  </div>
                </>
              )}
              {showEditModal === 'METHOD' && (
                <>
                  <div className="mb-4 text-center">
                    <b>Edit: </b>
                    Method
                  </div>
                  <div className="flex-grow-1 d-flex align-items-center">
                    <MethodChooser
                      tabIndex={0}
                      value={draftProject.packSize}
                      onChange={changePackSize}
                    />
                  </div>
                </>
              )}
            </CJModalBody>
            <CJModalFooter>
              <Button color="ddd" outline onClick={undoOnToggle}>
                Cancel
              </Button>
              <Button
                disabled={
                  updateProjectTask.started && updateProjectTask.pending
                }
                className="position-relative"
                color="primary"
                onClick={event => {
                  if (!editChanges && toggle) {
                    toggle()
                  } else {
                    switch (showEditModal) {
                      case 'NAME':
                        if (
                          nameFieldRef &&
                          nameFieldRef.current &&
                          nameFieldRef.current.checkAndSetValid()
                        ) {
                          updateProjectTask.start({
                            data: draftProject
                          })
                        }
                        break
                      case 'QUESTION':
                        if (
                          questionFieldRef &&
                          questionFieldRef.current &&
                          questionFieldRef.current.checkAndSetValid()
                        ) {
                          updateProjectTask.start({
                            data: draftProject
                          })
                        }
                        break
                      case 'DATE': {
                        const checkDateValid =
                          !chooseDate || draftProject.endDate !== undefined
                        setDateValid(checkDateValid)
                        if (checkDateValid) {
                          updateProjectTask.start({
                            data: draftProject
                          })
                        }
                        break
                      }
                      case 'METHOD':
                        if (
                          draftProject.packSize &&
                          draftProject.packSize >= 2 &&
                          draftProject.packSize <= 10 &&
                          draftProject.packsPerArtefact &&
                          draftProject.packsPerArtefact > 1 &&
                          draftProject.packsPerArtefact <= 50
                        )
                          updateProjectTask.start({
                            data: draftProject
                          })
                        break
                      default:
                    }
                  }
                }}
              >
                {editChanges && (
                  <span
                    aria-label="Changes made"
                    className="fa-layers fa-fw  position-absolute"
                    id="edit-button-span"
                    style={{
                      right: '-.625rem',
                      top: '-0.5rem'
                    }}
                  >
                    <FontAwesomeIcon className="text-danger" icon={faCircle} />

                    <UncontrolledTooltip
                      placement="top"
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      target="edit-button-span"
                    >
                      <div className="px-35 py-3">You've made some changes</div>
                    </UncontrolledTooltip>
                  </span>
                )}

                {updateProjectTask.started && updateProjectTask.pending && (
                  <Spinner color="white" size="sm" />
                )}
                {(!updateProjectTask.started ||
                  (updateProjectTask.started && !updateProjectTask.pending)) &&
                  'Save changes'}
              </Button>
            </CJModalFooter>
          </Col>
          {showEditModal === 'DATE' && (
            <Col className="d-flex pl-lg-4 mb-4">
              <CJModalAside>
                <CJCalendar
                  disabled={!chooseDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={draftProject.endDate}
                  dateValid={dateValid}
                  handleDateChange={handleDateChange}
                />
              </CJModalAside>
            </Col>
          )}
          {showEditModal === 'METHOD' && (
            <Col className="d-flex mb-4 pl-lg-4">
              <CJModalAside>
                <MethodParamaters
                  artefactViewCount={draftProject.packsPerArtefact}
                  packSize={draftProject.packSize}
                  handleChangeArtefactViewCount={newVal => {
                    const toUpdate = sanitizeArtefactViewCount(newVal)

                    setDraftProject({
                      ...draftProject,
                      packsPerArtefact: toUpdate
                    })
                  }}
                  handleChangePackSize={changePackSize}
                />
              </CJModalAside>
            </Col>
          )}
        </Row>
      </CJModal>
    </>
  )
}
