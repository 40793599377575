import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faBell } from '@fortawesome/pro-solid-svg-icons'

export const FaCircleBell: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className
}): JSX.Element => (
  <span className={`${className} fa-layers fa-fw`}>
    <FontAwesomeIcon className="text-danger" icon={faCircle} />
    <FontAwesomeIcon
      className="text-white"
      icon={faBell}
      transform="shrink-9"
    />
  </span>
)
