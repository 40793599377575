import { useState, ChangeEvent } from 'react'
import { addYears } from 'date-fns'

export interface DatePickerState {
  minDate: Date | undefined
  maxDate: Date | undefined
  dateValid: boolean
  setDateValid: (isValid: boolean) => void
  chooseDate: boolean
  setChooseDate: (newChooseDate: boolean) => void
  handleChangeRadio: (event: ChangeEvent<HTMLInputElement>) => void
  handleDateChange: (newDate: Date | Date[]) => void
}

export const useDatePickerState = (
  date: Date | undefined,
  onChange: (newDate: Date | undefined) => void
): DatePickerState => {
  const [chooseDate, setChooseDate] = useState(date !== undefined)
  const [dateValid, setDateValid] = useState(true)
  const minDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )
  const maxDate = addYears(minDate, 5)
  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = parseInt(event.target.value, 10)
    setChooseDate(newValue > 0)
    if (newValue === 0) {
      if (onChange) {
        onChange(undefined)
      }
    }
  }
  const handleDateChange = (newDate: Date | Date[]): void => {
    let fixedDate = newDate as Date
    if (newDate && newDate < minDate) {
      fixedDate = minDate
    }
    if (newDate && newDate > maxDate) {
      fixedDate = maxDate
    }
    if (!dateValid) {
      setDateValid(newDate !== null)
    }

    if (onChange) {
      onChange(fixedDate)
    }
  }

  return {
    dateValid,
    setDateValid,
    handleChangeRadio,
    handleDateChange,
    minDate,
    maxDate,
    chooseDate,
    setChooseDate
  }
}
