import React from 'react'

import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers, faLock } from '@fortawesome/pro-solid-svg-icons'

import { getMethodText } from '../helpers/project-type-helpers'
import { Project } from '../types'

export const NameAndMethod: React.FC<Project> = ({ ...project }) => {
  return (
    <Row className="align-items-center">
      <Col xs="auto">
        <h3
          className={`h5 mb-0 font-weight-bold ${
            project.isPaused ? 'text-dark' : 'text-primary'
          }`}
        >
          {project.name}
        </h3>
        <div className="font-larger font-weight-bold text-secondary">
          {getMethodText(project.packSize)}
        </div>
      </Col>
      <Col>
        {project.isPaused && (
          <FontAwesomeIcon className="text-dark ml-2" icon={faLock} />
        )}
        {project.userRole === 'OWNER' && (
          <FontAwesomeIcon className="text-primary ml-2" icon={faUser} />
        )}
        {project.userRole === 'ADMIN' && (
          <FontAwesomeIcon
            className="text-primary ml-2 font-larger"
            icon={faUsers}
          />
        )}
      </Col>
    </Row>
  )
}
