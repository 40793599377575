import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel, faScroll } from '@fortawesome/pro-solid-svg-icons'
import { keys } from 'lodash'
import { PlanTopLine } from '../../types'
import {
  getMethodText,
  getMethodIcon
} from '../../helpers/project-type-helpers'
import { CountBox } from '../shared/count-box/count-box'
import { CountRow } from '../shared/count-box/subcomponents/count-row'
import { InlineErrorMessage } from '../shared/simple-message/inline-error-message'
import { Jiggler } from '../jiggler'

export const PlanTopLineView: React.FunctionComponent<PlanTopLine> = ({
  packSizes,
  noJudges,
  noArtefacts,
  toggleCheckError,
  catCounts
}) => {
  const packSubtitle = useMemo(() => {
    if (packSizes[0] === 2) {
      return 'Choosing one from two artefacts'
    }
    return 'Putting the number of artefacts into an order'
  }, [packSizes])
  const countBox = (
    <CountBox
      className={` ${
        Object.keys(catCounts).length > 3 ? 'bs-danger' : ''
      } mb-45`}
      title="Artefacts"
      count={noArtefacts}
      icon={<FontAwesomeIcon icon={faScroll} />}
    >
      {keys(catCounts || []).map(x => (
        <CountRow key={x} title={x} count={catCounts[x]} />
      ))}
    </CountBox>
  )
  return (
    <>
      {packSizes.length === 1 && (
        <CountBox
          className="mb-45"
          title={getMethodText(packSizes[0])}
          subTitle={packSubtitle}
          icon={
            <img
              src={getMethodIcon(packSizes[0])}
              alt={getMethodText(packSizes[0])}
            />
          }
        >
          <CountRow title="Pack Size" count={packSizes[0]} />
        </CountBox>
      )}
      {packSizes.length > 1 && (
        <Jiggler toggle={toggleCheckError}>
          <InlineErrorMessage
            className="mb-45"
            title="Multiple pack sizes detected"
            message="Please amend your plan such that all packs are the same size"
          />
        </Jiggler>
      )}

      <CountBox
        className="mb-45"
        title="Judges"
        count={noJudges}
        icon={<FontAwesomeIcon icon={faGavel} />}
      />
      {Object.keys(catCounts).length > 3 && (
        <>
          <div className="text-center text-danger font-weight-bold mb-3">
            You should have maximum 3 categories
          </div>
          <Jiggler toggle={toggleCheckError}>{countBox}</Jiggler>
        </>
      )}
      {Object.keys(catCounts).length <= 3 && <>{countBox}</>}
    </>
  )
}
