import React from 'react'
import { Row, Col } from 'reactstrap'

interface PlanWidgetTipProps {
  idealTip: string
  otherTip: string
}

export const PlanWidgetTip: React.FC<PlanWidgetTipProps> = ({
  idealTip,
  otherTip
}): JSX.Element => (
  <Row className="h-100 no-gutters">
    <Col className="px-4 py-35">
      <ul className="pl-4 mb-4 bullet-list">
        <li className="mb-2 bullet-primary">{idealTip}</li>
        <li>{otherTip}</li>
      </ul>
    </Col>
  </Row>
)
