import produce from 'immer'
import { ArtefactView, DraftArtefactView } from '../types'
import {
  buildKeyFromParts,
  getCatFromKey
} from '../helpers/artefact-key-helpers'

import { NOCAT } from '../components/use-artefact-manager-new'

export type ArtefactAction =
  | { type: 'refresh'; artefacts: ArtefactView[] }
  | {
      type: 'add_to_queue'
      newFiles: File[]
      projectId: string
      category?: string
    }
  | { type: 'start_upload'; keys: string[] }
  | { type: 'progress_upload'; key: string; progress: number }
  | { type: 'complete_upload'; key: string }
  | { type: 'upload_error'; key: string; error: Error }
  | { type: 'complete_processing'; artefact: DraftArtefactView }
  | { type: 'remove_start'; key: string }
  | { type: 'remove_complete'; key: string }
  | { type: 'remove_error'; key: string }

export interface DraftArtefactState {
  artefactMap: Map<string, DraftArtefactView>
  categoryMap: Map<string, Set<string>>
  categoryCompleted: Map<string, Set<string>>
  cagtegoryUploading: Map<string, Set<string>>
  queued: Set<string>
  inProgress: Set<string>
}
export const artefactReducer = (
  state: DraftArtefactState,
  action: ArtefactAction
): DraftArtefactState => {
  return produce(state, draft => {
    switch (action.type) {
      case 'refresh': {
        draft.artefactMap = new Map([
          ...draft.artefactMap,
          ...new Map(action.artefacts.map(x => [x.key, x]))
        ])

        draft.categoryMap = new Map<string, Set<string>>()
        draft.cagtegoryUploading = new Map<string, Set<string>>()
        draft.categoryCompleted = new Map<string, Set<string>>()
        draft.inProgress = new Set<string>()
        draft.queued = new Set<string>()
        draft.artefactMap.forEach((val, key) => {
          draft.categoryMap.set(
            val.category || NOCAT,
            new Set([
              ...(draft.categoryMap.get(val.category || NOCAT) || []),
              ...[key]
            ])
          )
          draft.categoryCompleted.set(
            val.category || NOCAT,
            new Set([
              ...(draft.categoryCompleted.get(val.category || NOCAT) || []),
              ...[key]
            ])
          )
        })

        return draft
      }
      case 'add_to_queue': {
        const mappedNew: Map<string, DraftArtefactView> = new Map(
          action.newFiles.map(x => {
            const key = buildKeyFromParts(
              action.projectId,
              x.name,
              action.category
            )

            return [
              key,
              {
                name: x.name,
                key: buildKeyFromParts(
                  action.projectId,
                  x.name,
                  action.category
                ),
                category: action.category,
                file: x,
                uploadingState: {
                  isLoading: false,
                  isComplete: false,
                  loaded: 0,
                  total: x.size,
                  isError: false
                }
              }
            ]
          })
        )

        draft.categoryMap.set(
          action.category || NOCAT,
          new Set([
            ...Array.from(mappedNew.keys()).filter(
              x => !draft.artefactMap.has(x)
            ),
            ...(draft.categoryMap.get(action.category || NOCAT) || [])
          ])
        )
        draft.cagtegoryUploading.set(
          action.category || NOCAT,
          new Set([
            ...(draft.cagtegoryUploading.get(action.category || NOCAT) || []),
            ...mappedNew.keys()
          ])
        )

        draft.artefactMap = new Map([...draft.artefactMap, ...mappedNew])
        draft.queued = new Set([...draft.queued, ...mappedNew.keys()])

        return draft
      }
      case 'start_upload': {
        action.keys.forEach(x => {
          draft.queued.delete(x)
          draft.inProgress.add(x)
          const existing = draft.artefactMap.get(x)

          if (existing && existing.uploadingState) {
            const updated: DraftArtefactView = {
              ...existing,
              uploadingState: {
                ...existing.uploadingState,
                isLoading: true
              }
            }
            draft.artefactMap.set(x, updated)
          }
        })

        return draft
      }
      case 'progress_upload': {
        const existing = draft.artefactMap.get(action.key)
        if (existing && existing.uploadingState) {
          const updated: DraftArtefactView = {
            ...existing,
            uploadingState: {
              ...existing.uploadingState,
              loaded: action.progress
            }
          }
          draft.artefactMap.set(action.key, updated)
        }
        return draft
      }
      case 'upload_error': {
        const existing = draft.artefactMap.get(action.key)
        const category = getCatFromKey(action.key)
        draft.inProgress.delete(action.key)
        if (existing && existing.uploadingState) {
          const updated: DraftArtefactView = {
            ...existing,
            uploadingState: {
              ...existing.uploadingState,
              isError: true,
              isLoading: false
            }
          }
          draft.artefactMap.set(action.key, updated)
        }
        const current = draft.cagtegoryUploading.get(category || NOCAT)
        if (current) {
          current.delete(action.key)
        }
        return draft
      }
      case 'complete_upload': {
        const existing = draft.artefactMap.get(action.key)

        if (existing && existing.uploadingState) {
          const updated: DraftArtefactView = {
            ...existing,
            uploadingState: {
              ...existing.uploadingState,
              isComplete: true,
              loaded: existing.uploadingState.total
            }
          }
          draft.artefactMap.set(action.key, updated)
        }
        return draft
      }
      case 'complete_processing': {
        const existing = draft.artefactMap.get(action.artefact.key)

        draft.inProgress.delete(action.artefact.key)
        const category = getCatFromKey(action.artefact.key)
        if (existing && existing.uploadingState) {
          const updated: DraftArtefactView = {
            ...existing,
            ...action.artefact,
            uploadingState: {
              ...existing.uploadingState,
              isProcessed: true,
              isLoading: false
            }
          }
          if (action.artefact.tempKey) {
            draft.artefactMap.delete(action.artefact.tempKey)
          }
          draft.artefactMap.set(action.artefact.key, updated)
        }
        const currentUploading = draft.cagtegoryUploading.get(category || NOCAT)
        if (currentUploading) {
          currentUploading.delete(action.artefact.key)
        }

        const currentCompleted = draft.categoryCompleted.get(category || NOCAT)
        if (currentCompleted) {
          currentCompleted.add(action.artefact.key)
        } else {
          draft.categoryCompleted.set(
            category || NOCAT,
            new Set([action.artefact.key])
          )
        }
        return draft
      }
      case 'remove_start': {
        const existing = draft.artefactMap.get(action.key)
        if (existing) {
          const updated = {
            ...existing,
            deleting: true,
            deleteFailed: false
          }
          draft.artefactMap.set(action.key, updated)
        }
        return draft
      }
      case 'remove_error': {
        const existing = draft.artefactMap.get(action.key)
        if (existing) {
          const updated = {
            ...existing,
            deleting: false,
            deleteFailed: true
          }

          draft.artefactMap.set(action.key, updated)
        }

        return draft
      }
      case 'remove_complete': {
        draft.artefactMap.delete(action.key)
        const category = getCatFromKey(action.key)
        const currentCompleted = draft.categoryCompleted.get(category || NOCAT)
        if (currentCompleted) {
          currentCompleted.delete(action.key)
        }
        return draft
      }
      default:
        return draft
    }
  })
}
