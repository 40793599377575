import React from 'react'

import { ModalBody, ModalBodyProps } from 'reactstrap'

export const CJModalBody: React.FC<ModalBodyProps> = ({
  children,
  className
}) => {
  return (
    <ModalBody
      className={`d-flex flex-column bg-white border-0 px-45 py-4 text-center ${className} `}
    >
      {children}
    </ModalBody>
  )
}
