import { Rule } from '../types'

export const check = (
  rulesToCheck: Rule[],
  roles: string[],
  action: string
): boolean => {
  const staticPermissions: string[] = rulesToCheck
    .filter((x): boolean => roles.includes(x.role))
    .map<string[]>((r): string[] => r.permissions)
    .reduce((acc, x): string[] => acc.concat(x), [])

  if (!staticPermissions || staticPermissions.length === 0) {
    // role is not present in the rules
    return false
  }

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true
  }

  return false
}
