import React from 'react'

import { Label, Row, Col, Input, FormFeedback } from 'reactstrap'
import { CountPicker } from './count-picker'

export interface MethodParamaterProps {
  packSize?: number
  artefactViewCount?: number
  handleChangeArtefactViewCount: (newValue: number | undefined) => void
  handleChangePackSize: (newValue: number) => void
}
export const sanitizeArtefactViewCount = (
  newVal: number | undefined
): number | undefined => {
  if (newVal === undefined || Number.isNaN(newVal)) {
    return undefined
  }

  let toUpdate = newVal !== undefined && newVal > 50 ? 50 : newVal
  toUpdate = newVal !== undefined && newVal < 1 ? 1 : toUpdate
  return toUpdate
}
export const MethodParamaters: React.FC<MethodParamaterProps> = ({
  packSize,
  artefactViewCount,
  handleChangeArtefactViewCount,
  handleChangePackSize
}) => {
  return (
    <div className="border rounded d-flex flex-column bg-white">
      <Row
        style={{ minHeight: '120px' }}
        className=" border-bottom px-3 py-4 mx-0 align-items-center"
      >
        <Col>
          <Label
            for="artefactViewCount"
            className="mr-3 mb-1 font-weight-bold text-primary"
          >
            Packs per artefact
            <div className="font-weight-normal text-secondary mb-1">
              Is the number of judgements you wish each artefact to appear in
            </div>
            <div className="font-weight-normal text-secondary ">(Max 50)</div>
          </Label>
        </Col>
        <Col xs="8" className="text-center ">
          <Input
            data-testid="artefact-view-count-input"
            name="artefactViewCount"
            invalid={
              artefactViewCount === undefined ||
              artefactViewCount > 50 ||
              artefactViewCount < 1
            }
            pattern="[0-9][0-9]"
            style={{ fontSize: '1.25rem' }}
            className="text-center w-100 font-weight-bold mx-0 "
            value={artefactViewCount || ''}
            onChange={newValue => {
              const newVal = parseInt(newValue.target.value, 10)
              handleChangeArtefactViewCount(
                Number.isNaN(newVal) ? undefined : newVal
              )
            }}
          />
          <FormFeedback>Must be between 1 and 50</FormFeedback>
        </Col>
      </Row>
      <Row
        style={{ minHeight: '120px' }}
        className={`flex-grow-1  border-bottom px-3 py-4 mx-0 align-items-center ${
          packSize && packSize === 2 ? 'bg-light' : ''
        }`}
      >
        <Col>
          <div
            className={`mr-3 mb-1 font-weight-bold ${
              packSize && packSize > 2 ? 'text-primary' : ''
            }`}
          >
            Pack size
          </div>
          <div className="font-weight-normal text-secondary mb-1">
            {'Is the number of artefacts in each pack to be placed in order '}
          </div>
          {packSize && packSize > 2 && (
            <div className="font-weight-normal text-secondary">
              {'(Min 3 - Max 10) '}
            </div>
          )}
        </Col>
        <Col
          xs="8"
          className={`${
            packSize === 2 ? 'h4' : 'h5'
          } mb-0 font-weight-bold text-center `}
        >
          {packSize && packSize > 2 ? (
            <div data-testid="pack-size-input">
              <CountPicker
                min={3}
                max={10}
                value={packSize}
                onChange={newValue => handleChangePackSize(newValue)}
              />
            </div>
          ) : (
            packSize
          )}
        </Col>
      </Row>
      {artefactViewCount && packSize && (
        <Row
          style={{ minHeight: '120px' }}
          className="px-3 py-4 mx-0 align-items-center bg-light"
        >
          <Col>
            <div className="font-weight-bold  mb-1">
              Judgements per artefact
            </div>
            <div className="text-secondary ">Minimum of 20 is recommended</div>
          </Col>
          <Col xs="8" className="text-center h4 mb-0 font-weight-bold">
            {artefactViewCount * (packSize - 1)}
          </Col>
        </Row>
      )}
    </div>
  )
}
