import rankingIcon from '../assets/icon-ranking.svg'
import pairwiseIcon from '../assets/icon-pairwise.svg'

export const Pairwise = 'Pairwise'
export const RankOrdering = 'Ranking'

export const getMethodIcon = (packSize: number) => {
  if (packSize < 2) {
    throw new Error('Pack size must be 2 or higher')
  }
  if (packSize === 2) {
    return pairwiseIcon
  }

  return rankingIcon
}

export const getMethodText = (packSize: number) => {
  if (packSize < 2) {
    throw new Error('Pack size must be 2 or higher')
  }

  if (packSize === 2) {
    return Pairwise
  }

  return RankOrdering
}

export const getPacksOrPairs = (packSize: number) => {
  if (packSize < 2) {
    throw new Error('Pack size must be 2 or higher')
  }

  if (packSize === 2) {
    return 'pairs'
  }

  return 'packs'
}
