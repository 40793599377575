import React, { useMemo, useState } from 'react'
import { Col, Row, Button, Card, CardBody, Spinner } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faCheckCircle,
  faCalendar
} from '@fortawesome/pro-solid-svg-icons'
import { PlanStatus, Project } from '../../types'
import { CJModal } from '../shared/modal/modal'
import { CJModalHeader } from '../shared/modal/subcomponents/modal-header'
import { CJModalBody } from '../shared/modal/subcomponents/modal-body'
import { CJModalFooter } from '../shared/modal/subcomponents/modal-footer'

interface ProjectStatusProps {
  planNotFound: boolean
  planValid: boolean
  planConnected: boolean
  planInputValid: boolean
  project: Project
  creationPending: boolean
  startPending: boolean
  startFailed: boolean
  onCreate: () => void
  onStart: () => void
  onChangeDate: () => void
  className?: string
}

export const ProjectStatusView: React.FC<ProjectStatusProps> = ({
  planNotFound,
  planConnected,
  planValid,
  planInputValid,
  project,
  creationPending,
  className,
  onChangeDate,
  startPending,
  startFailed,
  onCreate,
  onStart
}): JSX.Element => {
  const [confirmStartModalOpen, setConfirmStartModalOpen] = useState(false)

  const toggle = () => {
    setConfirmStartModalOpen(!confirmStartModalOpen)
  }

  const status = useMemo((): PlanStatus => {
    if (!planInputValid) {
      return 'INVALIDINPUT'
    }
    if (planNotFound) {
      return 'NOPLAN'
    }
    if (!planValid && planInputValid) {
      return 'INVALID'
    }
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    if (project && project.endDate && project.endDate < now) {
      return 'DUEDATEPASSED'
    }
    if (startFailed) {
      return 'STARTFAILED'
    }
    return 'GOOD'
  }, [planInputValid, planValid, planNotFound, project, startFailed])

  const icon = useMemo((): JSX.Element | undefined => {
    switch (status) {
      case 'INVALIDINPUT':
      case 'INVALID':
      case 'DUEDATEPASSED':
      case 'STARTFAILED':
        return (
          <FontAwesomeIcon
            className="text-danger font-larger"
            icon={faExclamationTriangle}
          />
        )
      case 'GOOD':
        return undefined
      default:
        return (
          <FontAwesomeIcon
            className="text-primary"
            size="lg"
            icon={faCheckCircle}
          />
        )
    }
  }, [status])
  const button = useMemo(() => {
    if (status === 'INVALIDINPUT') {
      return undefined
    }
    if (status === 'DUEDATEPASSED') {
      return (
        <Button
          color="danger"
          onClick={() => {
            onChangeDate()
          }}
        >
          <FontAwesomeIcon className="mr-2" icon={faCalendar} />
          Change Due Date
        </Button>
      )
    }

    if (project.planUploaded || status === 'GOOD' || status === 'STARTFAILED') {
      return (
        <>
          <Button
            id="btn-startproject"
            disabled={startPending}
            color={
              (project.planUploaded &&
                ['INVALIDINPUT', 'INVALID', 'INVALID'].includes(status)) ||
              status === 'STARTFAILED'
                ? 'primary'
                : 'white'
            }
            className={
              (project.planUploaded &&
                ['INVALIDINPUT', 'INVALID'].includes(status)) ||
              status === 'STARTFAILED'
                ? 'text-white'
                : 'text-primary'
            }
            onClick={() => {
              if (planConnected) {
                onStart()
              } else {
                setConfirmStartModalOpen(true)
              }
            }}
          >
            {!startPending && 'Start project'}
            {startPending && <Spinner color="primary" size="sm" />}
          </Button>
          <CJModal centered isOpen={confirmStartModalOpen} toggle={toggle}>
            <CJModalHeader className="bg-ddd text-dark">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="lg"
                className="my-2"
              />
            </CJModalHeader>
            <CJModalBody>
              <b className="text-uppercase"> No Rank order will be generated</b>
              <u className="mb-3">The plan is not connected</u>
              <div>Do you still want to start the project?</div>
            </CJModalBody>
            <CJModalFooter>
              {toggle !== undefined && !startPending && (
                <Button color="ddd" className="mr-2" outline onClick={toggle}>
                  Cancel
                </Button>
              )}

              <Button
                id="btn-startproject"
                disabled={startPending}
                color="primary"
                onClick={() => {
                  onStart()
                }}
              >
                {!startPending && 'Confirm and Start project'}
                {startPending && <Spinner color="primary" size="sm" />}
              </Button>
            </CJModalFooter>
          </CJModal>
        </>
      )
    }

    return (
      <Button
        disabled={creationPending}
        color={status === 'INVALID' ? 'danger' : 'primary'}
        className="font-smaller w-100"
        onClick={() => {
          onCreate()
        }}
      >
        {!creationPending && status === 'INVALID' && 'Revise plan'}
        {!creationPending && status !== 'INVALID' && 'Create plan'}
        {creationPending && <Spinner color="white" size="sm" />}
      </Button>
    )
  }, [
    planConnected,
    confirmStartModalOpen,
    status,
    project.planUploaded,
    startPending,
    creationPending,
    onCreate,
    onStart
  ])
  const message = useMemo(() => {
    if (project.planUploaded && ['INVALIDINPUT', 'INVALID'].includes(status)) {
      return (
        <>
          {'Please add the '}
          <b>
            <u>artefacts</u>
          </b>
          {' defined in your uploaded plan to enable starting the project'}
        </>
      )
    }
    switch (status) {
      case 'INVALIDINPUT': {
        const perCat =
          project.categories.length > 1
            ? Math.floor(project.packSize / project.categories.length)
            : undefined
        return (
          <>
            <span>
              {`Your project must have at least 1 judge${
                perCat && perCat > 0 ? ',' : ' and'
              } more than ${project.packSize} artefacts${
                perCat && perCat > 0
                  ? ` and each category must have at least ${perCat} artefact${
                      perCat > 1 ? 's' : ''
                    }`
                  : ''
              }, in order to create a plan please `}
            </span>
            <b>
              <u>add some judges and artefacts</u>
            </b>
          </>
        )
      }
      case 'INVALID':
        return (
          <>
            <span>You've made some recent changes and need to</span>
            <b>
              <u> revise the plan</u>
            </b>
            {' to start the project'}
          </>
        )
      case 'DUEDATEPASSED':
        return <b>A project cannot start with a due date in the past</b>
      case 'STARTFAILED':
        return <b>Failed to start project, please try again</b>
      case 'GOOD':
        return <b>All things are set, you can start the project now</b>

      default:
        return <b>Everything is alright, you can create a plan now.</b>
    }
  }, [
    status,
    project.packSize,
    project.planUploaded,
    project.categories.length
  ])

  return (
    <Card
      className={`${className} h-100  rounded  ${
        status === 'GOOD' ? 'text-white bg-primary border-0' : ''
      }`}
    >
      <CardBody className="d-flex py-4 px-42 flex-column justify-content-center">
        <Row className="align-items-center">
          {icon && <Col xs="auto">{icon}</Col>}
          <Col>{message}</Col>
          <Col xs="auto">{button}</Col>
        </Row>
      </CardBody>
    </Card>
  )
}
