import { useEffect, useMemo } from 'react'
import { useAsyncTaskAxios, AsyncTask } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { useGetBearerToken } from './use-get-bearer-token'

interface UseDownloadProps {
  fileUrl: string
  fileName: string
}

export const useDownloadFile = (
  downloadProps: UseDownloadProps
): AsyncTask<AxiosResponse<any>, unknown[]> => {
  const accessToken = useGetBearerToken()

  const getPlanCSVMemo = useMemo(() => {
    return {
      responseType: 'blob',
      url: downloadProps.fileUrl,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  }, [downloadProps.fileUrl, accessToken])

  const getPlanCSVTask = useAsyncTaskAxios<AxiosResponse<any>>(
    axios,
    getPlanCSVMemo
  )
  useEffect(() => {
    if (
      getPlanCSVTask.result &&
      (getPlanCSVTask.result.headers['content-type'] ===
        'binary/octet-stream' ||
        getPlanCSVTask.result.headers['content-type'] ===
          'application/octet-stream')
    ) {
      const blob = new Blob([getPlanCSVTask.result.data])

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE
        window.navigator.msSaveOrOpenBlob(blob, `${downloadProps.fileName}`)
      } else {
        const downloadUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')

        link.href = downloadUrl

        link.setAttribute('download', `${downloadProps.fileName}`) // any other extension

        document.body.append(link)

        link.click()

        link.remove()
        window.URL.revokeObjectURL(downloadUrl)
      }
    }
  }, [getPlanCSVTask.result, downloadProps.fileName])

  return getPlanCSVTask
}
