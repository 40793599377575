import React, { FC } from 'react'
import { Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import { ProjectStatus } from '../../types'

interface HeaderProps {
  projectStatus: ProjectStatus
  hideBackButton?: boolean
}

export const ProjectHeader: FC<HeaderProps> = ({
  children,
  hideBackButton,
  projectStatus
}): JSX.Element => {
  return (
    <div className="bg-light py-42 px-3 ">
      {!hideBackButton && (
        <Container className="mb-4">
          <FontAwesomeIcon
            size="sm"
            icon={faChevronLeft}
            className="mr-2 text-secondary"
          />
          <Link
            to={`/projects?status=${projectStatus}`}
            className="font-weight-bold text-secondary"
          >
            Projects
          </Link>
        </Container>
      )}
      <Container className="">{children}</Container>
    </div>
  )
}
