import React, { forwardRef, useRef } from 'react'

import { Label } from 'reactstrap'
import { Step, StepDefinition } from './step'
import { WizardStepProps, BasisStepProps, reg } from '../../types'
import { ValidatedInput, CheckValid, ValAndSumbit } from './validated-input'

export interface BasisValAndSumbit extends ValAndSumbit {
  onChange?: (event: BasisStepProps) => void
}

export const BasisInput: React.FC<
  BasisValAndSumbit & React.RefAttributes<CheckValid>
> = forwardRef(({ value, onSubmit, onChange }, ref) => {
  return (
    <ValidatedInput
      autoFocus
      ref={ref}
      data-testid="basis-input"
      type="textarea"
      maxLength={200}
      name="projectBasis"
      onChange={event => {
        if (onChange) {
          onChange({ basis: event.target.value })
        }
      }}
      rows={4}
      className="font-weight-bold"
      onSubmit={onSubmit}
      id="projectBasis"
      validationMessage="Question must be at least 3 alphanumeric characters long"
      checkIsValid={newVal => {
        return (
          typeof newVal === 'string' &&
          newVal.trim().length >= 3 &&
          reg.test(newVal)
        )
      }}
      placeholder="Which essay is better?"
      value={value}
    />
  )
})
export const BasisStep: React.FC<WizardStepProps<BasisStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  goBack,
  data,
  totalSteps,
  onChange
}) => {
  const childRef = useRef<CheckValid>(null)
  return (
    <Step
      stepNumber={stepNumber}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <StepDefinition
        stepNumber={stepNumber}
        stepTitle="Judgement Question"
        totalSteps={totalSteps}
        currentStep={currentStep}
        goBack={() => {
          if (goBack) {
            goBack()
          }
        }}
        goForward={() => {
          if (childRef && childRef.current) {
            const newValid = childRef.current.checkAndSetValid()
            if (newValid) {
              goForward()
            }
          }
        }}
      >
        <Label className="wizard-label font-weight-bold mb-2 font-larger">
          Please write the question to be the basis for judgements
        </Label>
        <p className="text-secondary mb-45">
          This is the question that summarises the judges’ task. It should
          include enough detail for the judge to be able to focus on the task at
          hand.
        </p>
        <BasisInput
          ref={childRef}
          value={data.basis}
          onSubmit={goForward}
          onChange={onChange}
        />
      </StepDefinition>
    </Step>
  )
}
