import React from 'react'

export const TaskProgress: React.FC<{
  completedTasks: number
  totalTasks: number
}> = ({ completedTasks, totalTasks }): JSX.Element => (
  <div className={`position-relative rounded`}>
    <h5 className="font-weight-bold mb-0">{`${completedTasks} / ${totalTasks}`}</h5>
  </div>
)
