import React, { FC, useRef, useMemo } from 'react'
import { useRect } from '@reach/rect'
import { keys, values } from 'lodash'

interface SimpleResponsiveBarChart {
  data: { [key: string]: number }
  barWidth?: number
  maxDataValue?: number
}

export const SimpleResponsiveBarChart: FC<SimpleResponsiveBarChart> = ({
  data,
  maxDataValue,
  barWidth = 8
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRect(ref)

  const maxValue = useMemo(() => {
    if (maxDataValue) {
      return maxDataValue
    }
    const maxV = Math.max(...values(data))
    return maxV
  }, [data, maxDataValue])

  const itemWidth = useMemo(() => {
    let width = barWidth
    if (rect && rect.width) {
      width = rect.width / keys(data).length
    }
    return Math.min(width, barWidth)
  }, [rect, data, barWidth])
  const normalizedVals = useMemo(() => {
    const mappedVals = values(data).map(x => (24 / maxValue) * x)

    return mappedVals
  }, [data, maxValue])
  return (
    <>
      <div ref={ref} className="d-flex flex-column flex-fill">
        <div className="px-2 d-flex flex-fill justify-content-around">
          {keys(data).map((x, i) => (
            <svg key={x} width={itemWidth} height={24}>
              <rect
                fill="#037fac"
                x={0}
                y={24 - normalizedVals[i]}
                width={itemWidth}
                height={normalizedVals[i]}
              />
            </svg>
          ))}
        </div>
        <svg style={{ flex: '0 0 100%' }} width="100%" height={8}>
          <rect
            fill="#dddddd"
            x={0}
            y={0}
            rx="4"
            ry="4"
            width="100%"
            height={8}
          />
        </svg>
      </div>
    </>
  )
}
