import React from 'react'
import { Button, ButtonProps, Spinner, DropdownItemProps } from 'reactstrap'
import { useDownloadFile } from './use-download-file'

interface DownloadButtonProps extends ButtonProps {
  downloadUrl: string
  downloadFileName: string
  spinnerColor: string
}

interface DownloadDropDownProps extends DropdownItemProps {
  downloadUrl: string
  downloadFileName: string
  spinnerColor: string
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  downloadUrl,
  downloadFileName,
  spinnerColor,
  children,
  ...rest
}): JSX.Element => {
  const dl = useDownloadFile({
    fileUrl: downloadUrl,
    fileName: downloadFileName
  })
  return (
    <Button
      {...rest}
      onClick={() => dl.start()}
      disabled={dl.started && dl.pending}
    >
      {!(dl.started && dl.pending) && children}
      {dl.started && dl.pending && (
        <>
          <span>Downloading</span>
          <Spinner size="sm" className="ml-2" color={spinnerColor} />
        </>
      )}
    </Button>
  )
}
