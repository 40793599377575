import React, { ChangeEvent, useState } from 'react'

import { Input, Label, FormGroup } from 'reactstrap'
import { Step, StepDefinition } from './step'
import { WizardStepProps, PlanType, PlanTypeStepProps } from '../../types'

export const PlanTypeStep: React.FC<WizardStepProps<PlanTypeStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  data,
  totalSteps,
  goBack,
  onChange
}) => {
  const [selectionValid, setSelectionValid] = useState(true)

  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value as PlanType
    setSelectionValid(newValue !== undefined)
    if (onChange) {
      onChange({ planType: newValue })
    }
  }
  return (
    <Step
      stepNumber={stepNumber}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <StepDefinition
        stepNumber={stepNumber}
        stepTitle="Project's Plan"
        totalSteps={totalSteps}
        currentStep={currentStep}
        goBack={() => {
          if (goBack) {
            goBack()
          }
        }}
        goForward={() => {
          setSelectionValid(data.planType !== undefined)
          if (data.planType) {
            goForward()
          }
        }}
      >
        <FormGroup tag="fieldset">
          <FormGroup check className="mb-5 pl-0 ">
            <div className="custom-control custom-radio">
              <Input
                data-testid="input-autoplan"
                type="radio"
                id="none"
                name="none"
                value="AUTO"
                className="custom-control-input"
                checked={data.planType === 'AUTO'}
                onChange={event => handleChangeRadio(event)}
              />
              <Label
                for="none"
                className="custom-control-label"
                data-testid="label-autoplan"
                check
              >
                Let the system create an auto plan
              </Label>
            </div>
            <div className="text-secondary mt-2">
              When you complete uploading artefacts and selecting judges, the
              system will create a plan for you
            </div>
          </FormGroup>
          <FormGroup check className="pl-0">
            <div className="custom-control custom-radio">
              <Input
                data-testid="input-manualplan"
                type="radio"
                name="date"
                id="date"
                className="custom-control-input"
                value="MANUAL"
                checked={data.planType === 'MANUAL'}
                onChange={event => handleChangeRadio(event)}
              />
              <Label
                for="date"
                className="custom-control-label"
                data-testid="label-manualplan"
                check
              >
                I will upload my own plan as a CSV
              </Label>
            </div>
            <div className="text-secondary mt-2">
              You can choose to upload your own plan now
            </div>
          </FormGroup>
        </FormGroup>
        {!selectionValid && (
          <div className="font-weight-bold text-danger">
            Please choose a plan type
          </div>
        )}
      </StepDefinition>
    </Step>
  )
}
