import { Rule } from '../types'

export const rules: Rule[] = [
  {
    role: 'CJ Admin',
    permissions: ['project:create', 'project:list', 'project:view']
  },
  {
    role: 'CJ Judge',
    permissions: ['task:list']
  }
]
