import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { Row, Col, Container, Progress } from 'reactstrap'
import { format, differenceInCalendarDays, addDays } from 'date-fns'
import { Project } from '../../types'
import { getPacksOrPairs } from '../../helpers/project-type-helpers'

export const ProgressView: React.FC<Project> = ({
  totalTasks,
  completedDate,
  endDate,
  completedTasks,
  packSize
}): JSX.Element => {
  const daysLeft = endDate
    ? differenceInCalendarDays(addDays(endDate, 1), new Date())
    : 0
  return (
    <>
      <Progress
        className="rounded-0 progress-slim-2 "
        value={completedTasks || 0}
        max={totalTasks || 0}
        min={0}
      />
      <Container className="pt-45">
        <Row>
          <Col className="font-larger font-weight-bold">
            <span className="h4 font-weight-bold">
              {`${completedTasks} / ${totalTasks}`}
            </span>
            <span>{` ${getPacksOrPairs(packSize)} completed`}</span>
          </Col>
          {(endDate || completedDate) && (
            <Col className="text-right">
              <div className="font-weight-bold">
                <FontAwesomeIcon icon={faClock} className="mr-2 " />
                {completedDate && (
                  <span>
                    {`Completed on ${format(completedDate, 'MMM dd, yyyy')}`}
                  </span>
                )}
                {!completedDate && endDate && (
                  <span>
                    {`Due date is ${format(endDate, 'MMM dd, yyyy')}`}
                  </span>
                )}
              </div>
              {!completedDate && (
                <div
                  className={`${
                    daysLeft < 5 ? 'text-danger' : 'text-secondary'
                  } font-weight-bold`}
                >
                  {` ${daysLeft} days left`}
                </div>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}
