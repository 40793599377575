import React, { useState } from 'react'
import {
  Col,
  Row,
  CardBody,
  Card,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-solid-svg-icons'
import { Project } from '../../../types'
import { NameAndMethod } from '../../name-and-method'

import { ManageAdminModal } from '../../modals/manage-admins-modal'
import { ActionsDropDown } from '../../project-view/actions-dropdown'

export const ProjectDraftRow: React.FC<Project &
  React.HTMLAttributes<HTMLDivElement> & { triggerRemoveRow: () => void }> = ({
  className,
  triggerRemoveRow,
  ...project
}): JSX.Element => {
  const [showAdminsModal, setShowAdminsModal] = useState(false)

  return (
    <>
      <Card className={`${className} project-row`}>
        <CardBody className="p-42">
          <Row className="align-items-center" data-testid="project-draft">
            <Col>
              <NameAndMethod {...project} />
            </Col>
            <Col xs="auto" className="text-right">
              <div className="text-secondary font-weight-bold">Pack Size</div>
              <div className="h4 mb-0 font-weight-bold text-dark">
                {project.packSize}
              </div>
            </Col>
            <Col xs="auto" className="text-right ml-lg-45">
              {project.packsPerArtefact && !project.planUploaded && (
                <>
                  <div className="text-secondary font-weight-bold">
                    Judgements per Artefact
                  </div>
                  <div className="h4 mb-0 font-weight-bold text-dark">
                    {(project.packSize - 1) * project.packsPerArtefact}
                  </div>
                </>
              )}
              {project.planUploaded && (
                <>
                  <div className="text-secondary font-weight-bold">
                    Plan Type
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-dark">
                    Manual
                  </div>
                </>
              )}
            </Col>
            <Col xs="auto" className="text-right ml-lg-45">
              <ActionsDropDown>
                <DropdownMenu right className="mt-3">
                  <DropdownItem
                    className="py-3 text-left font-weight-bold "
                    onClick={e => {
                      e.preventDefault()
                      setShowAdminsModal(true)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUsers}
                      className="mr-3"
                      fixedWidth
                    />
                    Manage Admins
                  </DropdownItem>
                </DropdownMenu>
              </ActionsDropDown>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ManageAdminModal
        project={project}
        onRemoveCurrentUser={() => {
          triggerRemoveRow()
        }}
        isOpen={showAdminsModal}
        toggle={() => setShowAdminsModal(!showAdminsModal)}
      />
    </>
  )
}
