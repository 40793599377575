import React, { useMemo, useState, createRef, useRef } from 'react'
import { Button, Col, Row, CardHeader, CardBody, Spinner } from 'reactstrap'
import { SizeMe } from 'react-sizeme'
import {
  SliderHandle,
  SliderTrack,
  SliderTrackHighlight,
  SliderInput
} from '@reach/slider'
import '@reach/slider/styles.css'
import { ScrollSyncPane } from 'react-scroll-sync'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearchPlus,
  faSearchMinus,
  faExpandAlt,
  faCompressAlt,
  faAngleLeft,
  faAngleRight,
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import { useRect } from '@reach/rect'
import { findIndex } from 'lodash'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Document, Page, Pdf } from 'react-pdf'
import { InView } from 'react-intersection-observer'
import { useGetBearerToken } from '../use-get-bearer-token'
import { useConfig } from '../../use-remote-config'
import { Loading } from '../loading'

interface TaskArtefactProps {
  artefactKey: string | undefined
  retrievePending: boolean
  choosePending: boolean
  disabled?: boolean
  showAllPages?: boolean
  isExpanded?: boolean
  onClose?: () => void
  scrollSynced?: boolean
  onLoaded: () => void
  onChoose?: (chosenKey: string) => void
  onExpandChange?: (isExpanded: boolean) => void
}

export const TaskArtefact: React.FC<TaskArtefactProps> = ({
  artefactKey,
  scrollSynced,
  retrievePending = false,
  disabled,
  onClose,
  choosePending = false,
  showAllPages = true,
  isExpanded = false,
  onLoaded,
  onExpandChange,
  onChoose
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const pref = useRef<HTMLElement>(null)

  // pass it in to be observered
  const rect = useRect(ref)
  const prect = useRect(pref)
  const accessToken = useGetBearerToken()
  const [pageCount, setPageCount] = useState(0)
  const [pageRefs, setPageRefs] = useState<React.RefObject<any>[]>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [zoom, setZoom] = useState(1)
  const [inviews, setInviews] = useState<boolean[]>([])

  const derivedPageNumber = useMemo(() => {
    if (showAllPages) {
      return findIndex(inviews, x => x) + 1
    }
    return pageNumber
  }, [inviews, pageNumber, showAllPages])

  const { config } = useConfig()
  const artefactSource = useMemo(() => {
    if (!artefactKey || !accessToken || !config.apiUrl) {
      return undefined
    }
    // FIX for case insensitivey in uploaded plans (not ideal)
    const splits = artefactKey.split('/')
    splits[splits.length - 1] = splits[splits.length - 1].toLowerCase()
    const fiddled = splits.join('/')
    // END FIX
    return {
      url: `${config.apiUrl}/artefacts/${encodeURIComponent(`${fiddled}`)}`,
      httpHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  }, [artefactKey, accessToken, config.apiUrl])
  const onDocLoadSuccess = (pdf: Pdf) => {
    const refs = [...new Array(pdf.numPages)].reduce((acc, value, index) => {
      // eslint-disable-next-line no-param-reassign
      acc[index] = createRef()
      return acc
    }, {})
    setPageRefs(refs)
    setInviews(Array.from({ length: pdf.numPages }, () => false))
    setPageCount(pdf.numPages)
    if (onLoaded) {
      onLoaded()
    }
  }

  const nextPage = () => {
    if (showAllPages) {
      if (derivedPageNumber < pageCount) {
        pageRefs[derivedPageNumber - 1 + 1].current.ref.scrollIntoView(true)
      }
    } else if (pageNumber < pageCount) {
      setPageNumber(pageNumber + 1)
    }
  }
  const prevPage = () => {
    if (showAllPages) {
      if (derivedPageNumber > 1) {
        pageRefs[derivedPageNumber - 1 - 1].current.ref.scrollIntoView(true)
      }
    } else if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  return (
    <>
      <CardHeader
        className="w-100 bg-light border-0  rounded-0"
        style={{ zIndex: 1 }}
      >
        <Row className="align-items-center">
          <Col className="text-left">
            <FontAwesomeIcon
              className="mr-3 text-secondary"
              icon={faSearchMinus}
            />
            <SliderInput
              style={{ minWidth: '70px', maxWidth: '80px' }}
              className="p-0 pb-2 d-inline-block w-100"
              min={1}
              max={4}
              step={0.1}
              value={zoom}
              onChange={(newValue: any) => setZoom(newValue)}
            >
              <SliderTrack className="bg-ddd">
                <SliderTrackHighlight className="bg-primary opacity-37" />
                <SliderHandle className="border-0 bg-primary" />
              </SliderTrack>
            </SliderInput>

            <FontAwesomeIcon
              className="ml-3 text-secondary"
              icon={faSearchPlus}
            />
          </Col>

          <Col xs="auto" className="text-center px-0">
            {onChoose && (
              <Button
                color="primary"
                disabled={
                  !artefactKey || choosePending || retrievePending || disabled
                }
                className="px-45"
                onClick={() => {
                  if (artefactKey) {
                    onChoose(artefactKey)
                  }
                }}
              >
                {(choosePending || retrievePending) && (
                  <Spinner size="sm" color="white" />
                )}
                {!(choosePending || retrievePending) && 'Choose'}
              </Button>
            )}
          </Col>

          <Col className="text-right">
            {pageCount > 0 && (
              <>
                <Button
                  size="sm"
                  color="link"
                  className="px-2"
                  disabled={derivedPageNumber === 1}
                  onClick={() => prevPage()}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
                <span className="font-weight-bold mx-1 noselect">{`${derivedPageNumber} / ${pageCount}`}</span>
                <Button
                  size="sm"
                  className="px-2"
                  color="link"
                  disabled={derivedPageNumber === pageCount}
                  onClick={() => nextPage()}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </Button>
              </>
            )}
            {onClose && (
              <Button className="ml-4" color="link" onClick={() => onClose()}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
            {!onClose && (
              <Button
                color="lightButNotTooLight"
                className="ml-4"
                style={{ padding: 0, width: '40px', height: '40px' }}
                onClick={() => {
                  if (onExpandChange) {
                    onExpandChange(!isExpanded)
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={isExpanded ? faCompressAlt : faExpandAlt}
                  size="lg"
                />
              </Button>
            )}
          </Col>
        </Row>
      </CardHeader>
      <ScrollSyncPane>
        <CardBody
          innerRef={pref}
          className={`custom-scroll ${
            scrollSynced ? 'custom-scroll-sync' : ''
          } flex-fill overflow-scroll-y bg-white`}
        >
          <div ref={ref}>
            <ScrollContainer vertical horizontal className="scroll-container">
              {artefactSource && accessToken && (
                <Document
                  renderMode="canvas"
                  className="position-relative"
                  file={artefactSource}
                  onLoadSuccess={onDocLoadSuccess}
                  loading={<Loading className="mt-5 d-block mx-auto" />}
                >
                  {showAllPages ? (
                    [...new Array(pageCount)].map((el, index) => (
                      <Page
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${artefactKey}-${index}`}
                        ref={pageRefs[index]}
                        className="mx-auto position-relative"
                        pageNumber={index + 1}
                        scale={zoom}
                        width={
                          !rect || rect.width == null ? undefined : rect.width
                        }
                      >
                        <SizeMe monitorHeight>
                          {({ size }) => {
                            const pheight = prect ? prect.height : 1
                            // if at least half the document can fit on screen then half document required for threshold otherwise lower the threshold to the hald of what will fit on screen
                            const baseThreshold = Math.min(
                              0.5,
                              pheight / (size.height || pheight) / 2
                            )

                            return (
                              <InView
                                as="div"
                                threshold={baseThreshold / zoom}
                                style={{
                                  top: 0,
                                  left: 0
                                }}
                                className="position-absolute w-100 h-100"
                                onChange={inView => {
                                  const pre =
                                    index > 0
                                      ? [...inviews.slice(0, index)]
                                      : []
                                  const post =
                                    index < inviews.length
                                      ? [
                                          ...inviews.slice(
                                            index + 1,
                                            inviews.length
                                          )
                                        ]
                                      : []
                                  setInviews([...pre, inView, ...post])
                                }}
                              >
                                <div />
                              </InView>
                            )
                          }}
                        </SizeMe>
                      </Page>
                    ))
                  ) : (
                    <Page
                      className="mx-auto"
                      pageNumber={derivedPageNumber}
                      scale={zoom}
                      width={rect ? rect.width : 0}
                    />
                  )}
                </Document>
              )}
            </ScrollContainer>
          </div>
        </CardBody>
      </ScrollSyncPane>
    </>
  )
}
