import React, { useMemo } from 'react'
import { Row, Col, Container } from 'reactstrap'
import axios, { AxiosResponse } from 'axios'

import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Link } from 'react-router-dom'
import emptyList from '../assets/icon-draft.svg'
import { Project } from '../types'

import { Loading } from './loading'

import { SimpleMessage } from './shared/simple-message/simple-message'
import { SimpleErrorMessage } from './shared/simple-message/simple-error-message'
import { useGetBearerToken } from './use-get-bearer-token'
import { useConfig } from '../use-remote-config'
import { ProjectInProgressRow } from './shared/project-rows/project-list-inprogress-row'

export const MyTasks = (): JSX.Element => {
  const { config } = useConfig()

  const token = useGetBearerToken()

  const listProjectMemo = useMemo(() => {
    return {
      url: `${config.apiUrl}/projects?role=JUDGE&status=INPROGRESS`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }, [config, token])

  const listProjectsTask = useAsyncTaskAxios<AxiosResponse<Project[]>>(
    axios,
    listProjectMemo
  )
  useAsyncRun(config && token && listProjectsTask)

  const inProgress = useMemo(() => {
    return listProjectsTask.result
      ? listProjectsTask.result.data.filter(
          x =>
            x.completedTasks &&
            x.completedTasks > 0 &&
            x.completedTasks !== x.totalTasks
        )
      : []
  }, [listProjectsTask.result])

  const notStarted = useMemo(() => {
    return listProjectsTask.result
      ? listProjectsTask.result.data.filter(
          x => !x.completedTasks || x.completedTasks === 0
        )
      : []
  }, [listProjectsTask.result])

  return (
    <>
      <div className="bg-light py-45 px-3 ">
        <Container>
          <Row className="align-items-center">
            <Col xs={10} md={24}>
              <h1 className="h4 font-weight-bold mb-0">My tasks</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-55">
        <div className="mb-5">
          {listProjectsTask.pending && <Loading className="d-block mx-auto" />}
          {listProjectsTask.error && (
            <SimpleErrorMessage
              title=" Failed to get task list"
              message="Please refresh the page, and if the problem persists contact your system administrator."
              allowPageRefresh
            />
          )}

          {!listProjectsTask.pending &&
            listProjectsTask.result &&
            listProjectsTask.result.data.length === 0 && (
              <SimpleMessage
                className="mb-5"
                title="You have no tasks"
                message="Please come back later"
                icon={<img src={emptyList} alt="No tasks" />}
              />
            )}
          {!listProjectsTask.pending &&
            listProjectsTask.result &&
            notStarted.length > 0 && (
              <>
                {notStarted.map(
                  (project): JSX.Element => {
                    const row = (
                      <ProjectInProgressRow
                        {...project}
                        unstartedLabel="New"
                        className="mb-5"
                      />
                    )
                    if (!project.isPaused) {
                      return (
                        <Link
                          key={project.id}
                          className="no-text-hover"
                          to={`/projects/${project.id}/tasks`}
                        >
                          {row}
                        </Link>
                      )
                    } else {
                      return row
                    }
                  }
                )}
              </>
            )}
          {!listProjectsTask.pending &&
            listProjectsTask.result &&
            inProgress.length > 0 && (
              <>
                {inProgress.map(
                  (project): JSX.Element => {
                    const row = (
                      <ProjectInProgressRow
                        key={project.id}
                        {...project}
                        className="mb-5"
                      />
                    )
                    if (!project.isPaused) {
                      return (
                        <Link
                          key={project.id}
                          className="no-text-hover"
                          to={`/projects/${project.id}/tasks`}
                        >
                          {row}
                        </Link>
                      )
                    } else {
                      return row
                    }
                  }
                )}
              </>
            )}
        </div>
      </Container>
    </>
  )
}
