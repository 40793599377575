import React from 'react'
import { Row, Col, Spinner, UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { TargetMetNotificaton } from '../target-met-notification'
import { Tab } from '../shared/tabs/subcomponents/tab'
import { sanitizeId } from '../../helpers/dom-helpers'

interface CategoryTabProps {
  isActive?: boolean
  categoryName: string
  artefactLink: string
  hasRM: boolean
  pending?: boolean
  uploadingCount: number
  artefactCount: number
  expectedArtefactCount?: number | undefined
}
export const ArtefactCategoryTab: React.FC<CategoryTabProps> = ({
  isActive,
  artefactLink,
  pending,
  hasRM,
  uploadingCount,
  categoryName,
  artefactCount,
  expectedArtefactCount
}): JSX.Element => {
  const cleansedUploadIndicatorId = sanitizeId(
    `cat-${categoryName}-uploadIndicator`
  )
  return (
    <Tab key={categoryName} autoSize to={`${artefactLink}/${categoryName}`}>
      <div
        className={`${
          isActive
            ? 'bg-primary text-white'
            : 'bg-lightButNotTooLight text-body'
        } rounded  font-weight-bold py-2 px-35 position-relative`}
      >
        {expectedArtefactCount && artefactCount !== expectedArtefactCount && (
          <TargetMetNotificaton className="font-smaller" />
        )}
        <Row className="align-items-center">
          {hasRM && (
            <Col xs="auto" className="pl-0">
              <div
                className={`rounded-small bg-white text-uppercase p-1 px-2 ${
                  isActive ? 'text-primary' : 'text-dark'
                }`}
              >
                RM
              </div>
            </Col>
          )}
          <Col className="pr-5">{categoryName}</Col>
          <Col xs="auto" className="text-right" style={{ fontSize: '1.25rem' }}>
            {pending && <Spinner size="sm" />}
            {!pending && <>{artefactCount}</>}
            {!pending && uploadingCount > 0 && (
              <>
                <Spinner
                  id={cleansedUploadIndicatorId}
                  size="sm"
                  className="ml-2 align-middle"
                />
                <UncontrolledTooltip
                  target={cleansedUploadIndicatorId}
                  placement="bottom"
                >
                  <div className="px-35 py-3 font-weight-bold">
                    <span>Uploading...</span>
                    <span className="font-larger ">
                      {` ${uploadingCount} `}
                    </span>
                    <span> remaining</span>
                  </div>
                </UncontrolledTooltip>
              </>
            )}
            {expectedArtefactCount && ` / ${expectedArtefactCount}`}
            {expectedArtefactCount &&
              expectedArtefactCount === artefactCount && (
                <FontAwesomeIcon
                  title="Target met"
                  size="sm"
                  icon={faCheck}
                  className="ml-2"
                />
              )}
          </Col>
        </Row>
      </div>
    </Tab>
  )
}
