import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { Dropdown, DropdownToggle } from 'reactstrap'

export const ActionsDropDown: FC = ({ children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropDown = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
      <DropdownToggle
        color="link"
        className="text-primary bg-none px-0"
        onClick={e => e.preventDefault()}
      >
        Actions
        <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
      </DropdownToggle>
      {children}
    </Dropdown>
  )
}
