import React, { useState } from 'react'

import { FormFeedback } from 'reactstrap'
import { Step, StepDefinition, StepControls } from './step'
import { WizardStepProps, MethodStepProps } from '../../types'
import { MethodChooser } from './method-chooser'
import {
  MethodParamaters,
  sanitizeArtefactViewCount
} from './method-paramaters'

export const MethodStep: React.FC<WizardStepProps<MethodStepProps>> = ({
  stepNumber,
  currentStep,
  goForward,
  data,
  goBack,
  totalSteps,
  onChange
}) => {
  const [, setACountValid] = useState(true)

  const [packSizeValid, setPackSizeValid] = useState(true)
  const handleChangePackSize = (newValue: number): void => {
    if (onChange) {
      onChange({
        packSize: newValue,
        artefactViewCount: data.artefactViewCount
      })
    }
  }

  const handleChangeArtefactViewCount = (newVal: number | undefined): void => {
    const santizied = sanitizeArtefactViewCount(newVal)

    if (onChange) {
      onChange({
        packSize: data.packSize,
        artefactViewCount: santizied
      })
    }
  }

  const handleChangeType = (newValue: number | undefined): void => {
    setPackSizeValid(newValue !== undefined)
    if (onChange) {
      onChange({
        packSize: newValue,
        artefactViewCount: data.artefactViewCount
      })
    }
  }

  return (
    <Step
      stepNumber={stepNumber}
      currentStep={currentStep}
      totalSteps={totalSteps}
    >
      <StepDefinition
        stepNumber={stepNumber}
        currentStep={currentStep}
        stepTitle="Method"
        totalSteps={totalSteps}
        goBack={() => {
          if (goBack) {
            goBack()
          }
        }}
        goForward={() => {
          const isACountValid =
            data.artefactViewCount !== undefined &&
            data.artefactViewCount <= 50 &&
            data.artefactViewCount > 0
          const isPackSizeValid =
            data.packSize !== undefined &&
            data.packSize >= 2 &&
            data.packSize <= 10
          setPackSizeValid(isPackSizeValid)
          setACountValid(isACountValid)
          if (isACountValid && isPackSizeValid) {
            goForward()
          }
        }}
      >
        <MethodChooser
          tabIndex={0}
          className={`${packSizeValid ? 'mb-5' : ''}`}
          invalid={!packSizeValid}
          value={data.packSize}
          onChange={event => handleChangeType(event)}
        />
        <FormFeedback
          className={`mr-2 px-3 ${!packSizeValid ? 'd-block' : ''}`}
        >
          Please select the required method
        </FormFeedback>
      </StepDefinition>
      <StepControls>
        {data.packSize && (
          <MethodParamaters
            packSize={data.packSize}
            artefactViewCount={data.artefactViewCount}
            handleChangeArtefactViewCount={handleChangeArtefactViewCount}
            handleChangePackSize={handleChangePackSize}
          />
        )}
      </StepControls>
    </Step>
  )
}
