import React, { useState, useEffect, useContext, FC } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
// eslint-disable-next-line import/no-unresolved
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'
import {
  AuthState,
  AuthActions,
  User,
  AuthConfig,
  AuthCallback
} from '../types'

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<AuthState & AuthActions>({
  isAuthenticated: false, // to check if authenticated or not
  user: undefined,
  handleRedirectCallback: () => {
    throw new Error('Not implemented')
  },
  refreshUserName: (newName: string) => {
    throw new Error('Not implemented')
  },
  loginWithRedirect: () => {
    throw new Error('Not implemented')
  },
  getIdTokenClaims: () => {
    throw new Error('Not implemented')
  },
  getTokenSilently: () => {
    throw new Error('Not implemented')
  },
  getTokenWithPopup: () => {
    throw new Error('Not implemented')
  },
  logout: () => {
    throw new Error('Not implemented')
  }
})

const mapUser = (aoUser: any, roleUrl: string): User => {
  const roles: string[] = roleUrl in aoUser ? aoUser[roleUrl] : []
  const mappedUser: User = aoUser
  mappedUser.roles = roles
  mappedUser.user_id = mappedUser.sub
  return mappedUser
}

export const useAuth0 = (): AuthState & AuthActions => useContext(Auth0Context)
export const Auth0Provider: FC<AuthConfig & AuthCallback> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...rest
}): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [auth0Client, setAuth0] = useState<Auth0Client | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  useEffect((): void => {
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await createAuth0Client({
        ...rest
      })
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticatedNow = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticatedNow)

      if (isAuthenticatedNow) {
        const aoUser = await auth0FromHook.getUser()
        setUser(mapUser(aoUser, rest.role_url))
      }

      setLoading(false)
    }
    initAuth0()
  }, [])
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client!.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client!.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client!.handleRedirectCallback()
    const user = await auth0Client!.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        refreshUserName: async (newName: string) => {
          // TODO : Might be better to refresh from Auth0 although at present only way to do this is refresh the access token which causes data re-retriveal and therefore spinners on page
          // if (auth0Client) {
          //   await auth0Client.getTokenSilently({
          //     ignoreCache: true,
          //     scope: rest.scope || '',
          //     audience: rest.audience || ''
          //   })
          //   const updated = await auth0Client.getUser()
          // setUser(mapUser(updated, rest.role_url))
          if (user) {
            setUser({ ...user, name: newName })
          }
        },
        handleRedirectCallback,
        getIdTokenClaims: auth0Client
          ? (...p) => auth0Client.getIdTokenClaims(...p)
          : () => {
              throw new Error('Not implemented')
            },
        loginWithRedirect: auth0Client
          ? (...p) => auth0Client.loginWithRedirect(...p)
          : () => {
              throw new Error('Not implemented')
            },
        getTokenSilently: auth0Client
          ? (...p) => auth0Client.getTokenSilently(...p)
          : () => {
              throw new Error('Not implemented')
            },
        getTokenWithPopup: auth0Client
          ? (...p) => auth0Client.getTokenWithPopup(...p)
          : () => {
              throw new Error('Not implemented')
            },
        logout: auth0Client
          ? (...p) => auth0Client.logout(...p)
          : () => {
              throw new Error('Not implemented')
            }
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
