import React, { useState, useRef, useEffect } from 'react'

import { Input, Col } from 'reactstrap'
import {
  Pairwise,
  RankOrdering,
  getMethodIcon
} from '../../helpers/project-type-helpers'

export interface MethodChooserProps {
  tabIndex?: number
  value?: number
  className?: string
  invalid?: boolean
  onChange?: (newValue: number) => void
}
export interface MethodItemProps {
  tabIndex?: number
  method: string
  methodTip: string
  autofocus?: boolean
  checked: boolean
  value?: number
  className?: string
  innerClassName?: string
  onChange?: (newValue: string) => void
}

export const MethodInputItem: React.FC<MethodItemProps> = ({
  method,
  methodTip,
  checked,
  tabIndex,
  className,
  autofocus,
  onChange
}) => {
  const item = useRef<HTMLLabelElement>(null)

  useEffect(() => {
    if (autofocus && item !== null && item.current !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      item!.current!.focus()
    }
  }, [autofocus])
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label
      ref={item}
      data-testid={`${method}-label`}
      tabIndex={tabIndex}
      htmlFor={method}
      style={{ outline: 'none' }}
      className={`form-check-label pointer border fake-border ${
        checked ? 'fake-border-selected' : ''
      }  align-items-center rounded p-4 bg-white mx-0 row ${
        checked ? 'border-primary' : ''
      } ${className}`}
      onKeyPress={event => {
        if (event.key === '(space)' || event.charCode === 32) {
          if (onChange) {
            onChange(method)
          }
        }
      }}
    >
      <Input
        data-testid={`${method}-input`}
        type="radio"
        style={{ display: 'none' }}
        className="d-none"
        name={method}
        id={method}
        value={method}
        checked={checked}
        onChange={event => {
          if (onChange) {
            onChange(event.target.value)
          }
        }}
      />
      <Col xs="auto">
        <img src={getMethodIcon(method === Pairwise ? 2 : 3)} alt={method} />
      </Col>
      <Col className="text-left">
        <div className="font-weight-bold">{method}</div>
        {methodTip}
      </Col>
    </label>
  )
}

export const MethodChooser: React.FC<MethodChooserProps> = ({
  onChange,
  tabIndex,
  className,
  invalid,
  value
}) => {
  const [packSize, setPackSize] = useState(value)

  const handleChangeType = (newValue: string): void => {
    const newPackSize = newValue === Pairwise ? 2 : 3
    setPackSize(newPackSize)
    if (onChange) {
      onChange(newPackSize)
    }
  }

  return (
    <div
      className={`${className}  my-form-controls ${
        invalid ? 'is-invalid' : ''
      }`}
    >
      <MethodInputItem
        tabIndex={tabIndex}
        className={`mb-4 ${invalid ? 'bs-danger' : ''}`}
        autofocus
        value={packSize}
        method={Pairwise}
        methodTip="Choosing one from two artefacts"
        onChange={handleChangeType}
        checked={packSize !== undefined && packSize === 2}
      />
      <MethodInputItem
        tabIndex={tabIndex}
        className={`${invalid ? 'bs-danger' : ''}`}
        value={packSize}
        method={RankOrdering}
        methodTip="Putting a number of artefacts into an order"
        onChange={handleChangeType}
        checked={packSize !== undefined && packSize > 2}
      />
    </div>
  )
}
