import React from 'react'

import { Row, Col } from 'reactstrap'
import { FaCircleEnvelope } from '../icons/fa-circle-envelope'

export interface AvatarProps {
  emailVerified: boolean
  pictureSrc: string
}

export const Avatar: React.FunctionComponent<AvatarProps &
  React.HTMLAttributes<HTMLDivElement>> = ({
  emailVerified,
  pictureSrc,
  className
}) => (
  <>
    {emailVerified ? (
      <img
        src={pictureSrc}
        alt="user avatar"
        style={{ height: '32px' }}
        className={`nav-user-profile ${className}`}
      />
    ) : (
      <FaCircleEnvelope size="2x" />
    )}
  </>
)
