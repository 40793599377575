import React, { useMemo } from 'react'
import { Col, Row, Card, CardBody, CardFooter, Progress } from 'reactstrap'

import { format, differenceInCalendarDays } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPause } from '@fortawesome/pro-solid-svg-icons'
import { Project } from '../../../types'

import { getPacksOrPairs } from '../../../helpers/project-type-helpers'
import { NameAndMethod } from '../../name-and-method'
import { TargetMetNotificaton } from '../../target-met-notification'

interface InProgressRowProps {
  unstartedLabel?: string
}

export const ProjectInProgressRow: React.FC<Project &
  InProgressRowProps &
  React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  unstartedLabel,
  ...project
}): JSX.Element => {
  const formattedEndDate = useMemo(() => {
    return project.endDate ? format(project.endDate, 'MMM dd, yyyy') : '-'
  }, [project])
  return (
    <Card
      className={`${className} project-row ${
        project.isPaused ? 'grayscale-100 bg-light' : ''
      }`}
      data-testid="project-inprogress"
      data-projectid={project.id}
    >
      <CardBody className={`px-42 pt-42 pb-4 `}>
        <Row className="mb-42">
          <Col>
            <NameAndMethod {...project} />
          </Col>
          {project.endDate && (
            <Col xs="auto" className="text-right text-dark">
              <div>
                <FontAwesomeIcon icon={faClock} className="mr-2" />
                <span className="font-weight-bold text-dark">
                  {`Due date is ${formattedEndDate}`}
                </span>
              </div>
              {differenceInCalendarDays(project.endDate, new Date()) < 7 && (
                <div className="text-danger font-weight-bold">
                  {`${Math.max(
                    differenceInCalendarDays(project.endDate, new Date()),
                    0
                  )} days left`}
                </div>
              )}
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <span className="h4 mb-0 text-dark font-weight-bold">
              {project.completedTasks}
              {' / '}
              {project.totalTasks}
            </span>
            <span className="text-dark font-weight-bold">
              {` ${getPacksOrPairs(project.packSize)} completed`}
            </span>
            {project.unallocatedTasks !== undefined &&
              project.unallocatedTasks > 0 && (
                <span className="ml-45 position-relative rounded py-2 px-3 bg-lightButNotTooLight text-secondary font-weight-bold">
                  <span className="h5 mb-0 font-weight-bold ">
                    {project.unallocatedTasks}
                  </span>
                  {` ${getPacksOrPairs(project.packSize)} unallocated`}
                  <TargetMetNotificaton />
                </span>
              )}
          </Col>

          <Col
            xs="auto"
            className={`text-right font-weight-bold ${
              project.isPaused ||
              (unstartedLabel && project.completedTasks === 0)
                ? 'mr-n42'
                : ''
            } `}
          >
            {project.isPaused && (
              <span className="ribbon py-2 px-35 ">
                <FontAwesomeIcon
                  icon={faPause}
                  size="xs"
                  className="mr-2 align-middle"
                />
                {'Paused'}
              </span>
            )}
            {!project.isPaused &&
              unstartedLabel &&
              project.completedTasks === 0 && (
                <span className="ribbon py-2 px-35">{unstartedLabel}</span>
              )}

            {((project.completedTasks && project.completedTasks > 0) ||
              !unstartedLabel) &&
              !project.isPaused && (
                <span className="h4 mb-0 text-primary font-weight-bold">
                  {`${Math.round(
                    (100 / (project.totalTasks || 1)) *
                      (project.completedTasks || 0)
                  )}%`}
                </span>
              )}
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="p-0 border-0">
        <Progress
          className="progress-slim-2  rounded-top-0"
          max={project.totalTasks || 0}
          value={project.completedTasks || 0}
        />
      </CardFooter>
    </Card>
  )
}
